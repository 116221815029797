import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

import { CustomMenu } from "../../../Components/MenuElements";
import { CustomExpandIcon } from "../../../Components/CustomActionIcons";
import styles from "./styles";
import {
  selectSubjectList,
  selectSystemList,
} from "../../../selectors/category";

const useStyles = makeStyles(theme => styles(theme));

const FILTER_TITLE = {
  ALL_SUBJECTS: "All Subjects",
  ALL_SYSTEMS: "All Systems",
  MULTIPLE: "Multiple",
  NONE: "None",
};

const FlashcardFilter = props => {
  const {
    isDarkMode = false,
    horizontalOrigin = "left",
    total,
    onClose,
    anchorFilterMenu,
    selectedSubjects,
    selectedSystems,
    setSelectedSubjects,
    setSelectedSystems,
    setApplyCategoriesFilter,
    applyCategoriesFilter,
    fetchFlashcards,
    fetchFlashcardsByCategoriesFilter,
    setSearchKeyword,
  } = props;
  const classes = useStyles({ isDarkMode });

  const subjects = useSelector(selectSubjectList);
  const systems = useSelector(selectSystemList);
  const subject_ids = Array.isArray(subjects)
    ? subjects.map(subject => subject._id)
    : [];

  const system_ids = Array.isArray(systems)
    ? systems.map(system => system._id)
    : [];

  const [uiControls, setUiControls] = useState({
    showSubjectsFilter: false,
    showSystemsFilter: false,
  });

  const handleCloseMenu = () => {
    onClose();
    setUiControls({ showSubjectsFilter: false, showSystemsFilter: false });
  };

  const onExpandFilter = section => {
    setUiControls(c => Object.assign({}, c, { [section]: !c[section] }));
  };

  const handleSelectAllSubjects = event => {
    if (event.target.checked) {
      setSelectedSubjects(subjects.map(subject => subject._id));
    } else {
      setSelectedSubjects([]);
    }
  };

  const handleSelectAllSystems = event => {
    if (event.target.checked) {
      setSelectedSystems(systems.map(system => system._id));
    } else {
      setSelectedSystems([]);
    }
  };

  const handleChangeSelectedSubject = event => {
    const subject_id = event.target.name;

    if (event.target.checked) {
      if (selectedSubjects.includes(subject_id)) {
        setSelectedSubjects(
          selectedSubjects.filter(elem => elem !== subject_id)
        );
      } else {
        setSelectedSubjects([...selectedSubjects, subject_id]);
      }
    } else {
      setSelectedSubjects(selectedSubjects.filter(elem => elem !== subject_id));
    }
  };

  const handleChangeSelectedSystem = event => {
    const system_id = event.target.name;

    if (event.target.checked) {
      if (selectedSystems.includes(system_id)) {
        setSelectedSystems(selectedSystems.filter(elem => elem !== system_id));
      } else {
        setSelectedSystems([...selectedSystems, system_id]);
      }
    } else {
      setSelectedSystems(selectedSystems.filter(elem => elem !== system_id));
    }
  };

  const renderSubjectsFilter = () => {
    if (subject_ids.length === selectedSubjects.length)
      return FILTER_TITLE.ALL_SUBJECTS;
    else if (selectedSubjects.length === 1)
      return subjects.find(subject => subject._id === selectedSubjects[0])[
        "name"
      ];
    else if (selectedSubjects.length > 0) return FILTER_TITLE.MULTIPLE;
    else return FILTER_TITLE.NONE;
  };

  const renderSystemsFilter = () => {
    if (system_ids.length === selectedSystems.length)
      return FILTER_TITLE.ALL_SYSTEMS;
    else if (selectedSystems.length === 1)
      return systems.find(system => system._id === selectedSystems[0])["name"];
    else if (selectedSystems.length > 0) return FILTER_TITLE.MULTIPLE;
    else return FILTER_TITLE.NONE;
  };

  const restoreCategoriesFilter = () => {
    setSelectedSubjects(subject_ids);
    setSelectedSystems(system_ids);
  };

  const handleClickClearBtn = async () => {
    await fetchFlashcards();
    handleCloseMenu();
    setApplyCategoriesFilter(false);
    setSearchKeyword("");
    restoreCategoriesFilter();
  };

  const handleClickApplyBtn = async () => {
    await fetchFlashcardsByCategoriesFilter();
    handleCloseMenu();
    setApplyCategoriesFilter(true);
    setSearchKeyword("");
  };

  useEffect(() => {
    if (!applyCategoriesFilter) {
      restoreCategoriesFilter();
    }
  }, []);

  const categoryOptionsContProps = {
    p: 2,
    py: 1,
    className: classes.categoryOptionsCont,
  };

  const filterTitleContProps = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    className: "clickable",
    borderBottom: "1px solid #f3f3f4",
  };

  return (
    <CustomMenu
      anchorEl={anchorFilterMenu}
      keepMounted
      open={Boolean(anchorFilterMenu)}
      onClose={handleCloseMenu}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: horizontalOrigin,
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: horizontalOrigin,
      }}
      getContentAnchorEl={null}
      transitionDuration={0}
      classes={{
        paper: classes.filterMenu,
      }}
    >
      <Box p={2}>
        <Box
          fontSize="1.2em"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" className={classes.title}>
            Filter
            {applyCategoriesFilter && (
              <Box color="secondary.main" ml={1}>
                {total}
              </Box>
            )}
          </Box>
          <Box>
            <Button
              disabled={!applyCategoriesFilter}
              color="secondary"
              className="capitalize"
              onClick={handleClickClearBtn}
              classes={{
                disabled: classes.disabledClearBtn,
                root: classes.rootClearBtn,
              }}
            >
              Clear
            </Button>
          </Box>
        </Box>
        <Box mt={2}>
          <Box
            {...filterTitleContProps}
            onClick={() => onExpandFilter("showSubjectsFilter")}
          >
            <Box className={classes.subTitle}>{renderSubjectsFilter()}</Box>
            <CustomExpandIcon
              expanded={uiControls.showSubjectsFilter}
              expandIconSize={22}
            />
          </Box>
          {uiControls.showSubjectsFilter && (
            <Box {...categoryOptionsContProps}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      onChange={handleSelectAllSubjects}
                      checked={subject_ids.length === selectedSubjects.length}
                      icon={
                        <CheckBoxOutlineBlankIcon
                          className={classes.uncheckedIcon}
                        />
                      }
                    />
                  }
                  classes={{
                    label: classes.checkboxLabel,
                  }}
                  label={FILTER_TITLE.ALL_SUBJECTS}
                />
                {subjects.length > 0 &&
                  subjects.map(subject => (
                    <FormControlLabel
                      key={subject._id}
                      control={
                        <Checkbox
                          color="secondary"
                          checked={selectedSubjects.includes(subject._id)}
                          onChange={handleChangeSelectedSubject}
                          name={subject._id}
                          icon={
                            <CheckBoxOutlineBlankIcon
                              className={classes.uncheckedIcon}
                            />
                          }
                        />
                      }
                      classes={{
                        label: classes.checkboxLabel,
                      }}
                      label={subject.name}
                    />
                  ))}
              </FormGroup>
            </Box>
          )}
        </Box>

        <Box mt={3}>
          <Box
            {...filterTitleContProps}
            onClick={() => onExpandFilter("showSystemsFilter")}
          >
            <Box className={classes.subTitle}>{renderSystemsFilter()}</Box>
            <CustomExpandIcon
              expanded={uiControls.showSystemsFilter}
              expandIconSize={22}
            />
          </Box>

          {uiControls.showSystemsFilter && (
            <Box {...categoryOptionsContProps}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      onChange={handleSelectAllSystems}
                      checked={system_ids.length === selectedSystems.length}
                      icon={
                        <CheckBoxOutlineBlankIcon
                          className={classes.uncheckedIcon}
                        />
                      }
                    />
                  }
                  classes={{
                    label: classes.checkboxLabel,
                  }}
                  label={FILTER_TITLE.ALL_SYSTEMS}
                />
                {systems.length > 0 &&
                  systems.map(system => (
                    <FormControlLabel
                      key={system._id}
                      control={
                        <Checkbox
                          color="secondary"
                          checked={selectedSystems.includes(system._id)}
                          onChange={handleChangeSelectedSystem}
                          name={system._id}
                          icon={
                            <CheckBoxOutlineBlankIcon
                              className={classes.uncheckedIcon}
                            />
                          }
                        />
                      }
                      classes={{
                        label: classes.checkboxLabel,
                      }}
                      label={system.name}
                    />
                  ))}
              </FormGroup>
            </Box>
          )}
        </Box>

        <Box mt={3} fontSize="14px" textAlign="center">
          <Button
            color="secondary"
            onClick={handleClickApplyBtn}
            className="capitalize"
          >
            Apply
          </Button>
        </Box>
      </Box>
    </CustomMenu>
  );
};

export default FlashcardFilter;
