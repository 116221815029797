import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import CardMembershipOutlinedIcon from "@material-ui/icons/CardMembershipOutlined";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import AccountBalanceOutlinedIcon from "@material-ui/icons/AccountBalanceOutlined";
import PaymentOutlinedIcon from "@material-ui/icons/PaymentOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";

import { capitalizeFirstLetter, parseDateHtml } from "../../../utils";
import useStyles from "./styles";
import { getTransactionList } from "../../../actions/transactionAction";
import MUICustomHeader from "../../../Components/MUICustomHeader";
import { CURRENCY_FORMAT, TRANSACTION_STATUS } from "../../../constants";
import { Link } from "react-router-dom";
import { urlAdapter } from "../../../routes/helper";
import {
  EDIT_TRANSACTION_URL,
  EDIT_USER_URL,
  idParams,
} from "../../../routes/constants";
import { CustomTableSortLabel } from "../../../Components/CustomTableElements";
import CustomTooltip from "../../../Components/CustomTooltip";
import { Box } from "@material-ui/core";
import { selectTransactionList } from "../../../selectors/transaction";
import { sortList } from "../../../utils/parser-helper";

const ManageTransaction = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const transactions = useSelector(selectTransactionList);
  const [isAsc, setIsAsc] = useState(false);
  const [orderBy, setOrderBy] = useState("date");

  const onSort = value => {
    if (orderBy === value) {
      setIsAsc(!isAsc);
    } else {
      setOrderBy(value);
    }
  };

  useEffect(() => {
    dispatch(getTransactionList());
  }, [dispatch]);

  if (!transactions) return null;

  const sortedTransactions = sortList(transactions, isAsc, orderBy);

  return (
    <Fragment>
      <div className="pageCont">
        <div className="bodyCont">
          <div className="pageContent">
            <MUIDataTable
              data={sortedTransactions.map(transaction => [
                transaction._ref,
                transaction.order_id,
                transaction.status,
                transaction.term.title,
                transaction.term.price,
                transaction.transaction_fee,
                transaction.discount,
                transaction.total_price,
                transaction.payer,
                transaction.payer,
                transaction.source,
                transaction._createdOn,
                transaction._expiredOn,
              ])}
              columns={[
                {
                  name: "ID",
                  options: {
                    customHeadRender: columnMeta => {
                      return (
                        <MUICustomHeader label={columnMeta.label}>
                          <>
                            {columnMeta.label}
                            <FormatListNumberedIcon
                              className={classes.thIcon}
                              color="secondary"
                            />
                          </>
                        </MUICustomHeader>
                      );
                    },
                    customBodyRenderLite: dataIndex => {
                      let id = sortedTransactions[dataIndex]._id;
                      if (id) {
                        const url = urlAdapter(
                          EDIT_TRANSACTION_URL,
                          idParams,
                          id
                        );

                        return (
                          <Link to={url}>
                            {sortedTransactions[dataIndex]._ref}
                          </Link>
                        );
                      }
                      return "N/A";
                    },
                  },
                },
                {
                  name: "Transaction",
                  options: {
                    customHeadRender: columnMeta => {
                      return (
                        <MUICustomHeader label={columnMeta.label}>
                          <>
                            {columnMeta.label}
                            <SwapHorizIcon
                              className={classes.thIcon}
                              color="secondary"
                            />
                          </>
                        </MUICustomHeader>
                      );
                    },
                    customBodyRender: value => {
                      return (
                        <div style={{ width: 180, wordBreak: "break-word" }}>
                          {value ? value : "N/A"}
                        </div>
                      );
                    },
                  },
                },
                {
                  name: "Status",
                  options: {
                    customHeadRender: columnMeta => {
                      return (
                        <MUICustomHeader label={columnMeta.label}>
                          <>
                            {columnMeta.label}
                            <AssessmentOutlinedIcon
                              className={classes.thIcon}
                              color="secondary"
                            />
                          </>
                        </MUICustomHeader>
                      );
                    },
                    customBodyRenderLite: dataIndex => {
                      let val = sortedTransactions[dataIndex].status;
                      const text = capitalizeFirstLetter(val);
                      if (val === TRANSACTION_STATUS.REFUNDED) {
                        const refundedDate =
                          sortedTransactions[dataIndex]._refundedOn;

                        if (refundedDate) {
                          return (
                            <CustomTooltip
                              placement="right"
                              arrow
                              title={parseDateHtml(refundedDate)}
                            >
                              <span>{text}</span>
                            </CustomTooltip>
                          );
                        }
                      }
                      return <span>{text}</span>;
                    },
                  },
                },
                {
                  name: "Membership",
                  options: {
                    customHeadRender: columnMeta => {
                      return (
                        <MUICustomHeader label={columnMeta.label}>
                          <>
                            {columnMeta.label}
                            <CardMembershipOutlinedIcon
                              className={classes.thIcon}
                              color="secondary"
                            />
                          </>
                        </MUICustomHeader>
                      );
                    },
                  },
                },
                {
                  name: "Price",
                  options: {
                    customHeadRender: columnMeta => {
                      return (
                        <MUICustomHeader label={columnMeta.label}>
                          <>
                            {columnMeta.label}
                            <PaymentOutlinedIcon
                              className={classes.thIcon}
                              color="secondary"
                            />
                          </>
                        </MUICustomHeader>
                      );
                    },
                    customBodyRenderLite: dataIndex => {
                      let val = sortedTransactions[dataIndex].term.price;
                      return `${CURRENCY_FORMAT}${Number(val).toFixed(2)}`;
                    },
                  },
                },
                {
                  name: "Transaction Fee",
                  options: {
                    customHeadRender: columnMeta => {
                      return (
                        <MUICustomHeader label={columnMeta.label}>
                          <>
                            {columnMeta.label}
                            <AccountBalanceOutlinedIcon
                              className={classes.thIcon}
                              color="secondary"
                            />
                          </>
                        </MUICustomHeader>
                      );
                    },
                    customBodyRenderLite: dataIndex => {
                      let val = sortedTransactions[dataIndex].transaction_fee;
                      return (
                        <Box width={140}>{`${CURRENCY_FORMAT}${Number(
                          val
                        ).toFixed(2)}`}</Box>
                      );
                    },
                  },
                },
                {
                  name: "Discount",
                  options: {
                    customHeadRender: columnMeta => {
                      return (
                        <MUICustomHeader label={columnMeta.label}>
                          <>
                            {columnMeta.label}
                            <LocalOfferOutlinedIcon
                              className={classes.thIcon}
                              color="secondary"
                            />
                          </>
                        </MUICustomHeader>
                      );
                    },
                    customBodyRenderLite: dataIndex => {
                      let val = sortedTransactions[dataIndex].discount;
                      return `${CURRENCY_FORMAT}${Number(val).toFixed(2)}`;
                    },
                  },
                },
                {
                  name: "Total",
                  options: {
                    customHeadRender: columnMeta => {
                      return (
                        <MUICustomHeader label={columnMeta.label}>
                          <>
                            {columnMeta.label}
                            <MonetizationOnOutlinedIcon
                              className={classes.thIcon}
                              color="secondary"
                            />
                          </>
                        </MUICustomHeader>
                      );
                    },
                    customBodyRenderLite: dataIndex => {
                      let val = sortedTransactions[dataIndex].total_price;
                      return `${CURRENCY_FORMAT}${Number(val).toFixed(2)}`;
                    },
                  },
                },
                {
                  name: "Name",
                  options: {
                    customHeadRender: columnMeta => {
                      return (
                        <MUICustomHeader label={columnMeta.label}>
                          <>
                            {columnMeta.label}
                            <PersonOutlineOutlinedIcon
                              className={classes.thIcon}
                              color="secondary"
                            />
                          </>
                        </MUICustomHeader>
                      );
                    },
                    customBodyRenderLite: dataIndex => {
                      let payer = sortedTransactions[dataIndex].payer;
                      const name = payer
                        ? `${payer.firstName ? `${payer.firstName} ` : ""}${
                            payer.lastName ? `${payer.lastName}` : ""
                          }`
                        : "";
                      return name;
                    },
                  },
                },
                {
                  name: "User",
                  options: {
                    customHeadRender: columnMeta => {
                      return (
                        <MUICustomHeader label={columnMeta.label}>
                          <>
                            {columnMeta.label}
                            <EmailOutlinedIcon
                              className={classes.thIcon}
                              color="secondary"
                            />
                          </>
                        </MUICustomHeader>
                      );
                    },
                    customBodyRenderLite: dataIndex => {
                      let payer = sortedTransactions[dataIndex].payer;
                      const email = payer ? payer.email : "";
                      if (email) {
                        const url = urlAdapter(
                          EDIT_USER_URL,
                          idParams,
                          payer._id
                        );

                        return <Link to={url}>{email}</Link>;
                      }
                      return "N/A";
                    },
                  },
                },
                {
                  name: "Gateway",
                  options: {
                    customHeadRender: columnMeta => {
                      return (
                        <MUICustomHeader label={columnMeta.label}>
                          <>
                            {columnMeta.label}
                            <ReceiptOutlinedIcon
                              className={classes.thIcon}
                              color="secondary"
                            />
                          </>
                        </MUICustomHeader>
                      );
                    },
                  },
                },
                {
                  name: "Created On",
                  options: {
                    customHeadRender: columnMeta => {
                      return (
                        <MUICustomHeader label={columnMeta.label}>
                          <>
                            <CustomTableSortLabel
                              active={orderBy === "date"}
                              direction={isAsc ? "asc" : "desc"}
                              onClick={() => onSort("date")}
                            >
                              <div
                                className="MUIDataTableHeadCell-sortAction-97"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  color: "#313131",
                                }}
                              >
                                <div className="MUIDataTableHeadCell-data-96">
                                  {columnMeta.label}
                                </div>
                                <CalendarTodayOutlinedIcon
                                  className={classes.thIcon}
                                  color="secondary"
                                />
                              </div>
                            </CustomTableSortLabel>
                          </>
                        </MUICustomHeader>
                      );
                    },
                    customBodyRenderLite: dataIndex => {
                      let val = sortedTransactions[dataIndex]._createdOn;
                      return parseDateHtml(val, 130);
                    },
                  },
                },
                {
                  name: "Expires On",
                  options: {
                    customHeadRender: columnMeta => {
                      return (
                        <MUICustomHeader label={columnMeta.label}>
                          <>
                            {columnMeta.label}
                            <EventOutlinedIcon
                              className={classes.thIcon}
                              color="secondary"
                            />
                          </>
                        </MUICustomHeader>
                      );
                    },
                    customBodyRenderLite: dataIndex => {
                      let val = sortedTransactions[dataIndex]._expiredOn;
                      return parseDateHtml(val);
                    },
                  },
                },
              ]}
              options={{
                download: false,
                print: false,
                selectableRows: "none",
                setRowProps: (row, dataIndex, rowIndex) => {
                  return {
                    style: {
                      backgroundColor:
                        rowIndex % 2 !== 0 ? "#fff" : "rgba(0, 0, 0, 0.04)",
                    },
                  };
                },
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ManageTransaction;
