import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import PhotoSizeSelectActualOutlinedIcon from "@material-ui/icons/PhotoSizeSelectActualOutlined";
import SdStorageOutlinedIcon from "@material-ui/icons/SdStorageOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import TitleOutlinedIcon from "@material-ui/icons/TitleOutlined";
import LinkIcon from "@material-ui/icons/Link";
import Box from "@material-ui/core/Box";

import * as MediaAction from "../../../../actions/mediaAction";
import {
  CustomConfirmDialogActions,
  CustomActionBtn,
  CustomCancelBtn,
} from "../../../../Components/CustomDialogElements";
import { parseDateHtml, parseQuestionHtml } from "../../../../utils";
import { parseMediaTableData } from "../../ManageMedia/helper";
import useStyles from "./styles";
import { COURSE_APP_SUB_ROUTE } from "../../../../routes/constants";
import MUICustomHeader from "../../../../Components/MUICustomHeader";
import FileImage from "../../ManageMedia/components/FileImage";
import TableTitle from "./TableTitle";
import { RESOURCE_STATUS } from "../../../../constants";
import { DELETED_STATUS_FILTER } from "../constants";
import { handleRemoveTableRow } from "../helper";

const ManageMedia = ({ getMediaList, removeMedia }) => {
  const classes = useStyles();
  const history = useHistory();

  const [medias, setMedias] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedIndexes, setSelectedIndexes] = useState([]);

  // Handle Confirmtion Modal Start
  const handleOpenDeleteModal = event => {
    if (selectedIndexes.length > 0) {
      setShowDeleteModal(true);
    }
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };
  // Handle Confirmtion Modal End

  const fetchMediaList = async () => {
    const res = await getMediaList(DELETED_STATUS_FILTER);
    if (res && res.medias) {
      setMedias(res.medias);
    }
  };

  const onRemoveMedia = async () => {
    await handleRemoveTableRow(
      medias,
      setMedias,
      selectedIndexes,
      setSelectedIndexes,
      removeMedia,
      handleCloseDeleteModal
    );
  };

  useEffect(() => {
    fetchMediaList();
  }, []);

  if (!medias) return null;

  const currentMedias = medias;

  return (
    <Fragment>
      {/* Delete Media Confirmation Modal */}
      <Dialog onClose={handleCloseDeleteModal} open={showDeleteModal}>
        <DialogContent>
          Are you sure you wish to permanently delete these media(s)?
        </DialogContent>
        <CustomConfirmDialogActions>
          <CustomActionBtn onClick={onRemoveMedia}>Delete</CustomActionBtn>
          <CustomCancelBtn onClick={handleCloseDeleteModal}>
            Cancel
          </CustomCancelBtn>
        </CustomConfirmDialogActions>
      </Dialog>
      <TableTitle title="Media" handleOpenDeleteModal={handleOpenDeleteModal} />
      <MUIDataTable
        data={parseMediaTableData(currentMedias)}
        columns={[
          {
            name: "File",
            options: {
              customHeadRender: columnMeta => {
                return (
                  <MUICustomHeader label={columnMeta.label}>
                    <>
                      {columnMeta.label}

                      <PhotoSizeSelectActualOutlinedIcon
                        className={classes.thIcon}
                        color="secondary"
                      />
                    </>
                  </MUICustomHeader>
                );
              },
              customBodyRenderLite: dataIndex => {
                let url = currentMedias[dataIndex].url;
                let filename = currentMedias[dataIndex].filename;
                return <FileImage url={url} filename={filename} />;
              },
            },
          },
          {
            name: "File Size",
            options: {
              customHeadRender: columnMeta => {
                return (
                  <MUICustomHeader label={columnMeta.label}>
                    <>
                      {columnMeta.label}
                      <SdStorageOutlinedIcon
                        className={classes.thIcon}
                        color="secondary"
                      />
                    </>
                  </MUICustomHeader>
                );
              },
            },
          },
          {
            name: "Author",
            options: {
              customHeadRender: columnMeta => {
                return (
                  <MUICustomHeader label={columnMeta.label}>
                    <>
                      {columnMeta.label}
                      <PersonOutlineOutlinedIcon
                        className={classes.thIcon}
                        color="secondary"
                      />
                    </>
                  </MUICustomHeader>
                );
              },
            },
          },
          {
            name: "Attached to",
            options: {
              customHeadRender: columnMeta => {
                return (
                  <MUICustomHeader label={columnMeta.label}>
                    <>
                      {columnMeta.label}
                      <PublishOutlinedIcon
                        className={classes.thIcon}
                        color="secondary"
                      />
                    </>
                  </MUICustomHeader>
                );
              },
              customBodyRenderLite: dataIndex => {
                const media = currentMedias[dataIndex];
                let jsx, url;
                // Case Topic
                if (media.lesson) {
                  const {
                    lesson: { title, _id },
                  } = media;
                  jsx = <div>{title}</div>;
                  url = `${COURSE_APP_SUB_ROUTE}/manage-topic/${_id}/edit`;
                } // Case Question
                else if (media.question) {
                  const {
                    question: { question, _id },
                  } = media;
                  jsx = parseQuestionHtml(question);
                  url = `${COURSE_APP_SUB_ROUTE}/manage-question/${_id}/edit`;
                } else {
                  jsx = <div>{"N/A"}</div>;
                }

                return (
                  <Fragment>
                    <Box display="flex" alignItems="center">
                      <Box
                        className="clickable"
                        mr={1}
                        display="flex"
                        alignItems="center"
                        onClick={() => history.push(url)}
                      >
                        <LinkIcon color="secondary" fontSize="small" />
                      </Box>

                      <div style={{ width: 240, wordWrap: "break-word" }}>
                        {jsx}
                      </div>
                    </Box>
                  </Fragment>
                );
              },
            },
          },
          {
            name: "Date",
            options: {
              customHeadRender: columnMeta => {
                return (
                  <MUICustomHeader label={columnMeta.label}>
                    <>
                      {columnMeta.label}
                      <CalendarTodayOutlinedIcon
                        className={classes.thIcon}
                        color="secondary"
                      />
                    </>
                  </MUICustomHeader>
                );
              },
              customBodyRenderLite: dataIndex => {
                let val = currentMedias[dataIndex]._createdOn;
                return parseDateHtml(val);
              },
            },
          },
          {
            name: "Title",
            options: {
              customHeadRender: columnMeta => {
                return (
                  <MUICustomHeader label={columnMeta.label}>
                    <>
                      {columnMeta.label}
                      <TitleOutlinedIcon
                        className={classes.thIcon}
                        color="secondary"
                      />
                    </>
                  </MUICustomHeader>
                );
              },
            },
          },
        ]}
        options={{
          download: false,
          print: false,
          customSearch: (searchQuery, currentRow, columns) => {
            // Only search Title which is last second column
            return currentRow[currentRow.length - 2]
              .toLowerCase()
              .includes(searchQuery.toLowerCase());
          },
          setRowProps: (row, dataIndex, rowIndex) => {
            return {
              style: {
                backgroundColor:
                  rowIndex % 2 !== 0 ? "#fff" : "rgba(0, 0, 0, 0.04)",
              },
            };
          },
          onRowSelectionChange: (
            currentRowsSelected,
            allRowsSelected,
            rowsSelected
          ) => {
            setSelectedIndexes(rowsSelected);
          },
          customToolbarSelect: () => {},
          rowsSelected: selectedIndexes,
        }}
      />
    </Fragment>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  getMediaList: filter => dispatch(MediaAction.getMediaList(filter)),
  removeMedia: (id, permanent) =>
    dispatch(MediaAction.removeMedia(id, permanent)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageMedia);
