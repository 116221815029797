
import React, { useState, useRef } from 'react';

function FloatingChatButton() {
// const FloatingChatButton = () => {
  const SESSION_ID = "SESSION_ID";

  const [isOpen, setIsOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [sessionId, setSessionId] = useState(localStorage.getItem(SESSION_ID) || null); // Initialize with localStorage value
  
  let nextJsAppUrl = `http://159.65.11.102/chat`;

  
  // async function attemptLogin(apiKey) {
  //   const address = 'http://159.65.11.102';
  //   const url = `${address}/v1/user/login`; 
  //   // console.log("url: ", url);

  //   try {
  //     const response = await fetch(url, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         // 'Authorization': `Bearer ${apiKey}`, 
  //         // 'Access-Control-Allow-Origin': '*',
  //         // 'Access-Control-Allow-Headers': '*',
  //         // 'Access-Control-Request-Method': '*'
  //       },
  //       body: JSON.stringify({"email": "EMAIL","nickname":"name","password":"PASSWORD"}),
  //     });
      
  //     if (response.ok) {
  //       const responseData = await response.json(); 
  //       // console.log("responsseee:", response);
  //       return responseData; 
  //     } else {
  //       throw new Error('Invalid response format: missing data or id');
  //     }

  //   } catch (error) {
  //     console.error('Error creating chat session:', error);
  //   }
  // }

  async function createChatSession(chatId, apiKey) {
    const address = 'http://159.65.11.102';
    const url = `${address}/api/v1/chats/${chatId}/sessions`; 
    // console.log("url: ", url);

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`, 
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Access-Control-Request-Method': '*'
        },
        body: JSON.stringify({ name: "Untitled prompt" }),
      });
      
      if (response.ok) {
        const responseData = await response.json(); 
        // console.log("responsseee:", response);
        return responseData; 
      } else {
        throw new Error('Invalid response format: missing data or id');
      }

    } catch (error) {
      console.error('Error creating chat session:', error);
    }
  }

  const iframeRef = useRef(null);

  const handleCloseIframe = () => {
    // console.log("iframeRef.current: ", iframeRef.current)
    if (iframeRef.current) {
      iframeRef.current.contentWindow.postMessage(
        { type: 'close-iframe' },
        'http://159.65.11.102'
      );
      
      setTimeout(function() {
        toggleOpen();
      }, 100)
    }
  };

  const toggleOpen = () => {
    // console.log("sessionId:: ", sessionId);
    if (sessionId == null) {
      const chatId = "c11ffcdad4c211ef8ae10242ac120006"; 
      const apiKey = "ragflow-I1N2YwYTFlZDVhOTExZWY4OWJiMDI0Mm"; 
      // console.log('ishere??')

      // attemptLogin(apiKey).then(response1 => {
        // console.log('login::: ', response1)
              
        // if (response1) {
      createChatSession(chatId, apiKey).then(response => {
        const newSessionId = response.data.id; 
        setSessionId(newSessionId); 

        localStorage.setItem(SESSION_ID, newSessionId); 

        setIsOpen(!isOpen); 
        setIsMinimized(false); 
      }).catch(error => {
        console.error("Error creating chat session:", error);
      });
        // }
      // }).catch(error => {
      //   console.error("Error creating attempt login:", error);
      // });

    } else { 
      setIsOpen(!isOpen);
      if (!isOpen) {
        setIsMinimized(false); 
      }
    }
  };

  return (
    <div>
      {!isOpen && (
          <button
            style={{
              position: 'fixed',
              bottom: '20px',
              right: '20px',
              background: 'linear-gradient(to bottom right, #b30000, #800080)',
              color: '#ffffff',
              border: 'none',
              borderRadius: '50%',
              padding: '15px',
              cursor: 'pointer',
              boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
              zIndex: 1000,
            }}
            onClick={toggleOpen}
          >
            <img
              src={'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ficon-library.com%2Fimages%2Fmagic-icon-png%2Fmagic-icon-png-12.jpg&f=1&nofb=1&ipt=14f59709db382ade91a01c37e81c6e6f8fe99d7a598bdddbdb57f99c0a931934&ipo=images'}
              width={30}
              height={30}
              style={{ filter: 'brightness(0) invert(1)'}}
            />
          </button>
      )}

      {isOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
            backgroundColor: 'rgba(0, 0, 0, 0.5)', 
          }}
        >
          <div
            style={{
              width: '90%', 
              height: '90%', 
              background: '#fff',
              borderRadius: '10px',
              overflow: 'hidden', 
              display: isMinimized ? 'none' : 'block' 
            }}
          >
            <div
              style={{
                backgroundColor: '#eee',
                padding: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div></div> {/* Title */}
              <div style={{
                marginTop: '7px',
                height: '20px', 
              }}>
                <button 
                  onClick={handleCloseIframe}
                  >X</button>
                {/* Close button */}
              </div>
            </div>

            <iframe
              src={nextJsAppUrl}
              style={{
                width: '100%',
                height: 'calc(100% - 40px)', 
                border: 'none',
              }}
              ref={iframeRef}
            />
          </div>
             {/* {isMinimized && (
                <button
                style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    backgroundColor: '#007bff',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '5px',
                    padding: '10px',
                    cursor: 'pointer',
                    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
                    zIndex: 1000,
                }}
                // onClick={handleMinimize}
                >
                Show Chat
                </button>
            )} */}
        </div>
      )}
    </div>
  );
}

export default FloatingChatButton;
