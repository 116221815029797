import { Button, Dialog, DialogContent } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { activateUser } from "../../../../actions/userAction";
import {
  CustomCancelBtn,
  CustomConfirmDialogActions,
  CustomActionBtn,
} from "../../../../Components/CustomDialogElements";
import { USER_STATUS } from "../../../../constants";
import { MANAGE_USER_URL } from "../../../../routes/constants";

const ActivateUserSection = ({ user }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showActivateModal, setShowActivateModal] = useState(false);

  // Handle Confirmtion Modal Start
  const handleOpenActivateModal = () => {
    setShowActivateModal(true);
  };

  const handleCloseActivateModal = () => {
    setShowActivateModal(false);
  };
  // Handle Confirmtion Modal End

  const handleActivateUser = () => {
    const data = {
      _id: user._id,
      code: user._activateCode,
    };
    const byAdmin = true;
    dispatch(activateUser(data, byAdmin));
    handleCloseActivateModal();
    history.push(MANAGE_USER_URL);
  };

  const disabled = user.status !== USER_STATUS.INACTIVE;

  return (
    <>
      <Dialog onClose={handleCloseActivateModal} open={showActivateModal}>
        <DialogContent>
          Are you sure you wish to verify this user?
        </DialogContent>
        <CustomConfirmDialogActions>
          <CustomActionBtn onClick={handleActivateUser}>Verify</CustomActionBtn>
          <CustomCancelBtn onClick={handleCloseActivateModal}>
            Cancel
          </CustomCancelBtn>
        </CustomConfirmDialogActions>
      </Dialog>

      <Button
        color="secondary"
        variant="contained"
        onClick={handleOpenActivateModal}
        disabled={disabled}
      >
        Verify
      </Button>
    </>
  );
};

export default ActivateUserSection;
