import { NOTE_ORDER_BY_TYPE } from "../../constants";

// Always Ascending
export const sortNotes = (notes, orderBy) =>
  [...notes].sort((a, b) => {
    switch (orderBy) {
      case NOTE_ORDER_BY_TYPE["QUESTION_ID"]:
        return a.question._ref > b.question._ref ? 1 : -1;
      case NOTE_ORDER_BY_TYPE["SUBJECT"]:
        return a.category.name > b.category.name ? 1 : -1;
      case NOTE_ORDER_BY_TYPE["SYSTEM"]:
        return a.subCategory.name > b.subCategory.name ? 1 : -1;
    }
  });

export const filterNotesByKeyWord = (notes, keyword) => {
  return notes.filter(note => {
    const lowerCaseKeyword = keyword.toLowerCase();
    return (
      !keyword ||
      note.question._ref.toString().includes(lowerCaseKeyword) ||
      note.content.toLowerCase().includes(lowerCaseKeyword) ||
      note.category.name.toLowerCase().includes(lowerCaseKeyword) ||
      note.subCategory.name.toLowerCase().includes(lowerCaseKeyword)
    );
  });
};
