import React from "react";
import useStyles from "./styles";

const MultipleColumnTooltipBody = ({ data, columns }) => {
  const classes = useStyles();

  return (
    <div className={classes.popoverBody}>
      <div className="mt-3">
        <table className={classes.toolTipDescription}>
          <tbody>
            <tr className={classes.tableTr}>
              {columns.map(col => (
                <td className={classes.tableTd} key={col}>
                  {col}
                </td>
              ))}
            </tr>
            {data.map((row, index) => (
              <tr className={classes.tableTr} key={index}>
                {row.map((col, i) => (
                  <td className={classes.tableTd} key={i}>
                    {col}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MultipleColumnTooltipBody;
