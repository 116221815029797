import React, { useMemo } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import useStyles from "./styles";
import { COLOR_BLACK } from "styles/constants";
import { CURRENCY_CODE, CURRENCY_FORMAT } from "../../../../../constants";

const TotalPriceBox = props => {
  const { cartItems, handleBack, handleNext } = props;
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const classes = useStyles();

  const grandTotal = useMemo(() => {
    if (cartItems.length > 0) {
      return cartItems.reduce((acc, curr) => acc + curr.price, 0);
    } else {
      return 0;
    }
  }, [cartItems]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex"
        alignItems="center"
        flexDirection="column"
        width="100%"
      >
        <Box ml={lgUp ? "auto" : 0} className={classes.totalPriceBox}>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            height="100%"
            flexDirection="column"
            color={COLOR_BLACK}
            pt={5}
          >
            <Box fontSize="1rem">Total {`(${CURRENCY_CODE})`}</Box>
            <Box fontSize="2.8rem" fontWeight="bold">
              {CURRENCY_FORMAT} {grandTotal}
            </Box>
          </Box>
          <Button
            color="primary"
            variant="contained"
            className={classes.checkoutButton}
            onClick={handleNext}
          >
            Checkout
          </Button>
        </Box>
        <Box
          ml={lgUp ? "auto" : 0}
          fontSize="1rem"
          onClick={handleBack}
          className={classes.continueTxt}
          textAlign="center"
        >
          Continue Shopping
        </Box>
      </Box>
    </>
  );
};

export default TotalPriceBox;
