// Pre calculate subjects data
const parseSubjectsData = userExam =>
  userExam
    ? userExam.exam.categories.map(({ category }) => {
        const subjectQuestions = userExam.exam.questions.filter(
          question => question.category._id === category._id
        );

        const subjectsSummary = {
          correct: {
            count: 0,
          },
          incorrect: {
            count: 0,
          },
          omitted: {
            count: 0,
          },
        };
        const systemsSummaryArr = [];
        subjectQuestions.forEach(question => {
          const userAnswer = userExam.userExamAnswers.filter(
            answer => answer.question._id === question._id
          )[0];
          const foundSysQuestionIndex = systemsSummaryArr.findIndex(
            systemQuestion => systemQuestion.name === question.subCategory.name
          );

          let tempSystemQuestion;
          // If found, use the found systemsQuestionSummary and increase total by 1=
          if (foundSysQuestionIndex !== -1) {
            tempSystemQuestion = systemsSummaryArr[foundSysQuestionIndex];
            tempSystemQuestion.total += 1;
          } else {
            // If not found, create new systemsQuestionSummary and default total value is 1
            tempSystemQuestion = {
              name: question.subCategory.name,
              summary: {
                correct: {
                  count: 0,
                },
                incorrect: {
                  count: 0,
                },
                omitted: {
                  count: 0,
                },
              },
              total: 1,
            };
          }
          if (!userAnswer) {
            // No answer data found means omitted
            subjectsSummary.omitted.count += 1;
            tempSystemQuestion.summary.omitted.count += 1;
          } else {
            // If answer data found
            if (userAnswer.omitted) {
              subjectsSummary.omitted.count += 1;
              tempSystemQuestion.summary.omitted.count += 1;
            } else {
              if (userAnswer._answerRight) {
                subjectsSummary.correct.count += 1;
                tempSystemQuestion.summary.correct.count += 1;
              } else {
                subjectsSummary.incorrect.count += 1;
                tempSystemQuestion.summary.incorrect.count += 1;
              }
            }
          }
          // If found, replace the original systemsQuestionSummary
          if (foundSysQuestionIndex !== -1) {
            systemsSummaryArr[foundSysQuestionIndex] = tempSystemQuestion;
          } else {
            // If not found, push a new systemsQuestionSummary
            systemsSummaryArr.push(tempSystemQuestion);
          }
        });
        return {
          name: category.name,
          subjectsSummary,
          systemsSummaryArr,
          total: subjectQuestions.length,
        };
      })
    : [];

export { parseSubjectsData };
