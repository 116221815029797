import React from "react";
import useStyles from "./styles";

const SingleColumnTooltipBody = ({ rowArr }) => {
  const classes = useStyles();

  return (
    <div className={classes.popoverBody}>
      <div className="mt-3">
        <table className={classes.toolTipDescription}>
          <tbody>
            {rowArr.map(item => (
              <tr className={classes.tableTr} key={item}>
                <td className={classes.tableTd}>{item}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SingleColumnTooltipBody;
