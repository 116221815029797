import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import AntTabs from "../../Components/TabElements/AntTabs";
import AntTab from "../../Components/TabElements/AntTab";
import TabPanel from "../../Components/TabElements/TabPanel";
import TestResult from "../TestResult";
import TestAnalysis from "../TestAnalysis";
import * as ExamActions from "../../actions/examAction";
import * as NoteActions from "../../actions/noteAction";
import NoteModal from "./NoteModal";
import { COURSE_APP_SUB_ROUTE } from "../../routes/constants";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
  },
  tabs: {
    margin: `0 ${theme.spacing(5)}px`,
    [theme.breakpoints.down("xs")]: {
      margin: `0 ${theme.spacing(3)}px`,
    },
  },
  wrapperTop: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.spacing(3)}px ${theme.spacing(5)}px`,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(3),
    },
  },
  centerContainer: {
    display: "inline-flex",
    alignItems: "center",
    cursor: "pointer",
  },
}));

const TestTabsWrapper = ({ getUserExam, getNotes }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const [tab, setTab] = useState(0);
  const [userExamData, setUserExamData] = useState(null);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [notes, setNotes] = useState([]);
  const { pathname } = useLocation();

  const history = useHistory();
  let { id: userExamID } = useParams();

  const loadExamAndNotes = async () => {
    const filter = {
      key: "_id",
      value: userExamID,
    };
    const resUserExam = await getUserExam(filter);

    let question_ids;
    if (resUserExam) {
      setUserExamData(resUserExam.userExam);
      question_ids = resUserExam.userExam.exam.questions.map(
        question => question._id
      );
    }

    const resNotes = await getNotes();
    if (resNotes) {
      setNotes(
        resNotes.notes.filter(
          note =>
            note.status === "active" && question_ids.includes(note.question._id)
        )
      );
    }
  };
  // Handle Note Modal Start
  const handleOpenNoteModal = () => {
    setShowNoteModal(true);
  };
  const handleCloseNoteModal = () => {
    setShowNoteModal(false);
  };

  // Handle Note Modal End

  const handleChangeTab = (event, newTab) => {
    setTab(newTab);
  };

  useEffect(() => {
    loadExamAndNotes();
  }, []);
  useEffect(() => {
    if (pathname.substring(0, 22) === `${COURSE_APP_SUB_ROUTE}/test-result`) {
      setTab(0);
    } else {
      setTab(1);
    }
  }, [pathname]);

  return (
    <Paper elevation={0} className={classes.root}>
      <NoteModal
        handleCloseNoteModal={handleCloseNoteModal}
        showNoteModal={showNoteModal}
        notes={notes}
      />

      <div className={classes.wrapperTop}>
        <span
          className={classes.centerContainer}
          onClick={() => history.push(`${COURSE_APP_SUB_ROUTE}/test-list`)}
        >
          <ArrowBackIcon
            color="secondary"
            style={{ marginRight: "7px", marginBottom: "2px" }}
            fontSize="small"
          />
          <Typography color="secondary">Back</Typography>
        </span>

        <div className={classes.centerContainer} onClick={handleOpenNoteModal}>
          <DescriptionOutlinedIcon
            color="secondary"
            style={{ marginRight: "7px", marginBottom: "2px" }}
            fontSize="small"
          />
          <Typography color="secondary">Notes</Typography>
        </div>
      </div>
      <AntTabs
        className={classes.tabs}
        value={tab}
        onChange={handleChangeTab}
        aria-label="ant example"
      >
        <AntTab
          label="Test Results"
          onClick={() =>
            history.push(`${COURSE_APP_SUB_ROUTE}/test-result/${userExamID}`)
          }
        />
        <AntTab
          label="Test Analysis"
          onClick={() =>
            history.push(`${COURSE_APP_SUB_ROUTE}/test-analysis/${userExamID}`)
          }
        />
      </AntTabs>

      <TabPanel value={tab} index={0}>
        <TestResult userExam={userExamData} />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <TestAnalysis userExam={userExamData} />
      </TabPanel>
    </Paper>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  getUserExam: filter => dispatch(ExamActions.getUserExam(filter)),
  getNotes: () => dispatch(NoteActions.getNotes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TestTabsWrapper);
