import React, { Fragment, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import CardMembershipOutlinedIcon from "@material-ui/icons/CardMembershipOutlined";
import EventNoteOutlinedIcon from "@material-ui/icons/EventNoteOutlined";
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";
import * as UserAction from "../../../../actions/userAction";
import {
  parseCapitalizeHtml,
  parseDateHtml,
  parseUsername,
} from "../../../../utils";
import MUICustomHeader from "../../../../Components/MUICustomHeader";
import useStyles from "./styles";
import {
  parseUserEmailHtml,
  parseUserPlanHtml,
  parseUserStatusHtml,
} from "../../ManageUser/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTag } from "@fortawesome/free-solid-svg-icons";
import useModal from "../../../../hooks/useModal";
import { handleRemoveTableRow } from "../helper";
import ConfirmationModal from "../../../../Components/Modal/ConfirmationModal";
import { DELETED_STATUS_FILTER } from "../constants";
import TableTitle from "./TableTitle";

const UserTable = ({ getUsersList, removeUser }) => {
  const classes = useStyles();

  const [users, setUsers] = useState([]);
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const { open, handleOpen, handleClose } = useModal();
  const handleOpenDeleteModal = () => {
    if (selectedIndexes.length > 0) {
      handleOpen();
    }
  };

  const fetchUsers = async () => {
    const res = await getUsersList(DELETED_STATUS_FILTER);
    if (res && res.users) {
      setUsers(res.users);
    }
  };

  const onRemoveUser = async () => {
    await handleRemoveTableRow(
      users,
      setUsers,
      selectedIndexes,
      setSelectedIndexes,
      removeUser,
      handleClose
    );
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Fragment>
      <ConfirmationModal
        open={open}
        onClose={handleClose}
        actionFn={onRemoveUser}
        ctaTitle={"Are you sure you wish to permanently delete these user(s)?"}
        ctaTxt={"Delete"}
      />
      <TableTitle title="User" handleOpenDeleteModal={handleOpenDeleteModal} />
      <MUIDataTable
        data={users.map(user => [
          user._ref,
          parseUsername(user.firstName, user.lastName),
          user.email,
          user.status,
          user.subscription,
          user.type,
          user._createdOn,
          user._lastAccessOn,
          user._loginHistory,
        ])}
        columns={[
          {
            name: "ID",
            options: {
              customHeadRender: columnMeta => {
                return (
                  <MUICustomHeader label={columnMeta.label}>
                    <>
                      {columnMeta.label}
                      <FormatListNumberedIcon
                        className={classes.thIcon}
                        color="secondary"
                      />
                    </>
                  </MUICustomHeader>
                );
              },
            },
          },
          {
            name: "Username",
            options: {
              customHeadRender: columnMeta => {
                return (
                  <MUICustomHeader label={columnMeta.label}>
                    <>
                      {columnMeta.label}
                      <PersonOutlineOutlinedIcon
                        className={classes.thIcon}
                        color="secondary"
                      />
                    </>
                  </MUICustomHeader>
                );
              },
            },
          },
          {
            name: "Email",
            options: {
              customBodyRenderLite: dataIndex => {
                let email = users[dataIndex].email;
                let emailHistory = users[dataIndex]._emailHistory;
                return parseUserEmailHtml(email, emailHistory);
              },
              customHeadRender: columnMeta => {
                return (
                  <MUICustomHeader label={columnMeta.label}>
                    <>
                      {columnMeta.label}
                      <EmailOutlinedIcon
                        className={classes.thIcon}
                        color="secondary"
                      />
                    </>
                  </MUICustomHeader>
                );
              },
            },
          },
          {
            name: "Status",
            options: {
              customBodyRender: value => {
                return parseUserStatusHtml(value);
              },
              customHeadRender: columnMeta => {
                return (
                  <MUICustomHeader label={columnMeta.label}>
                    <>
                      {columnMeta.label}
                      <VerifiedUserOutlinedIcon
                        className={classes.thIcon}
                        color="secondary"
                      />
                    </>
                  </MUICustomHeader>
                );
              },
            },
          },
          {
            name: "Plan",
            options: {
              customBodyRender: value => {
                return (
                  <div style={{ width: 80 }}>{parseUserPlanHtml(value)}</div>
                );
              },

              customHeadRender: columnMeta => {
                return (
                  <MUICustomHeader label={columnMeta.label}>
                    <>
                      {columnMeta.label}
                      <CardMembershipOutlinedIcon
                        className={classes.thIcon}
                        color="secondary"
                      />
                    </>
                  </MUICustomHeader>
                );
              },
            },
          },
          {
            name: "Role",
            options: {
              customBodyRender: value => {
                return parseCapitalizeHtml(value);
              },
              customHeadRender: columnMeta => {
                return (
                  <MUICustomHeader label={columnMeta.label}>
                    <>
                      {columnMeta.label}
                      <FontAwesomeIcon
                        icon={faUserTag}
                        className={classes.faIcon}
                      />
                    </>
                  </MUICustomHeader>
                );
              },
            },
          },
          {
            name: "Registration",
            options: {
              customBodyRenderLite: dataIndex => {
                let val = users[dataIndex]._createdOn;
                return parseDateHtml(val, 130);
              },
              customHeadRender: columnMeta => {
                return (
                  <MUICustomHeader label={columnMeta.label}>
                    <>
                      {columnMeta.label}
                      <CalendarTodayOutlinedIcon
                        className={classes.thIcon}
                        color="secondary"
                      />
                    </>
                  </MUICustomHeader>
                );
              },
              setCellHeaderProps: () => ({
                className: "globalCustomMuiTableHead",
              }),
            },
          },
          {
            name: "Last Login",
            options: {
              customBodyRenderLite: dataIndex => {
                let val = users[dataIndex]._lastAccessOn;
                return parseDateHtml(val);
              },
              customHeadRender: columnMeta => {
                return (
                  <MUICustomHeader label={columnMeta.label}>
                    <>
                      {columnMeta.label}
                      <EventNoteOutlinedIcon
                        className={classes.thIcon}
                        color="secondary"
                      />
                    </>
                  </MUICustomHeader>
                );
              },
              setCellHeaderProps: () => ({
                className: "globalCustomMuiTableHead",
              }),
            },
          },
          {
            name: "# Logins",
            options: {
              customBodyRender: value => {
                return (
                  <Box width={80}>
                    {Array.isArray(value) ? value.length : 0}
                  </Box>
                );
              },
              customHeadRender: columnMeta => {
                return (
                  <MUICustomHeader label={columnMeta.label}>
                    <>
                      {columnMeta.label}
                      <VpnKeyOutlinedIcon
                        className={classes.thIcon}
                        color="secondary"
                      />
                    </>
                  </MUICustomHeader>
                );
              },
            },
          },
        ]}
        options={{
          download: false,
          print: false,
          onRowSelectionChange: (
            currentRowsSelected,
            allRowsSelected,
            rowsSelected
          ) => {
            setSelectedIndexes(rowsSelected);
          },
          customToolbarSelect: () => {},
          rowsSelected: selectedIndexes,
        }}
      />
    </Fragment>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  getUsersList: filter => dispatch(UserAction.getUsersList(filter)),
  removeUser: (id, permanent) => dispatch(UserAction.removeUser(id, permanent)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserTable);
