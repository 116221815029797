import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  container: {
    backgroundColor: "#BDBDBD",
    backgroundImage: "linear-gradient(315deg, #BDBDBD 0%, #f2f2f2 74%)",
    padding: 15,
    minHeight: "100vh",
  },
  title: {
    fontWeight: "bold",
    fontSize: "1.7rem",
    marginBottom: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  cardContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    maxWidth: 800,
    background: "white",
    borderRadius: 10,
    padding: "45px 25px",
    [theme.breakpoints.up("md")]: {
      padding: "45px",
    },
    // boxShadow: "0 5px 10px rgba(0, 0, 0, 0.1)",
    minHeight: 450,
    overflow: "hidden",
  },
  logo: {
    width: 300,
    height: 125,
  },
  leftBox: {
    width: "40%",
  },
  rightBox: {
    width: "60%",
  },
}));
