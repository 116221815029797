import { Box } from "@material-ui/core";
import React from "react";
import useStyles from "./styles";

const VisitorFormTitle = ({ title }) => {
  const classes = useStyles();

  return <Box className={classes.title}>{title}</Box>;
};

export default VisitorFormTitle;
