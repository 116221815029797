import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import { IS_AUTEHNTICATED } from "../localStorage/constants";

const AuthRoute = ({ component: Component, UserStore, render, ...rest }) => {
  const { authToken } = UserStore;
  const isAuthenticated = localStorage.getItem(IS_AUTEHNTICATED);

  return (
    <Route
      {...rest}
      render={
        render
          ? render
          : props =>
              authToken || isAuthenticated ? (
                <Component {...props} />
              ) : (
                <Redirect to={{ pathname: "/" }} />
              )
      }
    />
  );
};

const mapStateToProps = ({ UserStore }) => ({
  UserStore,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AuthRoute);
