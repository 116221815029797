import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const CustomTooltip = withStyles(theme => ({
  popper: {
    zIndex: 100001,
  },
  arrow: {
    "&:before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: "100%",
      height: "100%",
      backgroundColor: "#fff",
      transform: "rotate(45deg)",
      border: "1px solid rgba(0,0,0,.2)",
    },
  },
  tooltip: {
    maxWidth: "750px",
    backgroundColor: "#fff",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: 1.5,
    textDecoration: "none",
    textShadow: "none",
    textTransform: "none",
    letterSpacing: "normal",
    wordBreak: "normal",
    wordSpacing: "normal",
    whiteSpace: "normal",
    lineBreak: "auto",
    fontSize: ".875rem",
    border: "1px solid rgba(0,0,0,.2)",
    borderRadius: "0.3rem",
    padding: ".5rem .75rem",
    color: "#212529",
  },
}))(Tooltip);

export default CustomTooltip;
