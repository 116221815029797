import React, { createContext, useState } from "react";

const TopicDetailContext = createContext({
  topicDetail: null,
  setTopicDetail: data => {},
  activeSubTopic: null,
  setActiveSubtopic: subtitle => {},
  expandedList: [],
  setExpandedList: arr => {},
});

function TopicDetailProvider(props) {
  const [topicDetail, setTopicDetail] = useState(null);
  const [activeSubtopic, setActiveSubtopic] = useState(null);
  const [expandedList, setExpandedList] = useState([]);

  return (
    <TopicDetailContext.Provider
      value={{
        topicDetail,
        setTopicDetail: data => setTopicDetail(data),
        activeSubtopic,
        setActiveSubtopic: subtitle => setActiveSubtopic(subtitle),
        expandedList,
        setExpandedList: arr => setExpandedList(arr),
      }}
      {...props}
    />
  );
}

export { TopicDetailContext, TopicDetailProvider };
