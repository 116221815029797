import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";

import * as NoteActions from "../../actions/noteAction";
import NoteCard from "../noteList/NoteCard";
import {
  CustomDialogActions,
  CustomDialogTitle,
  CustomDialogContent,
  CustomDialog,
} from "../../Components/CustomDialogElements";

const useStyles = makeStyles(theme => ({
  okBtn: {
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: "none",
    },
  },
}));

const NoteModal = ({
  handleCloseNoteModal,
  showNoteModal,
  notes: fetchedNotes,
  removeNote,
}) => {
  const classes = useStyles();
  const [notes, setNotes] = useState([]);

  const onRemoveNote = async id => {
    const res = await removeNote(id);
    if (res.removeNote) {
      setNotes(notes.filter(note => note._id !== id));
    }
  };

  useEffect(() => {
    setNotes(fetchedNotes);
  }, [fetchedNotes]);
  return (
    <CustomDialog
      onClose={handleCloseNoteModal}
      open={showNoteModal}
      scroll={"paper"}
    >
      <CustomDialogTitle
        onClose={handleCloseNoteModal}
        className={classes.dialogTitle}
      >
        Notes
      </CustomDialogTitle>
      <CustomDialogContent>
        {notes.length > 0
          ? notes.map(note => (
              <NoteCard note={note} key={note._id} removeNote={onRemoveNote} />
            ))
          : "No notes available"}
      </CustomDialogContent>
      <CustomDialogActions style={{ padding: 20 }}>
        <Button
          onClick={handleCloseNoteModal}
          color="secondary"
          variant="contained"
          className={classes.okBtn}
        >
          OK
        </Button>
      </CustomDialogActions>
    </CustomDialog>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  updateNote: data => dispatch(NoteActions.updateNote(data)),
  removeNote: id => dispatch(NoteActions.removeNote(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NoteModal);
