import * as ActionTypes from "../actions/topicAction";

const { SET_TOPIC_LIST_STATE, CLEAR_TOPIC_LIST_STATE } = ActionTypes;

export const initialState = {
  topics: null,
  categories: null,
  selectedTopicsCategory: null,
  showTopicsCategory: true,
  selectedCategory: null,
  showCategory: true,
  selectedCategories: [],
  selectedTopics: [],
};

const topicListReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_TOPIC_LIST_STATE: {
      return {
        ...state,
        ...payload,
      };
    }
    case CLEAR_TOPIC_LIST_STATE: {
      return {
        ...state,
        selectedTopicsCategory: null,
        showTopicsCategory: true,
        selectedCategory: null,
        showCategory: true,
        selectedCategories: [],
        selectedTopics: [],
      };
    }

    default:
      return state;
  }
};

export default topicListReducer;
