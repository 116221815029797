import {
  Button,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Switch,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import React, { Fragment, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form/dist/index.ie11";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReply, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Editor } from "@tinymce/tinymce-react";

import * as UIActions from "../../actions/uiAction";
import * as ExamActions from "../../actions/examAction";
import * as TopicAction from "../../actions/topicAction";
import * as MediaAction from "../../actions/mediaAction";
import { AppConfig } from "../../configs";
import { preprocessImg } from "../../utils";
import { COURSE_APP_SUB_ROUTE } from "../../routes/constants";
import CustomSwitch from "../../Components/Switch/CustomSwitch";
import { uploadImageToDOSpace } from "../../services/uploadImage";
import { EDITOR_TOOLBAR, TABLE_TOOLBAR } from "./constants";

const QuestionForm = ({
  match,
  history,
  addError,
  getQuestionById,
  createQuestion,
  updateQuestion,
  getCategoryList,
  getTopicList,
  createMedia,
}) => {
  const {
    register,
    watch,
    handleSubmit,
    control,
    reset,
    errors,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      subject: "",
      system: "",
      topics: [],
      question: "",
      explanation: "",
      trial: false,
    },
  });
  const [subjects, setSubjects] = useState([]);
  const [topics, setTopics] = useState([]);
  const [questionId, setQuestionId] = useState(match.params.id);
  const [systems, setSystems] = useState([]);
  const [answerIndexes, setAnswerIndexes] = useState([0, 1]);
  const [answerCounter, setAnswerCounter] = useState(2);
  const [resourceIndexes, setResourceIndexes] = useState([]);
  const [resourceCounter, setResourceCounter] = useState(0);
  const [validAnswerIndex, setValidAnswerIndex] = useState(0);

  const loadInitial = async () => {
    const resCategoryList = await getCategoryList();
    if (resCategoryList && resCategoryList.categories) {
      setSubjects(
        resCategoryList.categories.filter(
          category => category.type === "subject"
        )
      );
      setSystems(
        resCategoryList.categories.filter(
          category => category.type === "system"
        )
      );
    }

    const resTopicList = await getTopicList();
    if (resTopicList && resTopicList.lessons)
      setTopics(
        resTopicList.lessons.filter(topic => topic.status === "active")
      );

    if (!questionId) return;

    const { question: questionData } = await getQuestionById(questionId);

    if (!questionData)
      return history.push(`${COURSE_APP_SUB_ROUTE}/manage-question`);

    setAnswerIndexes(
      [...Array(questionData.answers.length)].map((_, index) => index)
    );
    setAnswerCounter(questionData.answers.length);
    setValidAnswerIndex(
      questionData.answers.findIndex(item => item.validAnswer)
    );

    if (questionData.resources) {
      setResourceIndexes(
        [...Array(questionData.resources.length)].map((_, index) => index)
      );
      setResourceCounter(questionData.resources.length);
    }

    reset({
      question: questionData.question,
      subject: questionData.category ? questionData.category._id : "",
      system: questionData.subCategory ? questionData.subCategory._id : "",
      topics: questionData.lessons.map(lesson => ({
        _id: lesson._id,
        title: lesson.title,
      })),
      explanation: questionData.explanation,
      answers: questionData.answers.map(answer => answer.content),
      resourcesName: questionData.resources
        ? questionData.resources.map(resource => resource.name)
        : [],
      resourcesUrl: questionData.resources
        ? questionData.resources.map(resource => resource.url)
        : [],
      trial: questionData.trial || false,
    });
  };

  useEffect(() => {
    loadInitial();
  }, []);

  const onSubmit = async ({
    subject,
    system,
    topics,
    question,
    explanation,
    answers,
    answerValid,
    resourcesName,
    resourcesUrl,
    trial,
  }) => {
    const data = {
      category_id: subject,
      subCategory_id: system,
      lesson_ids: topics.map(topic => topic._id),
      course_id: "5faa70739cbf26001ac2f828",
      question,
      explanation,
      publish: true,
      type: "single",
      tags: ["question"],
      answers: answers
        .map((answer, index) => ({
          content: answer.trim(),
          validAnswer: answerValid[index],
        }))
        .filter(answer => !!answer.content),
      resources: resourcesName
        ? resourcesName
            .map((name, index) => ({
              name: name,
              url: resourcesUrl[index],
            }))
            .filter(resource => !!resource.name)
        : [],
      trial,
    };
    if (questionId) {
      const { updateQuestion: updateQuestionData } = await updateQuestion({
        _id: questionId,
        input: data,
      });
      if (!updateQuestionData) return;
    } else {
      const { createQuestion: createQuestionData } = await createQuestion({
        input: data,
      });
      if (!createQuestionData) return;
    }

    history.push(`${COURSE_APP_SUB_ROUTE}/manage-question`);
  };

  const addAnswer = () => {
    setAnswerIndexes(indexes => [...indexes, answerCounter]);
    setAnswerCounter(counter => counter + 1);
  };

  const removeAnswer = index => {
    if (answerIndexes.length <= 2) {
      addError("There must be at least 2 questions");
      return;
    }
    setAnswerIndexes(indexes => [...indexes.filter(item => item !== index)]);
    if (index === validAnswerIndex) setValidAnswerIndex(answerIndexes[0]);
  };

  const onAnswerCheckValid = index => {
    setValidAnswerIndex(index);
  };

  const addResource = () => {
    setResourceIndexes(indexes => [...indexes, resourceCounter]);
    setResourceCounter(counter => counter + 1);
  };

  const removeResource = index => {
    setResourceIndexes(indexes => [...indexes.filter(item => item !== index)]);
  };

  return (
    <Fragment>
      <div className="pageCont createQuestionPage">
        <div className="bodyCont">
          <div className="pageAction">
            <div className="pageActionLeft">
              <Link to={`${COURSE_APP_SUB_ROUTE}/manage-question`}>
                <FontAwesomeIcon icon={faReply} />
                Back to question list
              </Link>
            </div>
          </div>
          <div className="pageContent">
            <h4 className="pageTitle hiddenWeb hiddenTablet">
              {questionId ? "Update" : "Create"} Question
            </h4>
            <h3 className="pageTitle hiddenMobile">
              {questionId ? "Update" : "Create"} Question
            </h3>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="createQuestionForm"
            >
              <Controller
                control={control}
                name="subject"
                rules={{ required: "Subject cannot be empty" }}
                render={({ onChange, onBlur, value, name, ref }) => (
                  <TextField
                    name={name}
                    label="Subject"
                    value={value}
                    onChange={e => {
                      onChange(e.target.value);
                      setValue("system", "");
                    }}
                    error={!!errors.subject}
                    inputRef={ref}
                    helperText={errors.subject && errors.subject.message}
                    InputProps={{
                      style: { fontSize: 15 },
                    }}
                    InputLabelProps={{
                      style: { fontSize: 15, color: "rgba(0, 0, 0, 0.25)" },
                    }}
                    FormHelperTextProps={{
                      style: { fontSize: 13 },
                    }}
                    select
                    fullWidth
                    color="secondary"
                  >
                    <MenuItem value={null} disabled>
                      Please choose a subject
                    </MenuItem>
                    {subjects.map(subject => (
                      <MenuItem value={subject._id} key={subject._id}>
                        {subject.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                control={control}
                name="system"
                rules={{ required: "System cannot be empty" }}
                render={({ onChange, onBlur, value, name, ref }) => (
                  <TextField
                    name={name}
                    label="System"
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    error={!!errors.system}
                    inputRef={ref}
                    helperText={errors.system && errors.system.message}
                    InputProps={{
                      style: { fontSize: 15 },
                    }}
                    InputLabelProps={{
                      style: { fontSize: 15, color: "rgba(0, 0, 0, 0.25)" },
                    }}
                    FormHelperTextProps={{
                      style: { fontSize: 13 },
                    }}
                    select
                    fullWidth
                    color="secondary"
                  >
                    {systems
                      .filter(system =>
                        Array.isArray(system.parents)
                          ? system.parent_ids.includes(watch("subject"))
                          : false
                      )
                      .map(system => (
                        <MenuItem value={system._id} key={system._id}>
                          {system.name}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
              />
              <Controller
                control={control}
                name="topics"
                rules={{
                  validate: value =>
                    value.length > 0
                      ? true
                      : "Please select at least one topic",
                }}
                render={({ onChange, onBlur, value, name, ref }) => (
                  <Autocomplete
                    multiple
                    name={name}
                    value={value}
                    options={topics.map(topic => ({
                      _id: topic._id,
                      title: topic.title,
                    }))}
                    disableCloseOnSelect
                    getOptionLabel={topic => topic.title}
                    fullWidth
                    color="secondary"
                    onChange={(event, newValue) => {
                      onChange(newValue);
                    }}
                    getOptionSelected={(option, value) => {
                      return option._id === value._id;
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder="Search topics"
                        fullWidth
                        color="secondary"
                        label="Topics"
                        error={!!errors.topics}
                        helperText={errors.topics && errors.topics.message}
                      />
                    )}
                  />
                )}
              />

              <Controller
                control={control}
                name="trial"
                render={({ onChange, value, name }) => (
                  <FormControlLabel
                    control={
                      <CustomSwitch
                        checked={value}
                        onChange={e => onChange(e.target.checked)}
                        name={name}
                        disabled={Boolean(questionId)}
                      />
                    }
                    label="Free Trial"
                  />
                )}
              />

              {/* Question Input Section Start here */}
              <p
                style={{
                  color: "rgba(0, 0, 0, 0.25)",
                  fontSize: "15px",
                }}
              >
                Question
              </p>
              <Controller
                control={control}
                name="question"
                rules={{ required: "Question cannot be empty" }}
                render={({ onChange, onBlue, value, name, ref }) => (
                  <Editor
                    apiKey={AppConfig.tinyMceApiKey}
                    value={value}
                    init={{
                      height: 500,
                      branding: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      menubar: "insert edit table",
                      toolbar: EDITOR_TOOLBAR,
                      table_toolbar: TABLE_TOOLBAR,
                      images_upload_handler: async function(
                        blobInfo,
                        success,
                        failure
                      ) {
                        if (!questionId) {
                          failure("Please only upload image in edit mode");
                          return;
                        }

                        try {
                          const preprocessedImg = await preprocessImg(blobInfo);
                          const data = await uploadImageToDOSpace(
                            preprocessedImg
                          );
                          if (data.Location) {
                            const input = {
                              url: data.Location,
                              filename: preprocessedImg.filename,
                              size: preprocessedImg.size,
                              question_id: questionId ? questionId : "",
                            };
                            const res = await createMedia(input);
                            if (res) success(data.Location);
                          }
                        } catch (err) {
                          failure(err);
                        }
                      },
                    }}
                    onEditorChange={(content, _editor) => {
                      onChange(content);
                    }}
                  />
                )}
              />
              {errors.question && (
                <FormHelperText error>{errors.question.message}</FormHelperText>
              )}

              {/* Explanation Input Section Start here */}
              <p
                style={{
                  color: "rgba(0, 0, 0, 0.25)",
                  fontSize: "15px",
                }}
              >
                Explanation
              </p>
              <Controller
                control={control}
                name="explanation"
                rules={{ required: "Explanation cannot be empty" }}
                render={({ onChange, onBlue, value, name, ref }) => (
                  <Editor
                    apiKey={AppConfig.tinyMceApiKey}
                    value={value}
                    init={{
                      height: 500,
                      branding: false,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      menubar: "insert edit table",
                      toolbar: EDITOR_TOOLBAR,
                      table_toolbar: TABLE_TOOLBAR,
                      images_upload_handler: async function(
                        blobInfo,
                        success,
                        failure
                      ) {
                        if (!questionId) {
                          failure("Please only upload image in edit mode");
                          return;
                        }

                        try {
                          const preprocessedImg = await preprocessImg(blobInfo);

                          const data = await uploadImageToDOSpace(
                            preprocessedImg
                          );
                          if (data.Location) {
                            const input = {
                              url: data.Location,
                              filename: preprocessedImg.filename,
                              size: preprocessedImg.size,
                              question_id: questionId ? questionId : "",
                            };
                            const res = await createMedia(input);
                            if (res) success(data.Location);
                          }
                        } catch (err) {
                          failure(err);
                        }
                      },
                    }}
                    onEditorChange={(content, _editor) => {
                      onChange(content);
                    }}
                  />
                )}
              />

              {errors.explanation && (
                <FormHelperText error>
                  {errors.explanation.message}
                </FormHelperText>
              )}

              {answerIndexes.map((item, index) => (
                <div className="inlineFormControl" key={item}>
                  <Controller
                    control={control}
                    name={`answers[${item}]`}
                    rules={{ required: "Answer cannot be empty" }}
                    defaultValue={""}
                    render={({ onChange, onBlue, value, name, ref }) => (
                      <TextField
                        name={name}
                        label={`Answer ${index + 1}`}
                        value={value}
                        inputRef={ref}
                        onChange={e => onChange(e.target.value)}
                        error={errors.answers && !!errors.answers[item]}
                        helperText={
                          errors.answers &&
                          errors.answers[item] &&
                          errors.answers[item].message
                        }
                        InputProps={{
                          style: { fontSize: 15 },
                        }}
                        InputLabelProps={{
                          style: { fontSize: 15, color: "rgba(0, 0, 0, 0.25)" },
                        }}
                        FormHelperTextProps={{
                          style: { fontSize: 13 },
                        }}
                        fullWidth
                        color="secondary"
                      />
                    )}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={item === validAnswerIndex}
                        inputRef={register}
                        name={`answerValid[${item}]`}
                        onChange={() => onAnswerCheckValid(item)}
                      />
                    }
                    label="Valid"
                  />
                  <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => removeAnswer(item)}
                  />
                </div>
              ))}
              <Button
                onClick={addAnswer}
                variant="outlined"
                color="secondary"
                fullWidth
              >
                Add Answer
              </Button>
              {resourceIndexes.map((item, index) => (
                <div className="inlineFormControl" key={item}>
                  <Controller
                    control={control}
                    name={`resourcesName[${item}]`}
                    rules={{ required: "Name cannot be empty" }}
                    defaultValue={""}
                    render={({ onChange, onBlue, value, name, ref }) => (
                      <TextField
                        variant="outlined"
                        name={name}
                        label={`Resource Name ${index + 1}`}
                        value={value}
                        inputRef={ref}
                        onChange={e => onChange(e.target.value)}
                        error={
                          errors.resourcesName && !!errors.resourcesName[item]
                        }
                        helperText={
                          errors.resourcesName &&
                          errors.resourcesName[item] &&
                          errors.resourcesName[item].message
                        }
                        InputProps={{
                          style: { fontSize: 15 },
                        }}
                        InputLabelProps={{
                          style: { fontSize: 15, color: "rgba(0, 0, 0, 0.25)" },
                        }}
                        FormHelperTextProps={{
                          style: { fontSize: 13 },
                        }}
                        fullWidth
                        color="secondary"
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`resourcesUrl[${item}]`}
                    rules={{ required: "URL cannot be empty" }}
                    defaultValue={""}
                    render={({ onChange, onBlue, value, name, ref }) => (
                      <TextField
                        variant="outlined"
                        name={name}
                        label={`Resource URL ${index + 1}`}
                        value={value}
                        inputRef={ref}
                        onChange={e => onChange(e.target.value)}
                        error={errors.resourceUrl && !!errors.resourceUrl[item]}
                        helperText={
                          errors.resourceUrl &&
                          errors.resourceUrl[item] &&
                          errors.resourceUrl[item].message
                        }
                        InputProps={{
                          style: { fontSize: 15 },
                        }}
                        InputLabelProps={{
                          style: { fontSize: 15, color: "rgba(0, 0, 0, 0.25)" },
                        }}
                        FormHelperTextProps={{
                          style: { fontSize: 13 },
                        }}
                        fullWidth
                        color="secondary"
                      />
                    )}
                  />
                  <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => removeResource(item)}
                  />
                </div>
              ))}
              <Button
                onClick={addResource}
                variant="outlined"
                color="secondary"
                fullWidth
              >
                Add Resource
              </Button>
            </form>
          </div>
        </div>
        <Button
          onClick={handleSubmit(onSubmit)}
          color="secondary"
          variant="contained"
          className="submitButton"
          size="large"
        >
          {questionId ? "Update" : "Create"}
        </Button>
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  addError: msg => dispatch(UIActions.addError(msg)),
  getQuestionById: id => dispatch(ExamActions.getQuestionById(id)),
  createQuestion: data => dispatch(ExamActions.createQuestion(data)),
  updateQuestion: data => dispatch(ExamActions.updateQuestion(data)),
  getCategoryList: () => dispatch(ExamActions.getCategoryList()),
  getTopicList: () => dispatch(TopicAction.getTopicList()),
  createMedia: data => dispatch(MediaAction.createMedia(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(QuestionForm)
);
