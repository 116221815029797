import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  logo: {
    width: 250,
    height: 90,
  },
  printBtn: {
    height: 40,
    "@media print": {
      display: "none",
    },
  },
}));
