import { DISCOUNT_UNIT, FREE_TRIAL_PLAN_NAME } from "../../../constants";

export const getGrandTotalAndGrandDiscount = (planPrice, unit, discount) => {
  let grandTotal = planPrice;
  let totalDiscount = 0;

  if (!discount || !planPrice || !unit)
    return {
      grandTotal: grandTotal.toFixed(2),
      totalDiscount: totalDiscount.toFixed(2),
    };

  switch (unit) {
    case DISCOUNT_UNIT.AMOUNT:
      totalDiscount = discount.toFixed(2);
      break;
    case DISCOUNT_UNIT.PERCENTAGE:
      const discountPercentage = Number(discount) / 100;
      totalDiscount = (planPrice * discountPercentage).toFixed(2);
      break;
    default:
      totalDiscount = totalDiscount.toFixed(2);
  }
  grandTotal = (Number(planPrice) - Number(totalDiscount)).toFixed(2);
  return { grandTotal, totalDiscount };
};

export const checkIsFreeTrialPlan = cartItems =>
  Array.isArray(cartItems) &&
  cartItems.length > 0 &&
  cartItems[0].title === FREE_TRIAL_PLAN_NAME;
