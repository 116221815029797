import { makeStyles } from "@material-ui/styles";
import { COLOR_GREY, COLOR_WHITE } from "../../../../../styles/constants";

export default makeStyles(theme => ({
  planBoxContainer: {
    position: "relative",
    borderRadius: 20,
    backgroundColor: props =>
      props.disabled ? COLOR_GREY : theme.palette.primary.main,
    height: 200,
    marginBottom: theme.spacing(10),
    width: "100%",
    [theme.breakpoints.between("md", "lg")]: {
      maxWidth: 400,
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: 490,
    },
  },
  selectedPlanBox: {
    border: `5px solid ${theme.palette.primary.dark}`,
    borderRadius: 20,
  },
  priceBox: {
    position: "absolute",
    backgroundColor: COLOR_WHITE,
    boxShadow: "0px 0px 20px 5px rgba(0, 0, 0, 0.08)",
    borderRadius: 10,
    height: 90,
    width: "80%",
    top: "78%",
    left: "10%",
    zIndex: 1,
  },
  selectedPriceBox: {
    border: `5px solid ${theme.palette.primary.dark}`,
  },
  pricePlanTitle: {
    fontSize: "1.8rem",
    [theme.breakpoints.up("lg")]: {
      fontSize: "2.4rem",
    },
  },
  arrowIcon: {
    width: 12.5,
    height: 25,
    marginBottom: theme.spacing(0.5),
  },
  crossIcon: {
    width: 20,
    height: 20,
  },
}));
