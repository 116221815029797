import React from 'react';
import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux';

const AdminGuardedRoute = ({ component: Component, UserStore, ...rest }) => {
  const { type } = UserStore

  return (
    <Route {...rest} render={(props) => (
      type === 'admin' ?
        <Component {...props} /> : <Redirect to={{ pathname: '/' }} />
    )} />
  )
};

const mapStateToProps = ({ UserStore }) => ({
  UserStore
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminGuardedRoute)
