import { gql } from "@apollo/client";

export const GET_SUBSCRIPTION = gql`
  query Subscription($_id: ObjectId!) {
    subscription(_id: $_id) {
      _id
      isValid
      planEndOn
      planStartOn
      resetLimit
      _resetHistory {
        _createdOn
        _id
      }
      resetUsage
      status
      _planHistory {
        _id
        plan_id
        transaction_id
        _createdOn
        _expiredOn
        _activatedOn
        _refundedOn
        term {
          _id
          price
          term
          unit
          title
        }
        source
        status
      }
    }
  }
`;

export const GET_SUBSCRIPTION_BY_PROFILE = gql`
  query GetSubscriptionByProfile {
    profile {
      subscription {
        _id
        isValid
        paidBefore
        planEndOn
        planStartOn
        resetLimit
        _resetHistory {
          _createdOn
          _id
        }
        resetUsage
        status
        _planHistory {
          _id
          plan_id
          transaction_id
          _createdOn
          _expiredOn
          _activatedOn
          _refundedOn
          term {
            _id
            price
            term
            unit
            title
          }
          source
          status
        }
      }
    }
  }
`;

export const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateSubscription(
    $_id: ObjectId!
    $input: UpdateSubscriptionInput!
  ) {
    updateSubscription(_id: $_id, input: $input) {
      _id
    }
  }
`;

export const RESET_SUBSCRIPTION = gql`
  mutation ResetSubscription($_id: ObjectId!, $input: ResetSubscriptionInput!) {
    resetSubscription(_id: $_id, input: $input) {
      _id
      isValid
      planEndOn
      planStartOn
      resetLimit
      _resetHistory {
        _createdOn
        _id
      }
      resetUsage
      status
      _planHistory {
        _id
        plan_id
        transaction_id
        _createdOn
        _expiredOn
        _activatedOn
        _refundedOn
        term {
          _id
          price
          term
          unit
          title
        }
        source
        status
      }
    }
  }
`;

export const SUBSCRIBE_PLAN_WITH_PAYPAL = gql`
  mutation SubscribePlanWithPaypal($input: SubscribePlanInput!) {
    subscribePlanWithPaypal(input: $input) {
      intent
      purchase_units {
        amount {
          currency_code
          value
        }
      }
      payer {
        name {
          given_name
          surname
        }
        email_address
      }
      application_context {
        brand_name
        locale
        shipping_preference
        user_action
        payment_method {
          payer_selected
          payee_preferred
        }
      }
    }
  }
`;

export const SUBSCRIBE_PLAN_WITH_STRIPE = gql`
  mutation SubscribePlanWithStripe($input: SubscribePlanInput!) {
    subscribePlanWithStripe(input: $input) {
      url
    }
  }
`;

export const PAYPAL_PAYMENT_APPROVED = gql`
  mutation PaypalPaymentApproved($input: PaypalPaymentApprovedInput!) {
    paypalPaymentApproved(input: $input) {
      _id
      isValid
      planEndOn
      planStartOn
      resetLimit
      _resetHistory {
        _createdOn
        _id
      }
      resetUsage
      status
      _planHistory {
        _id
        plan_id
        transaction_id
        _createdOn
        _expiredOn
        _activatedOn
        _refundedOn
        term {
          _id
          price
          term
          unit
          title
        }
        source
        status
      }
    }
  }
`;

export const ACTIVATE_SUBSCRIPTION = gql`
  mutation ActivateSubscription($_id: ObjectId!, $planHistoryID: ObjectId!) {
    activateSubscription(_id: $_id, planHistoryID: $planHistoryID) {
      _id
      isValid
      planEndOn
      planStartOn
      resetLimit
      _resetHistory {
        _createdOn
        _id
      }
      resetUsage
      status
      _planHistory {
        _id
        plan_id
        transaction_id
        _createdOn
        _expiredOn
        _activatedOn
        _refundedOn
        term {
          _id
          price
          term
          unit
          title
        }
        source
        status
      }
    }
  }
`;

export const REFUND_SUBSCRIPTION_PLAN = gql`
  mutation RefundPaypalSubscriptionPlan($transaction_id: ObjectId!) {
    refundSubscriptionPlan(transaction_id: $transaction_id)
  }
`;

export const ASSIGN_SUBSCRIPTION_PLAN = gql`
  mutation assignSubscriptionPlan(
    $_id: ObjectId!
    $input: AssignSubscriptionPlanInput!
  ) {
    assignSubscriptionPlan(_id: $_id, input: $input) {
      _id
    }
  }
`;

export const ASSIGN_SUBSCRIPTION_TRIAL_PLAN = gql`
  mutation assignSubscriptionTrialPlan(
    $_id: ObjectId!
    $input: AssignSubscriptionTrialPlanInput!
  ) {
    assignSubscriptionTrialPlan(_id: $_id, input: $input) {
      _id
    }
  }
`;
