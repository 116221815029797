import * as UIActions from "./uiAction";
import * as MediaApi from "../services/media";
import { AppConfig } from "../configs";

const { client } = AppConfig;

export const getMediaList = filter => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.query({
      query: MediaApi.GET_MEDIAS,
      variables: { filter },
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else
      dispatch(
        UIActions.addError(
          "We are having problem getting medias. Please try again later."
        )
      );
  }
};

export const getMediaById = id => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.query({
      query: MediaApi.GET_MEDIA,
      variables: { _id: id },
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else
      dispatch(
        UIActions.addError(
          "We are having problem getting medias. Please try again later."
        )
      );
  }
};

export const createMedia = data => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: MediaApi.CREATE_MEDIA,
      variables: { input: data },
    });
    dispatch(UIActions.showSuccess("Media created successfully."));
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else dispatch(UIActions.addError("Error creating media"));
  }
};

export const updateMedia = data => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: MediaApi.UPDATE_MEDIA,
      variables: data,
    });
    dispatch(UIActions.showSuccess("Media updated successfully."));
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else dispatch(UIActions.addError("Error updating media"));
  }
};

export const removeMedia = (id, permanent) => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: MediaApi.REMOVE_MEDIA,
      variables: { _id: id, permanent },
    });
    dispatch(UIActions.showSuccess("Media removed successfully."));
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else dispatch(UIActions.addError("Error removing media"));
  }
};
