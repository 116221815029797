import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import { connect } from "react-redux";
import ReactLoading from "react-loading";
import { Helmet } from "react-helmet";
import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import jwt_decode from "jwt-decode";

// Main Stylesheet
import "./styles/incredible/incredible.css";
// Actions
import * as UIActions from "./actions/uiAction";
import * as UserActions from "./actions/userAction";
import * as SubscriptionActions from "./actions/subscriptionAction";
// Shared
import AuthRoute from "./shared/AuthRoute";
import AdminGuardedRoute from "./shared/AdminGuardedRoute";
import AuthFeatureRoute from "./shared/AuthFeatureRoute";
// Components
import NavBar from "./Components/NavBar";
import OverallPerformancePage from "./views/OverallPerformancePage";
import TopicDetails from "./views/topic/TopicDetails/TopicDetails";
import TopicList from "./views/topic/TopicList/TopicList";
import CreateTest from "./views/CreateTest";
import TestList from "./views/TestList";
import TestDetail from "./views/TestDetailPage";
import ReportsPage from "./views/performance/ReportsPage";
import GraphPage from "./views/performance/GraphsPage";
import SearchQuestionPage from "./views/SearchQuestionPage";
import NoteList from "./views/noteList/NoteList";
import FlashcardsPage from "./views/FlashcardsPage";
import NotFound from "./views/404";
import ImportQuestion from "./views/admin/import/ImportQuestion";
import ManageMedia from "./views/admin/ManageMedia";
import MediaForm from "./views/admin/MediaForm";
import ManageTopic from "./views/admin/ManageTopic";
import TopicForm from "./views/admin/TopicForm/TopicForm";
import UserForm from "./views/admin/UserForm/UserForm";
import ManageCategory from "./views/admin/ManageCategory";
import ManageQuestion from "./views/admin/ManageQuestion";
import ManageUser from "./views/admin/ManageUser/";
import QuestionForm from "./views/admin/QuestionForm";
import FeedbackPage from "./views/admin/ManageFeedback";
import LoginPage from "./views/auth/LoginPage";
import ResetPasswordPage from "./views/auth/ResetPasswordPage";
import HelpPage from "./views/HelpPage";
import TestTabsWrapper from "./views/TestTabsWrapper";
import CheckoutPage from "./views/app/Checkout/CheckoutPage";
import SubscriptionsPage from "./views/app/Subscriptions/SubscriptionsPage";
import ResetPage from "./views/ResetPage";

import { AppConfig } from "./configs";
import MainLayout from "./Components/Layout/MainLayout";
import { TopicDetailProvider } from "./context/topicDetail";
import theme from "./theme";
import {
  ACTIVATE_USER_URL,
  BILLING_URL,
  CART_URL,
  CHECKOUT_URL,
  COURSE_APP_SUB_ROUTE,
  CREATE_DISCOUNT_CODE_URL,
  CREATE_TEST_URL,
  EDIT_DISCOUNT_CODE_URL,
  EDIT_TRANSACTION_URL,
  EDIT_USER_URL,
  FLASHCARD_LIST_URL,
  FORGOT_PASSWORD_URL,
  HELP_URL,
  LOGIN_URL,
  MANAGE_DELETED_ITEM_URL,
  MANAGE_DISCOUNT_CODE_URL,
  MANAGE_MEDIA_URL,
  MANAGE_TRANSACTION_URL,
  NOTE_LIST_URL,
  ORDER_RECEIPT_URL,
  ORDER_SUCCESS_URL,
  PERFORMANCE_OVERALL_URL,
  PRICING_URL,
  PROFILE_URL,
  RESET_PASSWORD_URL,
  SUBSCRIPTIONS_URL,
  TEST_ANALYSIS_URL,
  TEST_DETAIL_FULL_URL,
  TEST_LIST_URL,
  TEST_RESULT_URL,
  TOPICS_URL,
  WELCOME_URL,
} from "./routes/constants";
import ManageTransaction from "./views/admin/ManageTransaction";
import TransactionForm from "./views/admin/TransactionForm";
import ActivateUserPage from "./views/ActivateUserPage";
import ProfilePage from "./views/app/Profile";
import CreateUserPage from "./views/admin/UserForm/CreateUserPage";
import BillingPage from "./views/app/Billing/BillingPage";
import ReceiptPage from "./views/Receipt/ReceiptPage";
import ManageVoucher from "./views/admin/Voucher/Manage/ManageVoucher";
import EditVoucherForm from "./views/admin/Voucher/Form/EditVoucherForm";
import CreateVoucherForm from "./views/admin/Voucher/Form/CreateVoucherForm";
import ManageDeletedItem from "./views/admin/DeletedItem/ManageDeletedItem";
import ForgotPasswordPage from "./views/auth/ForgotPasswordPage";
import OrderSuccessPage from "./views/app/Order/OrderSuccessPage";
import { isDisableTextSelectionPage } from "./utils";
import clsx from "clsx";

const { recaptcha_id, client } = AppConfig;

// Create an instance of Notyf
const notyf = new Notyf({
  duration: 4000,
  dismissible: true,
  position: { x: "center", y: "top" },
});

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currPosition: 0,
      showHeader: true,
      uiControls: {
        expandedTab: null,
      },
      openSideMenu: true,
    };
  }

  setOpenSideMenu = val => {
    this.setState({ openSideMenu: val });
  };

  fetchUserSubscription = async id => {
    const { getSubscriptionByProfile } = this.props;
    await getSubscriptionByProfile(id);
  };

  fetchUserProfile = async () => {
    const { getUserProfile } = this.props;
    await getUserProfile();
  };

  async componentDidMount() {
    const {
      UserStore: { authToken },
    } = this.props;
    let isMobile = false;

    if (authToken && this.props.location.pathname.includes(LOGIN_URL)) {
      this.props.history.push(SUBSCRIPTIONS_URL);
    }

    if (authToken) {
      const decoded = jwt_decode(authToken);
      if (decoded && decoded.id) {
        this.fetchUserSubscription(decoded.id);
      }
      this.fetchUserProfile();
    }

    if (
      !authToken &&
      this.props.location.pathname.includes(COURSE_APP_SUB_ROUTE)
    ) {
      this.props.history.push(LOGIN_URL);
    }

    if (!this.props.location.pathname.includes("/test-detail") && authToken) {
      this.props.updateShowSidemenu(true);
      this.props.updateShowHeader(true);
      this.props.updateShowFooter(true);
    } else {
      this.props.updateShowSidemenu(false);
      this.props.updateShowHeader(false);
      this.props.updateShowFooter(false);
    }

    if (window.screen.width < 768) {
      isMobile = true;
      this.props.updateShowSidemenu(false);
    }
    this.props.updateIsMobile(isMobile);
  }

  componentDidUpdate(prevProps) {
    const {
      UserStore: { authToken },
    } = this.props;

    if (authToken && this.props.location.pathname.includes(LOGIN_URL)) {
      this.props.history.push(SUBSCRIPTIONS_URL);
    }

    if (
      !authToken &&
      this.props.location.pathname.includes(COURSE_APP_SUB_ROUTE)
    ) {
      this.props.history.push(LOGIN_URL);
    }

    if (
      this.props.errorMessage &&
      (!prevProps.errorMessage ||
        prevProps.errorMessage !== this.props.errorMessage)
    ) {
      notyf.error(this.props.errorMessage);
      this.props.clearErrMsg();
    }

    if (
      this.props.successMessage &&
      (!prevProps.successMessage ||
        prevProps.successMessage !== this.props.successMessage)
    ) {
      notyf.success(this.props.successMessage);
      this.props.clearSuccMsg();
    }

    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (!this.props.location.pathname.includes("/test-detail") && authToken) {
        if (!this.props.isMobile) {
          this.props.updateShowSidemenu(true);
        } else {
          this.props.updateShowSidemenu(false);
        }
        this.props.updateShowHeader(true);
        this.props.updateShowFooter(true);
      } else {
        this.props.updateShowSidemenu(false);
        this.props.updateShowHeader(false);
        this.props.updateShowFooter(false);
      }

      this.setState({ showHeader: true });
    }
  }

  handleNavigate = (path, withinTab) => {
    if (!withinTab) {
      this.setState({
        uiControls: {
          ...this.state.uiControls,
          expandedTab: null,
        },
      });
    }
    if (this.props.location.pathname !== path) {
      this.props.history.push(path);
    }
  };

  onExpand = tab => {
    const { expandedTab } = this.state.uiControls;
    let newExpandedTab = null;

    if (expandedTab !== tab) {
      newExpandedTab = tab;
    }

    this.setState({
      uiControls: {
        ...this.state.uiControls,
        expandedTab: newExpandedTab,
      },
    });
  };

  handleLogout = async () => {
    const { logout } = this.props;
    await logout();

    this.props.updateShowHeader(false);
    this.props.updateShowFooter(false);
  };

  render() {
    const {
      loading,
      showAppHeader,
      showAppFooter,
      UserStore: { userInfo },
      SubscriptionStore: { subscription },
      location,
    } = this.props;
    const shouldDisableTextSelection = isDisableTextSelectionPage(
      location.pathname
    );

    return (
      <ThemeProvider theme={theme}>
        <TopicDetailProvider>
          <ApolloProvider client={client}>
            <GoogleReCaptchaProvider reCaptchaKey={recaptcha_id}>
              <Helmet>
                <title>Question Bank Main</title>
                <meta name="description" content="testing react helmet" />
                <meta name="keywords" content="question,bank,test" />
                <meta
                  name="viewport"
                  content="minimum-scale=1, initial-scale=1, width=device-width"
                />
              </Helmet>
              <CssBaseline />

              {loading && (
                <div className="appLoadingCont">
                  <ReactLoading type="spinningBubbles" color="#ffc411" />
                </div>
              )}

              <div className={clsx(shouldDisableTextSelection && "noselect")}>
                <NavBar
                  openSideMenu={this.state.openSideMenu}
                  setOpenSideMenu={this.setOpenSideMenu}
                  showHeader={showAppHeader}
                />
                <MainLayout
                  openSideMenu={this.state.openSideMenu}
                  showAppFooter={showAppFooter}
                >
                  <Switch>
                    <Route exact path={LOGIN_URL} component={LoginPage} />
                    <Route
                      exact
                      path={FORGOT_PASSWORD_URL}
                      component={ForgotPasswordPage}
                    />
                    <Route
                      exact
                      path={RESET_PASSWORD_URL}
                      component={ResetPasswordPage}
                    />
                    <Route
                      exact
                      path={ACTIVATE_USER_URL}
                      component={ActivateUserPage}
                    />

                    <Route exact path={PRICING_URL} component={CheckoutPage} />
                    <Route exact path={CART_URL} component={CheckoutPage} />
                    <Route exact path={CHECKOUT_URL} component={CheckoutPage} />
                    <AuthRoute
                      exact
                      path={ORDER_SUCCESS_URL}
                      component={OrderSuccessPage}
                    />
                    <AuthRoute
                      exact
                      path={ORDER_RECEIPT_URL}
                      component={ReceiptPage}
                    />
                    <AuthRoute
                      exact
                      path={SUBSCRIPTIONS_URL}
                      component={SubscriptionsPage}
                    />
                    <AuthRoute
                      exact
                      path={PROFILE_URL}
                      component={ProfilePage}
                    />
                    <AuthRoute
                      exact
                      path={BILLING_URL}
                      component={BillingPage}
                    />
                    <AuthRoute
                      exact
                      path={WELCOME_URL}
                      component={OverallPerformancePage}
                    />
                    <AuthRoute exact path={HELP_URL} component={HelpPage} />

                    <AuthFeatureRoute
                      exact
                      path={TEST_LIST_URL}
                      component={TestList}
                    />
                    <AuthFeatureRoute
                      exact
                      path={TEST_DETAIL_FULL_URL}
                      component={TestDetail}
                    />
                    <AuthFeatureRoute
                      exact
                      path={TEST_RESULT_URL}
                      render={props => (
                        <TestTabsWrapper
                          {...props}
                          setOpenSideMenu={this.setOpenSideMenu}
                        />
                      )}
                    />
                    <AuthFeatureRoute
                      exact
                      path={TEST_ANALYSIS_URL}
                      render={props => (
                        <TestTabsWrapper
                          {...props}
                          setOpenSideMenu={this.setOpenSideMenu}
                        />
                      )}
                    />
                    <AuthFeatureRoute
                      exact
                      path={PERFORMANCE_OVERALL_URL}
                      component={OverallPerformancePage}
                    />
                    <AuthFeatureRoute
                      exact
                      path={`${COURSE_APP_SUB_ROUTE}/performance/reports`}
                      component={ReportsPage}
                    />
                    <AuthFeatureRoute
                      exact
                      path={`${COURSE_APP_SUB_ROUTE}/performance/graphs`}
                      component={GraphPage}
                    />
                    <AuthFeatureRoute
                      exact
                      path={`${COURSE_APP_SUB_ROUTE}/search`}
                      component={SearchQuestionPage}
                    />
                    <AuthFeatureRoute
                      path={NOTE_LIST_URL}
                      component={NoteList}
                    />
                    <AuthFeatureRoute
                      exact
                      path={FLASHCARD_LIST_URL}
                      component={FlashcardsPage}
                    />
                    <AuthFeatureRoute
                      exact
                      path={`${COURSE_APP_SUB_ROUTE}/reset`}
                      component={ResetPage}
                    />
                    <AuthFeatureRoute
                      exact
                      path={`${COURSE_APP_SUB_ROUTE}/topics/:id`}
                      component={TopicDetails}
                    />
                    <AuthFeatureRoute
                      exact
                      path={TOPICS_URL}
                      component={TopicList}
                    />
                    <AuthFeatureRoute
                      exact
                      path={CREATE_TEST_URL}
                      component={CreateTest}
                    />
                    <AdminGuardedRoute
                      exact
                      path={`${COURSE_APP_SUB_ROUTE}/import-question`}
                      component={ImportQuestion}
                    />
                    <AdminGuardedRoute
                      exact
                      path={MANAGE_MEDIA_URL}
                      component={ManageMedia}
                    />
                    <AdminGuardedRoute
                      exact
                      path={`${COURSE_APP_SUB_ROUTE}/manage-media/:id/edit`}
                      component={MediaForm}
                    />
                    <AdminGuardedRoute
                      exact
                      path={`${COURSE_APP_SUB_ROUTE}/manage-topic`}
                      component={ManageTopic}
                    />
                    <AdminGuardedRoute
                      exact
                      path={`${COURSE_APP_SUB_ROUTE}/manage-topic/create`}
                      component={TopicForm}
                    />
                    <AdminGuardedRoute
                      exact
                      path={`${COURSE_APP_SUB_ROUTE}/manage-topic/:id/edit`}
                      component={TopicForm}
                    />
                    <AdminGuardedRoute
                      exact
                      path={`${COURSE_APP_SUB_ROUTE}/manage-category`}
                      component={ManageCategory}
                    />
                    <AdminGuardedRoute
                      exact
                      path={`${COURSE_APP_SUB_ROUTE}/manage-question`}
                      component={ManageQuestion}
                    />
                    <AdminGuardedRoute
                      exact
                      path={`${COURSE_APP_SUB_ROUTE}/manage-question/create`}
                      component={QuestionForm}
                    />
                    <AdminGuardedRoute
                      exact
                      path={`${COURSE_APP_SUB_ROUTE}/manage-question/:id/edit`}
                      component={QuestionForm}
                    />
                    <AdminGuardedRoute
                      exact
                      path={`${COURSE_APP_SUB_ROUTE}/manage-feedback`}
                      component={FeedbackPage}
                    />
                    <AdminGuardedRoute
                      exact
                      path={MANAGE_TRANSACTION_URL}
                      component={ManageTransaction}
                    />
                    <AdminGuardedRoute
                      exact
                      path={EDIT_TRANSACTION_URL}
                      component={TransactionForm}
                    />
                    <AdminGuardedRoute
                      exact
                      path={MANAGE_DISCOUNT_CODE_URL}
                      component={ManageVoucher}
                    />
                    <AdminGuardedRoute
                      exact
                      path={CREATE_DISCOUNT_CODE_URL}
                      component={CreateVoucherForm}
                    />
                    <AdminGuardedRoute
                      exact
                      path={EDIT_DISCOUNT_CODE_URL}
                      component={EditVoucherForm}
                    />
                    <AdminGuardedRoute
                      exact
                      path={`${COURSE_APP_SUB_ROUTE}/manage-user`}
                      component={ManageUser}
                    />
                    <AdminGuardedRoute
                      exact
                      path={EDIT_USER_URL}
                      component={UserForm}
                    />
                    <AdminGuardedRoute
                      exact
                      path={`${COURSE_APP_SUB_ROUTE}/manage-user/invite`}
                      component={CreateUserPage}
                    />
                    <AdminGuardedRoute
                      exact
                      path={MANAGE_DELETED_ITEM_URL}
                      component={ManageDeletedItem}
                    />
                    <Route component={NotFound} />
                  </Switch>
                </MainLayout>
              </div>
            </GoogleReCaptchaProvider>
          </ApolloProvider>
        </TopicDetailProvider>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = ({ UiStore, UserStore, SubscriptionStore }) => ({
  errorMessage: UiStore.errorMessage,
  successMessage: UiStore.successMessage,
  loading: UiStore.loading,
  showSidemenu: UiStore.showSidemenu,
  showAppHeader: UiStore.showAppHeader,
  showAppFooter: UiStore.showAppFooter,
  isMobile: UiStore.isMobile,
  UserStore,
  SubscriptionStore,
});

const mapDispatchToProps = dispatch => ({
  clearErrMsg: () => dispatch(UIActions.resetError()),
  clearSuccMsg: () => dispatch(UIActions.resetSuccess()),
  updateIsMobile: bool => dispatch(UIActions.updateIsMobile(bool)),
  updateShowSidemenu: bool => dispatch(UIActions.updateShowSidemenu(bool)),
  updateShowHeader: bool => dispatch(UIActions.updateShowHeader(bool)),
  login: body => dispatch(UserActions.login(body)),
  updateShowFooter: bool => dispatch(UIActions.updateShowFooter(bool)),
  logout: () => dispatch(UserActions.logout()),
  getSubscriptionByProfile: userId =>
    dispatch(SubscriptionActions.getSubscriptionByProfile(userId)),
  getUserProfile: () => dispatch(UserActions.getUserProfile()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
