import React, { Fragment, useEffect, useContext, useState } from "react";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import { scroller } from "react-scroll";
import clsx from "clsx";

import * as TopicAction from "../../../actions/topicAction";
import * as FlashcardActions from "../../../actions/flashcardAction";
import { TopicDetailContext } from "../../../context/topicDetail";
import { parseTopicReferencesHTML, parseLightBoxHTML } from "../../../utils";
import useStyles from "./styles";
import useProtectContent from "../../../hooks/useProtectContent";
import { ReactComponent as ExpandCollapseIcon } from "../../../resources/assets/svg/expand-collapse.svg";
import {
  FLASHCARD_LIST_TYPE,
  FLASHCARD_MODE_TYPE,
  TOPIC_SUBTOPIC_TITLE,
} from "../../../constants";
import ImagePreviewModal from "../../TestDetailPage/modal/themedModal/ImagePreviewModal";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../components/Accordion";
import FlashcardListModal from "../../TestDetailPage/modal/themedModal/FlashcardListModal";
import history from "../../../routes/history";
import { TOPICS_URL } from "../../../routes/constants";

const TopicDetails = ({
  match,
  getTopicById,
  getFlashcards,
  removeFlashcard,
  createFlashcard,
  updateFlashcard,
}) => {
  const classes = useStyles();

  const {
    topicDetail,
    setTopicDetail,
    setActiveSubtopic,
    expandedList,
    setExpandedList,
  } = useContext(TopicDetailContext);
  const [expandAll, setExpandAll] = useState(false);
  const [showImagePreview, setShowImagePreview] = useState({
    show: false,
    imgAttr: null,
  });
  const { id: selectedTopicId } = match.params;
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const loadInitial = async () => {
    const topicByIdData = await getTopicById(selectedTopicId);
    if (topicByIdData) setTopicDetail(topicByIdData.lesson);
  };

  const handleChange = subTitle => (event, isExpanded) => {
    if (isExpanded) {
      setExpandedList([...expandedList, subTitle]);
    }
  };

  const toggleExpandAll = () => {
    const toggledExpandAll = !expandAll;
    setExpandAll(toggledExpandAll);
    if (toggledExpandAll) {
      if (topicDetail.subTopics && topicDetail.subTopics.length > 0) {
        setExpandedList([
          ...topicDetail.subTopics.map(subTopic => subTopic.subTitle),
        ]);
      }
    } else {
      setExpandedList([]);
    }
  };

  const onClickAccordion = subTitle => {
    if (expandedList.includes(subTitle)) {
      setExpandedList(expandedList.filter(elem => elem !== subTitle));
    } else {
      scroller.scrollTo(subTitle, {
        duration: 500,
        smooth: true,
        offset: mdUp ? -140 : -60,
      });
    }
  };

  // FlashcardList related Functions Start here
  const [showFlashcardListModal, setShowFlashcardListModal] = useState(false);
  const [
    openFlashcardListModalState,
    setOpenFlashcardListModalState,
  ] = useState({
    mode: FLASHCARD_MODE_TYPE["MAIN"],
  });
  const [flashcardsData, setFlashcardsData] = useState(null);
  const [searchedFlashcardsData, setSearchedFlashcardsData] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [selectedSystems, setSelectedSystems] = useState([]);
  const [anchorFilterMenu, setAnchorFilterMenu] = useState(null);
  const [applyCategoriesFilter, setApplyCategoriesFilter] = useState(false);
  const [filteredTotal, setFilteredTotal] = useState(0);

  const fetchFlashcards = async () => {
    const res = await getFlashcards();
    if (res && res.flashcards) {
      setFlashcardsData(res.flashcards);
    }
  };

  const fetchFlashcardsByCategoriesFilter = async () => {
    const filter = {
      category_ids: selectedSubjects,
      subCategory_ids: selectedSystems,
    };
    const { flashcards } = await getFlashcards(filter);

    if (flashcards) {
      setFlashcardsData(flashcards);
      setFilteredTotal(flashcards.length);
    }
  };

  const onRemoveFlashcard = async flashcardID => {
    const res = await removeFlashcard(flashcardID);
    if (res && res.removeFlashcard) {
      const newFlashcardsData = flashcardsData.filter(
        flashcard => flashcard._id !== flashcardID
      );

      setFlashcardsData(newFlashcardsData);
    }
    return res;
  };
  const onCreateFlashcard = async data => {
    const res = await createFlashcard(data);
    if (res && res.createFlashcard) {
      let tempFlashcardsData = flashcardsData;
      tempFlashcardsData.unshift(res.createFlashcard);
      setFlashcardsData(tempFlashcardsData);
    }
  };
  const onUpdateFlashcard = async data => {
    const res = await updateFlashcard(data);
    if (res && res.updateFlashcard) {
      // Update Whole List
      let index = flashcardsData.findIndex(
        flashcard => flashcard._id === res.updateFlashcard._id
      );
      let tempFlashcardsData = flashcardsData;
      tempFlashcardsData.splice(index, 1, res.updateFlashcard);
      setFlashcardsData(tempFlashcardsData);

      // Update Search List
      if (
        openFlashcardListModalState.listType === FLASHCARD_LIST_TYPE["SEARCH"]
      ) {
        let searchListIndex = searchedFlashcardsData.findIndex(
          flashcard => flashcard._id === res.updateFlashcard._id
        );
        let tempFlashcardsData = searchedFlashcardsData;
        tempFlashcardsData.splice(searchListIndex, 1, res.updateFlashcard);
        setSearchedFlashcardsData(tempFlashcardsData);
      }
    }
  };
  // Handle Filter Menu Start
  const handleOpenFilterMenu = event => {
    setAnchorFilterMenu(event.currentTarget);
  };

  const handleCloseFilterMenu = () => {
    setAnchorFilterMenu(null);
  };
  // Handle Filter Menu Start
  // FlashcardList related Functions End here

  const handleBackTopicList = () => {
    history.push(TOPICS_URL);
  };

  useEffect(() => {
    loadInitial();
    fetchFlashcards();
    return () => {
      setActiveSubtopic(null);
      setExpandedList([]);
      setTopicDetail(null);
    };
  }, [selectedTopicId]);

  useEffect(() => {
    if (
      topicDetail &&
      topicDetail.subTopics &&
      topicDetail.subTopics.length > 0
    ) {
      if (expandedList.length === topicDetail.subTopics.length) {
        setExpandAll(true);
      } else {
        setExpandAll(false);
      }
    }
  }, [topicDetail, expandedList]);

  useProtectContent();

  return (
    <Fragment>
      {/* Image Preview Modal */}
      <ImagePreviewModal
        visible={showImagePreview.show}
        imgAttr={showImagePreview.imgAttr}
        isDarkMode={false}
        customPos={true}
        onClose={() => setShowImagePreview({ show: false, imgAttr: null })}
        openFlashcardListModalState={openFlashcardListModalState}
        setOpenFlashcardListModalState={setOpenFlashcardListModalState}
        setShowFlashcardListModal={setShowFlashcardListModal}
      />
      {/* FlashcardList Modal */}
      {flashcardsData && (
        <FlashcardListModal
          visible={showFlashcardListModal}
          isDarkMode={false}
          customPos={{ x: 50, y: 0 }}
          flashcardsData={flashcardsData}
          openModalState={openFlashcardListModalState}
          setOpenModalState={setOpenFlashcardListModalState}
          searchedFlashcardsData={searchedFlashcardsData}
          setSearchedFlashcardsData={setSearchedFlashcardsData}
          onClose={() => {
            setShowFlashcardListModal(false);
            setOpenFlashcardListModalState({
              mode: FLASHCARD_MODE_TYPE["MAIN"],
            });
          }}
          onRemoveFlashcard={onRemoveFlashcard}
          onCreateFlashcard={onCreateFlashcard}
          onUpdateFlashcard={onUpdateFlashcard}
          separated={false}
          handleOpenFilterMenu={handleOpenFilterMenu}
          handleCloseFilterMenu={handleCloseFilterMenu}
          anchorFilterMenu={anchorFilterMenu}
          selectedSubjects={selectedSubjects}
          selectedSystems={selectedSystems}
          setSelectedSubjects={setSelectedSubjects}
          setSelectedSystems={setSelectedSystems}
          setApplyCategoriesFilter={setApplyCategoriesFilter}
          applyCategoriesFilter={applyCategoriesFilter}
          fetchFlashcards={fetchFlashcards}
          fetchFlashcardsByCategoriesFilter={fetchFlashcardsByCategoriesFilter}
          filteredTotal={filteredTotal}
        />
      )}

      {topicDetail && topicDetail.subTopics && (
        <Paper
          elevation={0}
          className={clsx(classes.root, "htmlContentContainer")}
        >
          <Box className={classes.topContainer}>
            <Box className={classes.topActionContainer}>
              <Box
                className={classes.backButtonContainer}
                onClick={handleBackTopicList}
              >
                <ArrowBackOutlinedIcon className={classes.backIcon} />
                <Box>Back</Box>
              </Box>
              <Box
                className={classes.expandCollapseContainer}
                onClick={toggleExpandAll}
              >
                <ExpandCollapseIcon className={classes.expandCollapseIcon} />
                <Box component={"span"} ml={1}>
                  {expandAll ? "Collapse all sections" : "Expand all sections"}
                </Box>
              </Box>
            </Box>

            <Box className={classes.title}>{topicDetail.title}</Box>
          </Box>

          {topicDetail.subTopics.length > 0 &&
            topicDetail.subTopics.map((subtopic, index) => (
              <div id={subtopic.subTitle} key={index}>
                <Accordion
                  className={classes.accordion}
                  expanded={expandedList.includes(subtopic.subTitle)}
                  onChange={handleChange(subtopic.subTitle)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.subTitle}
                    onClick={() => onClickAccordion(subtopic.subTitle)}
                  >
                    {subtopic.subTitle}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box style={{ overflowX: "auto", width: "100%" }}>
                      {subtopic.subTitle === TOPIC_SUBTOPIC_TITLE.REFERENCES
                        ? parseTopicReferencesHTML(
                            subtopic.content,
                            setShowImagePreview
                          )
                        : parseLightBoxHTML(
                            subtopic.content,
                            setShowImagePreview
                          )}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
        </Paper>
      )}
    </Fragment>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  getTopicById: id => dispatch(TopicAction.getTopicById(id)),
  // FlashcardList related
  getFlashcards: filter => dispatch(FlashcardActions.getFlashcards(filter)),
  createFlashcard: data => dispatch(FlashcardActions.createFlashcard(data)),
  updateFlashcard: data => dispatch(FlashcardActions.updateFlashcard(data)),
  removeFlashcard: id => dispatch(FlashcardActions.removeFlashcard(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopicDetails);
