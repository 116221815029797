import { Box } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import React, { useMemo, useState } from "react";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import CardMembershipOutlinedIcon from "@material-ui/icons/CardMembershipOutlined";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import TodayOutlinedIcon from "@material-ui/icons/TodayOutlined";

import MUICustomHeader from "../../../../../Components/MUICustomHeader";
import {
  SUBSCRIPTION_STATUS,
  TRANSACTION_SOURCE,
  XS_MUIDATATABLE_OPTIONS,
} from "../../../../../constants";
import useStyles from "./styles";
import { capitalizeFirstLetter, parseDateHtml } from "../../../../../utils";
import { CustomRefundIcon } from "../../../../../Components/CustomActionIcons";
import { useDispatch } from "react-redux";
import { refundSubscriptionPlan } from "../../../../../actions/subscriptionAction";
import ConfirmationModal from "../../../../../Components/Modal/ConfirmationModal";
import useModal from "../../../../../hooks/useModal";

const PlanHistoriesTable = props => {
  const { user, isEditing = false } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedTransactionID, setSelectedTransactionID] = useState(null);
  const handleRefundSubscriptionPlan = () => {
    dispatch(refundSubscriptionPlan(selectedTransactionID));
    handleClose();
  };
  const { open, handleOpen, handleClose } = useModal();

  const data = useMemo(
    () =>
      user.subscription &&
      Array.isArray(user.subscription._planHistory) &&
      user.subscription._planHistory.length > 0
        ? user.subscription._planHistory
            .sort((a, b) => (a._createdOn < b._createdOn ? 1 : -1))
            .map((p, index) => [
              index + 1,
              p.term.title,
              p._createdOn,
              p._activatedOn,
              p.status,
              p.source,
              p.transaction_id,
            ])
        : [],
    [user]
  );
  const columnsConfig = [
    {
      name: "No",
      options: {
        customHeadRender: columnMeta => {
          return (
            <MUICustomHeader label={columnMeta.label}>
              <>
                {columnMeta.label}
                <FormatListNumberedIcon
                  className={classes.thIcon}
                  color="secondary"
                />
              </>
            </MUICustomHeader>
          );
        },
      },
    },
    {
      name: "Plan Detail",
      options: {
        customBodyRender: value => <Box width={100}>{value}</Box>,
        customHeadRender: columnMeta => {
          return (
            <MUICustomHeader label={columnMeta.label}>
              <>
                {columnMeta.label}
                <CardMembershipOutlinedIcon
                  className={classes.thIcon}
                  color="secondary"
                />
              </>
            </MUICustomHeader>
          );
        },
      },
    },
    {
      name: "Purchased On",
      options: {
        customBodyRender: value => {
          return parseDateHtml(value, 120);
        },
        customHeadRender: columnMeta => {
          return (
            <MUICustomHeader label={columnMeta.label}>
              <>
                {columnMeta.label}
                <CalendarTodayOutlinedIcon
                  className={classes.thIcon}
                  color="secondary"
                />
              </>
            </MUICustomHeader>
          );
        },
      },
    },
    {
      name: "Activated On",
      options: {
        customBodyRender: value => {
          return parseDateHtml(value, 120);
        },
        customHeadRender: columnMeta => {
          return (
            <MUICustomHeader label={columnMeta.label}>
              <>
                {columnMeta.label}
                <TodayOutlinedIcon
                  className={classes.thIcon}
                  color="secondary"
                />
              </>
            </MUICustomHeader>
          );
        },
      },
    },
    {
      name: "Status",
      options: {
        customBodyRender: value => {
          return capitalizeFirstLetter(value);
        },
        customHeadRender: columnMeta => {
          return (
            <MUICustomHeader label={columnMeta.label}>
              <>
                {columnMeta.label}
                <AssessmentOutlinedIcon
                  className={classes.thIcon}
                  color="secondary"
                />
              </>
            </MUICustomHeader>
          );
        },
      },
    },
    {
      name: "Gateway",
      options: {
        customHeadRender: columnMeta => {
          return (
            <MUICustomHeader label={columnMeta.label}>
              <>
                {columnMeta.label}
                <ReceiptOutlinedIcon
                  className={classes.thIcon}
                  color="secondary"
                />
              </>
            </MUICustomHeader>
          );
        },
      },
    },
  ];

  const columns = isEditing
    ? [
        ...columnsConfig,
        {
          name: "Refund",
          options: {
            customBodyRenderLite: dataIndex => {
              const current = user.subscription._planHistory[dataIndex];
              const isValid =
                current.status !== SUBSCRIPTION_STATUS.EXPIRED &&
                current.status !== SUBSCRIPTION_STATUS.REFUNDED &&
                current.source !== TRANSACTION_SOURCE.FREE &&
                current.source !== TRANSACTION_SOURCE.MANUAL;

              return (
                <CustomRefundIcon
                  disabled={!isValid}
                  onClickHandler={() => {
                    if (isValid) {
                      handleOpen();
                      setSelectedTransactionID(current.transaction_id);
                    }
                  }}
                />
              );
            },
          },
        },
      ]
    : columnsConfig;

  return (
    <>
      <ConfirmationModal
        open={open}
        onClose={handleClose}
        actionFn={handleRefundSubscriptionPlan}
        ctaTitle={"Are you wish to refund this subscription plan"}
        ctaTxt={"Refund"}
      />
      <Box width="100%" mb={2}>
        <MUIDataTable
          data={data}
          columns={columns}
          options={{ ...XS_MUIDATATABLE_OPTIONS }}
        />
      </Box>
    </>
  );
};

export default PlanHistoriesTable;
