import React, { useState, useEffect, Fragment, useRef } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CropDinOutlinedIcon from "@material-ui/icons/CropDinOutlined";
import { Rnd } from "react-rnd";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import isTouchDevice from "is-touch-device";

import {
  uworldCloseIcon,
  restoreWindowIcon,
} from "../../../../../resources/assets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearchPlus,
  faSearchMinus,
  faSync,
  faBolt,
} from "@fortawesome/free-solid-svg-icons";
import styles from "../styles";
import { HTML_TAG_TYPE, FLASHCARD_MODE_TYPE } from "../../../../../constants";
import { convertPXToNum } from "../../../../../utils";

const useStyles = makeStyles(theme => styles(theme));

// Constants
const TOOLS_CONT_HEIGHT = 45;
const MIN_SIZE = { width: 280, height: 300 };

const y = 100;
const DEFAULT_CUSTOM_POSITION = { x: 20, y: 0 };
const DEFAULT_POSITION = {
  x: 50,
  y,
};
const DEFAULT_SM_POSITION = {
  x: 20,
  y,
};
const DEFAULT_XS_POSITION = {
  x: 0,
  y,
};
const LIGHT_ICON_COLOR = "#363636";
const DARK_ICON_COLOR = "#fff";
const WIDTH_SCALE = 1.1;

const loadImage = path => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous"; // to avoid CORS if used with Canvas
    img.src = path;
    img.onload = () => {
      resolve(img);
    };
    img.onerror = e => {
      // reject(e);
    };
  });
};

const ImagePreviewModal = props => {
  const {
    visible,
    imgAttr,
    isDarkMode,
    customPos = false,
    setOpenFlashcardListModalState,
    setShowFlashcardListModal,
    onClose,
    showFlashcardFunc = true,
  } = props;
  const { url = "" } = imgAttr || {};
  const classes = useStyles();
  const refTransformWrapper = useRef();
  const [imageDimensions, setImageDimensions] = useState({});

  const DEFAULT_SIZE = ({ width = 0, height = 0 }) => {
    const formattedWidth = width * WIDTH_SCALE;
    const formattedHeight = height * 1.2;
    const maxWidth = window.innerWidth * 0.8;
    const maxHeight = window.innerHeight * 0.8;

    return {
      width: `${
        formattedWidth <= MIN_SIZE.width
          ? MIN_SIZE.width
          : formattedWidth <= maxWidth
          ? formattedWidth
          : maxWidth
      }px`,
      height: `${
        formattedHeight <= MIN_SIZE.height
          ? MIN_SIZE.height
          : formattedHeight <= maxHeight
          ? formattedHeight
          : maxHeight
      }px`,
    };
  };

  const PRESET_POSITION = customPos
    ? DEFAULT_CUSTOM_POSITION
    : window.innerWidth < 290
    ? DEFAULT_XS_POSITION
    : window.innerWidth < 360
    ? DEFAULT_SM_POSITION
    : DEFAULT_POSITION;

  const [size, setSize] = useState(DEFAULT_SIZE(imageDimensions));
  const [position, setPosition] = useState(PRESET_POSITION);
  const [disableDragging, setDisableDragging] = useState(false);

  const onRestoreWindowSize = () => {
    setSize(DEFAULT_SIZE(imageDimensions));
  };

  const shouldChangeToIcon = convertPXToNum(size.width) < 540;

  const getImageHeightByContainerHeight = height => {
    const containerHeight = convertPXToNum(height);
    return containerHeight / 1.07 - TOOLS_CONT_HEIGHT;
  };

  const getImageWidthByContainerWidth = width => {
    return convertPXToNum(width) / WIDTH_SCALE;
  };

  // Update position when scrolling
  const handleOnScroll = e => {
    const window = e.currentTarget;

    if (position) {
      setPosition(prevState => ({
        x: prevState.x,
        y: window.scrollY,
      }));
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleOnScroll);

    return () => {
      window.removeEventListener("scroll", handleOnScroll);
    };
  }, [handleOnScroll]);

  useEffect(() => {
    if (refTransformWrapper && refTransformWrapper.current) {
      refTransformWrapper.current.centerView();
    }
  }, [size]);

  // Reset position and size when image preview modal mounted/unmounted
  useEffect(() => {
    setSize(DEFAULT_SIZE(imageDimensions));
    // For Default mode
    if (!customPos) {
      setPosition(PRESET_POSITION);
    }
  }, [visible, imgAttr]);

  const fetchImageDimensions = async url => {
    try {
      const { naturalHeight, naturalWidth } = await loadImage(url);
      const imgSize = {
        width: naturalWidth,
        height: naturalHeight,
      };
      setImageDimensions(imgSize);
      setSize(DEFAULT_SIZE(imgSize));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchImageDimensions(url);
  }, [url]);

  if (!visible) return null;

  return (
    <Fragment>
      {/* Image Preview Modal */}
      <Rnd
        className={clsx(classes.zIndex1001, "animated fadeIn")}
        bounds={"window"}
        minHeight={MIN_SIZE.height}
        minWidth={MIN_SIZE.width}
        size={{ width: size.width, height: size.height }}
        position={{ x: position.x, y: position.y }}
        onDragStop={(e, d) => {
          setPosition({ x: d.x, y: d.y });
        }}
        onResize={(e, direction, ref, delta, position) => {
          setSize({ width: ref.style.width, height: ref.style.height });
          setPosition({
            ...position,
          });
        }}
        enableUserSelectHack={false}
        disableDragging={disableDragging}
      >
        <div
          className={clsx(
            isDarkMode ? classes.modalContDark : classes.modalContLight,
            classes.modalCont
          )}
        >
          {/* Header */}
          <div className={classes.header} style={{ margin: 0 }}>
            <div></div>
            <span className={classes.headerTitle} style={{ fontWeight: 1000 }}>
              Exhibit Display
            </span>
            <div className={classes.dFlex}>
              <div>
                <img
                  src={restoreWindowIcon}
                  alt="Restore window icon"
                  className={classes.restoreIcon}
                  onClick={onRestoreWindowSize}
                />
              </div>

              <div
                style={{ backgroundImage: `url(${uworldCloseIcon})` }}
                className={classes.closeIcon}
                onClick={onClose}
              />
            </div>
          </div>

          {/* Image Preview */}
          <div
            className={classes.imgPreviewCont}
            onMouseEnter={() => {
              if (!isTouchDevice()) setDisableDragging(true);
            }}
            onMouseLeave={() => {
              if (!isTouchDevice()) setDisableDragging(false);
            }}
          >
            <TransformWrapper centerOnInit={true} ref={refTransformWrapper}>
              {({
                zoomIn,
                zoomOut,
                resetTransform,
                centerView,
                setTransform,
                ...rest
              }) => (
                <React.Fragment>
                  <div
                    className={
                      isDarkMode ? classes.imgContDark : classes.imgContLight
                    }
                  >
                    <TransformComponent>
                      <img
                        src={imgAttr.url}
                        alt="Preview"
                        style={{ margin: "0 auto", objectFit: "contain" }}
                        width={getImageWidthByContainerWidth(size.width)}
                        height={getImageHeightByContainerHeight(size.height)}
                      />
                    </TransformComponent>
                  </div>

                  {/* Tools */}
                  <div className={classes.toolsCont}>
                    <div className={classes.toolBtn} onClick={() => zoomIn()}>
                      <FontAwesomeIcon
                        icon={faSearchPlus}
                        className={classes.toolIcon}
                        color={isDarkMode ? DARK_ICON_COLOR : LIGHT_ICON_COLOR}
                      />
                      {!shouldChangeToIcon && (
                        <span
                          className={clsx(
                            isDarkMode
                              ? classes.toolTextDark
                              : classes.toolTextLight
                          )}
                        >
                          Zoom In
                        </span>
                      )}
                    </div>
                    <div className={classes.toolBtn} onClick={() => zoomOut()}>
                      <FontAwesomeIcon
                        icon={faSearchMinus}
                        className={classes.toolIcon}
                        color={isDarkMode ? DARK_ICON_COLOR : LIGHT_ICON_COLOR}
                      />
                      {!shouldChangeToIcon && (
                        <span
                          className={clsx(
                            isDarkMode
                              ? classes.toolTextDark
                              : classes.toolTextLight
                          )}
                        >
                          Zoom Out
                        </span>
                      )}
                    </div>
                    <div
                      className={classes.toolBtn}
                      onClick={() => {
                        resetTransform(0);
                        centerView();
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faSync}
                        className={classes.toolIcon}
                        color={isDarkMode ? DARK_ICON_COLOR : LIGHT_ICON_COLOR}
                      />
                      {!shouldChangeToIcon && (
                        <span
                          className={clsx(
                            isDarkMode
                              ? classes.toolTextDark
                              : classes.toolTextLight
                          )}
                        >
                          Reset
                        </span>
                      )}
                    </div>
                    {showFlashcardFunc ? (
                      <>
                        <div
                          className={classes.toolBtn}
                          onClick={() => {
                            setShowFlashcardListModal(true);
                            setOpenFlashcardListModalState({
                              mode: FLASHCARD_MODE_TYPE["CREATE_WITH_ELEMENT"],
                              payload: {
                                type: HTML_TAG_TYPE["IMG"],
                                html: imgAttr,
                              },
                            });
                            onClose();
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faBolt}
                            className={classes.toolIcon}
                            color={
                              isDarkMode ? DARK_ICON_COLOR : LIGHT_ICON_COLOR
                            }
                          />
                          {!shouldChangeToIcon && (
                            <span
                              className={clsx(
                                isDarkMode
                                  ? classes.toolTextDark
                                  : classes.toolTextLight
                              )}
                            >
                              New
                            </span>
                          )}
                        </div>
                        <div
                          className={classes.toolBtn}
                          style={{ marginRight: 0 }}
                          onClick={() => {
                            setShowFlashcardListModal(true);
                            setOpenFlashcardListModalState({
                              mode: FLASHCARD_MODE_TYPE["INSERT_WITH_ELEMENT"],
                              payload: {
                                type: HTML_TAG_TYPE["IMG"],
                                html: imgAttr,
                              },
                            });
                            onClose();
                          }}
                        >
                          <span className={classes.stackCont}>
                            <FontAwesomeIcon
                              icon={faBolt}
                              className={classes.flashIcon}
                              color={
                                isDarkMode ? DARK_ICON_COLOR : LIGHT_ICON_COLOR
                              }
                            />
                            <CropDinOutlinedIcon
                              style={{
                                color: isDarkMode
                                  ? DARK_ICON_COLOR
                                  : LIGHT_ICON_COLOR,
                              }}
                              className={classes.squareIcon}
                            />
                          </span>
                          {!shouldChangeToIcon && (
                            <span
                              className={clsx(
                                isDarkMode
                                  ? classes.toolTextDark
                                  : classes.toolTextLight
                              )}
                            >
                              Existing
                            </span>
                          )}
                        </div>
                      </>
                    ) : (
                      <a href={url} target="_blank" rel="noreferrer">
                        Link
                      </a>
                    )}
                  </div>
                </React.Fragment>
              )}
            </TransformWrapper>
          </div>
        </div>
      </Rnd>
    </Fragment>
  );
};

export default ImagePreviewModal;
