const tourReducer = initialState => {
  return (state = initialState, action) => {
    switch (action.type) {
      // start the tour
      case "START":
        return { ...state, run: true };
      // Reset to 0th step
      case "RESET":
        return { ...state, stepIndex: 0 };
      // Stop the tour
      case "STOP":
        return { ...state, run: false };
      // Update the steps for next / back button click
      case "NEXT_OR_PREV":
        return { ...state, ...action.payload };
      // Restart the tour - reset go to 1st step, restart create new tour
      case "RESTART":
        return {
          ...state,
          stepIndex: 0,
          run: true,
          loading: false,
          key: new Date(),
        };
      default:
        return state;
    }
  };
};

export default tourReducer;
