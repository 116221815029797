import {
  CURRENCY_FORMAT,
  DISCOUNT_AUDIENCE_TYPE,
  DISCOUNT_INCLUDED_PLAN_TYPE,
  DISCOUNT_MODE,
  DISCOUNT_UNIT,
} from "../../../constants";

export const DISCOUNT_UNIT_LIST = [
  {
    label: CURRENCY_FORMAT,
    value: DISCOUNT_UNIT["AMOUNT"],
  },
  {
    label: "%",
    value: DISCOUNT_UNIT["PERCENTAGE"],
  },
];

export const DISCOUNT_MODE_LIST = [
  {
    label: "First Payment only",
    value: DISCOUNT_MODE["FIRST_PAYMENT"],
  },
  {
    label: "Recurring Payment",
    value: DISCOUNT_MODE["RECURRING_PAYMENT"],
  },
];

export const DISCOUNT_AUDIENCE_TYPE_LIST = [
  {
    label: "All",
    value: DISCOUNT_AUDIENCE_TYPE["ALL"],
  },
  {
    label: "Custom",
    value: DISCOUNT_AUDIENCE_TYPE["CUSTOM"],
  },
];

export const DISCOUNT_INCLUDED_PLAN_TYPE_LIST = [
  {
    label: "All",
    value: DISCOUNT_INCLUDED_PLAN_TYPE["ALL"],
  },
  {
    label: "Custom",
    value: DISCOUNT_INCLUDED_PLAN_TYPE["CUSTOM"],
  },
];
