import { Box } from "@material-ui/core";
import React from "react";

const SectionTitle = ({ text }) => {
  return (
    <Box>
      <h4>{text}</h4>
    </Box>
  );
};

export default SectionTitle;
