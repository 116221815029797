import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form/dist/index.ie11";

import PrimaryTextField from "../../PrimaryTextField";
import { Box, Grid } from "@material-ui/core";
import { PrimaryButton } from "../../CustomButton";
import VisitorFormTitle from "../../../views/auth/components/VisitorFormTitle";
import { EMAIL_REGEX, REQUEST_STATUS } from "../../../constants";
import {
  forgotPassword,
  resetForgotPasswordStatus,
} from "../../../actions/userAction";
import { selectUserForgotPasswordStatus } from "../../../selectors/user";

const ForgotPasswordForm = ({ setShowForgotPasswordForm }) => {
  const dispatch = useDispatch();
  const status = useSelector(selectUserForgotPasswordStatus);
  const { handleSubmit, control, errors, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = data => {
    const input = {
      ...data,
    };
    dispatch(forgotPassword({ input }));
  };

  const handleResetFields = useCallback(() => {
    reset({
      email: "",
    });
  }, [reset]);

  useEffect(() => {
    if (status === REQUEST_STATUS.SUCCESS) {
      handleResetFields();
      dispatch(resetForgotPasswordStatus());
    }
  }, [status, handleResetFields]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <VisitorFormTitle title="Forgot Password" />
      </Grid>
      <Grid item xs={12}>
        Verify your identity by entering the email address associated with your
        account.
      </Grid>

      {/* <Grid item xs={12}>
        <Controller
          control={control}
          name="firstName"
          rules={{
            required: "First Name cannot be empty",
          }}
          render={({ onChange, onBlur, value, name, ref }) => (
            <PrimaryTextField
              label="First Name"
              name={name}
              onChange={e => {
                onChange(e.target.value);
              }}
              value={value}
              inputRef={ref}
              error={Boolean(errors.firstName)}
              helperText={errors.firstName && errors.firstName.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="lastName"
          rules={{
            required: "Last Name cannot be empty",
          }}
          render={({ onChange, onBlur, value, name, ref }) => (
            <PrimaryTextField
              label="Last Name"
              name={name}
              onChange={e => {
                onChange(e.target.value);
              }}
              value={value}
              inputRef={ref}
              error={Boolean(errors.lastName)}
              helperText={errors.lastName && errors.lastName.message}
            />
          )}
        />
      </Grid> */}
      <Grid item xs={12}>
        <Controller
          control={control}
          name="email"
          rules={{
            required: "Email cannot be empty",
            pattern: {
              value: EMAIL_REGEX,
              message: "Invalid Email Format",
            },
          }}
          render={({ onChange, onBlur, value, name, ref }) => (
            <PrimaryTextField
              label="Email"
              name={name}
              onChange={e => {
                onChange(e.target.value);
              }}
              value={value}
              inputRef={ref}
              error={Boolean(errors.email)}
              helperText={errors.email && errors.email.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" mt={3} width="100%">
          <PrimaryButton
            color="primary"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            fullWidth
          >
            Submit
          </PrimaryButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ForgotPasswordForm;
