import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import { WELCOME_URL } from "../routes/constants";
import { IS_AUTEHNTICATED } from "../localStorage/constants";

const AuthFeatureRoute = ({
  component: Component,
  UserStore,
  SubscriptionStore,
  render,
  ...rest
}) => {
  const { userInfo, authToken, type } = UserStore;
  const { subscription } = SubscriptionStore;
  const isAuthenticated = localStorage.getItem(IS_AUTEHNTICATED);

  if (!authToken && !isAuthenticated)
    return <Redirect to={{ pathname: "/" }} />;

  if (!userInfo || !subscription) return <></>;

  return (
    <Route
      {...rest}
      render={
        render
          ? render
          : props =>
              type === "admin" ||
              (userInfo &&
                subscription &&
                !userInfo.isBlocked &&
                subscription.isValid) ? (
                <Component {...props} />
              ) : (
                <Redirect to={{ pathname: WELCOME_URL }} />
              )
      }
    />
  );
};

const mapStateToProps = ({ UserStore, SubscriptionStore }) => ({
  UserStore,
  SubscriptionStore,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AuthFeatureRoute);
