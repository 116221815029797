// Admin
export const selectUserList = ({ UserStore }) => UserStore.users;

// User
export const selectUserChangePasswordStatus = ({ UserStore }) =>
  UserStore.status.changePasswordStatus;

export const selectUserCheckEmailStatus = ({ UserStore }) =>
  UserStore.status.checkEmailStatus;

export const selectUserForgotPasswordStatus = ({ UserStore }) =>
  UserStore.status.forgotPasswordStatus;

export const selectUserInfo = ({ UserStore }) => UserStore.userInfo;

export const selectUserAuthToken = ({ UserStore }) => UserStore.authToken;

export const selectEmailAvailability = ({ UserStore }) =>
  UserStore.emailAvailability;

export const selectUserLoginErrorMsg = ({ UserStore }) =>
  UserStore.loginErrorMsg;
