import { Box } from "@material-ui/core";
import React from "react";
import { CustomDeleteIcon } from "../../../../Components/CustomActionIcons";

const TableTitle = ({ title, handleOpenDeleteModal }) => {
  return (
    <Box display="flex" justifyContent="space-between">
      <Box fontSize="1.5rem" mb={2}>
        {title}
      </Box>
      <CustomDeleteIcon
        onClickHandler={handleOpenDeleteModal}
        style={{ fontSize: 30 }}
      />
    </Box>
  );
};

export default TableTitle;
