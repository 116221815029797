export const contactInfos = [
  {
    imgPath: require("../../resources/assets/images/location.png"),
    imgAlt: "Location Icon",
    infos: [
      "MS Webb & Co. Ltd, Maddison House, 226 High Street, Croydon, Surrey, CR9 1DF",
    ],
  },

  {
    imgPath: require("../../resources/assets/images/call.png"),
    imgAlt: "Phone Icon",
    infos: ["Call Us Now For A Free Consultation", "0844 544 4804"],
  },
  {
    imgPath: require("../../resources/assets/images/mail.png"),
    imgAlt: "Mail Icon",
    infos: ["E : support@amckey.com", "E : support@amckey.com"],
  },
  {
    imgPath: require("../../resources/assets/images/24-hours.png"),
    imgAlt: "24 hours Icon",
    infos: ["Working Hours 9am to 6pm", "Available on call 24/7"],
  },
];

export const menuItems = [
  {
    title: "About",
  },
  {
    title: "Bailiff & Security",
  },
  {
    title: "Services",
  },
  {
    title: "Reviews",
  },
  {
    title: "Blog",
  },
  {
    title: "Contact Us",
  },
  {
    title: "Privacy Policy",
  },
];
