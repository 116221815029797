import React, { useState, Fragment } from "react";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import clsx from "clsx";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";

import {
  CorrectAnsIcon,
  CustomRadio,
  CustomFormControlLabel,
} from "../CustomRadioControlElements";
import useStyles from "./styles";
import { ANSWER_TABS } from "./helper";
import { COURSE_APP_SUB_ROUTE } from "../../routes/constants";

const QuestionExplanation = props => {
  const {
    question,
    setShowImagePreview,
    setSelectedQuestionID,
    disabledImagePreview = false,
  } = props;
  const classes = useStyles();
  const [revealTab, setRevealTab] = useState(ANSWER_TABS["EXPLANATION"]);

  const parseHTMLContent = html =>
    ReactHtmlParser(html, {
      transform: node => {
        if (node.name === "img") {
          const { src, width, height } = node.attribs;

          return disabledImagePreview ? (
            <img
              src={src}
              key={src}
              width={width}
              height={height}
              className={"img-responsive"}
            />
          ) : (
            <img
              src={src}
              key={src}
              width={width}
              height={height}
              className={"clickable img-responsive"}
              onClick={() => {
                setSelectedQuestionID(question._id);
                setShowImagePreview({
                  show: true,
                  imgAttr: { url: src, width, height },
                });
              }}
            />
          );
        }
      },
    });

  const renderRevealContent = () => {
    switch (revealTab) {
      case ANSWER_TABS["EXPLANATION"]:
        return question && question.explanation
          ? parseHTMLContent(question.explanation)
          : "No explanation available";
      case ANSWER_TABS["REFERENCE"]:
        return question &&
          question.resources &&
          question.resources.length > 0 ? (
          <ul>
            {question.resources.map((resource, index) => (
              <li key={index}>
                <a href={resource.url} target="_blank" rel="noreferrer">
                  {resource.name}
                </a>
              </li>
            ))}
          </ul>
        ) : (
          "No references available"
        );
      case ANSWER_TABS["FURTHER_READING"]: {
        const readingList = question.lessons;

        return readingList && readingList.length > 0 ? (
          <ul>
            {readingList.map(topic => (
              <li key={topic._id}>
                <Link
                  to={`${COURSE_APP_SUB_ROUTE}/topics/${topic._id}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {topic.title}
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          "No further reading available"
        );
      }
    }
  };

  return (
    <Fragment>
      <div className={clsx(classes.root, "htmlContentContainer")}>
        <h4 className={classes.questionHeader}>Question:</h4>
        <div className={classes.question}>
          {parseHTMLContent(question.question)}
        </div>
        <div className={classes.answerContainer}>
          <FormControl component="fieldset">
            <RadioGroup>
              {question.answers.map((answer, index) => (
                <div className={classes.answerContent} key={index}>
                  {answer.validAnswer ? (
                    <CorrectAnsIcon className={classes.answerLeftIcon} />
                  ) : (
                    <CorrectAnsIcon
                      style={{ color: "transparent" }}
                      className={classes.answerLeftIcon}
                    />
                  )}
                  <CustomFormControlLabel
                    control={<CustomRadio checked={false} />}
                    disabled
                    label={`${String.fromCharCode(65 + index)}.  ${
                      answer.content
                    }`}
                  />
                </div>
              ))}
            </RadioGroup>
          </FormControl>
        </div>
        <div className={classes.explanationContainer}>
          <div className={classes.tabCont}>
            {Object.values(ANSWER_TABS).map(tab => (
              <div
                className={clsx(
                  classes.tabItem,
                  revealTab === tab && classes.tabItemActive
                )}
                onClick={() => setRevealTab(tab)}
              >
                <span className={classes.tabHeader}>{tab}</span>
              </div>
            ))}
          </div>
        </div>
        <div>{renderRevealContent()}</div>
      </div>
    </Fragment>
  );
};

export default QuestionExplanation;
