import {
  DISCOUNT_AUDIENCE_TYPE,
  DISCOUNT_INCLUDED_PLAN_TYPE,
} from "../../../../constants";
import { convertDateGMT_Plus08ToPlus10 } from "../../../../utils";

export const voucherAdapter = (rawData, voucherId, isCodeChanged) => {
  const {
    code,
    unit,
    mode,
    discount,
    audienceType,
    audienceIds,
    includedPlanType,
    pricePlanIds,
    limit,
    expiredDate,
  } = rawData;
  const data = {
    _id: voucherId ? voucherId : undefined,
    input: {
      code:
        isCodeChanged !== undefined ? (isCodeChanged ? code : undefined) : code,
      unit,
      mode,
      discount: parseFloat(discount),
      limit: parseInt(limit),
      _expiredOn: convertDateGMT_Plus08ToPlus10(expiredDate),
      audienceType,
      audienceIds:
        audienceType === DISCOUNT_AUDIENCE_TYPE.CUSTOM && audienceIds.length > 0
          ? audienceIds.map(item =>
              typeof item === "string" ? item : item._id
            )
          : [],
      includedPlanType,
      pricePlanIds:
        includedPlanType === DISCOUNT_INCLUDED_PLAN_TYPE.CUSTOM &&
        pricePlanIds.length > 0
          ? pricePlanIds.map(item =>
              typeof item === "string" ? item : item._id
            )
          : [],
    },
  };
  return data;
};
