import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStylesPrimary = makeStyles(theme => ({
  root: {
    border: `1px solid ${theme.palette.primary.lighter}`,
    overflow: "hidden",
    borderRadius: 4,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // "&$focused": {
    //   boxShadow: `${theme.palette.primary.lighter} 0 0 0 2px`,
    //   borderColor: theme.palette.primary.main,
    // },
    "&:focus": {
      backgroundColor: "",
    },
    "&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active": {
      transition: "none",
      boxShadow: `0 0 0 30px ${theme.palette.primary.lighter} inset !important`,
    },
  },
  input: {
    "&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active": {
      boxShadow: `0 0 0 30px ${theme.palette.primary.lighter} inset !important`,
    },
  },
  formControl: {
    "& .MuiInputLabel-root": { color: "#00000080" },
    "& .MuiFilledInput-root": {
      backgroundColor: `${theme.palette.primary.lighter} !important`,
    },
  },
}));

const IconSelect = props => {
  return <ExpandMoreIcon {...props} style={{ color: "#00000080" }} />;
};

export default function PrimarySelect(props) {
  const { label, error, onChange, value, disabled, children } = props;
  const classes = useStylesPrimary();

  return (
    <FormControl
      className={classes.formControl}
      variant="filled"
      style={{ width: "100%" }}
      error={Boolean(error)}
    >
      <InputLabel htmlFor="filled-native-simple">{label}</InputLabel>
      <Select
        native
        IconComponent={props => <IconSelect {...props} />}
        disableUnderline={true}
        classes={{ ...classes, formControl: undefined, input: undefined }}
        inputProps={{
          name: "age",
          id: "filled-native-simple",
        }}
        onChange={onChange}
        value={value}
        disabled={disabled}
      >
        {children}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
}
