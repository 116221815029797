import React from "react";
import { TRANSACTION_SOURCE } from "../../constants";
export const parseReceiptTransactionSource = source => {
  switch (source) {
    case TRANSACTION_SOURCE.PAYPAL:
      return "Paid by PayPal";
    case TRANSACTION_SOURCE.STRIPE:
      return "Paid by Stripe";
    default:
      return "";
  }
};

export const parseAddressLine = value => {
  if (value) {
    return (
      <>
        {value}
        <br />
      </>
    );
  }
  return "";
};
