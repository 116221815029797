import React from "react";
import Box from "@material-ui/core/Box";

import useStyles from "./styles";
import { PLAN_BOX_TYPE } from "./constants";
import { COLOR_WHITE, COLOR_BLACK } from "../../../../../styles/constants";
import { blackStepArrowIcon, crossIcon } from "../../../../../resources/assets";
import { CURRENCY_FORMAT } from "../../../../../constants";
import clsx from "clsx";

const PlanBox = props => {
  const {
    type,
    pricePlan,
    handleAddCartItem,
    handleOpenRemoveModal,
    disabled = false,
    selected = false,
  } = props;
  const classes = useStyles({ disabled });

  return (
    <Box
      className={clsx(
        classes.planBoxContainer,
        disabled || type === PLAN_BOX_TYPE.CART_ITEM ? "" : "clickable"
      )}
      onClick={type === PLAN_BOX_TYPE.OPTION ? handleAddCartItem : undefined}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        flexDirection="column"
        pb={3}
        color={COLOR_WHITE}
        className={clsx(selected && classes.selectedPlanBox)}
      >
        <Box
          className={classes.pricePlanTitle}
          fontSize="2.4rem"
          fontWeight="bold"
        >
          {pricePlan.title}
        </Box>
      </Box>

      <Box
        className={clsx(classes.priceBox, selected && classes.selectedPriceBox)}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          fontSize="2.1rem"
          fontWeight="bold"
          color={COLOR_BLACK}
          p={3}
        >
          <Box mr={2}>
            {CURRENCY_FORMAT} {pricePlan.price}
          </Box>
          <Box>
            {type === PLAN_BOX_TYPE.OPTION ? (
              <img
                className={classes.arrowIcon}
                src={blackStepArrowIcon}
                alt={"Arrow icon"}
              />
            ) : type === PLAN_BOX_TYPE.CART_ITEM ? (
              <img
                className={clsx(classes.crossIcon, disabled ? "" : "clickable")}
                src={crossIcon}
                alt={"Cross icon"}
                onClick={
                  type === PLAN_BOX_TYPE.CART_ITEM
                    ? handleOpenRemoveModal
                    : undefined
                }
              />
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PlanBox;
