import { Box, Grid } from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTransactionList } from "../../../actions/transactionAction";
import { selectTransactionList } from "../../../selectors/transaction";

import FormTitle from "../FormTitle";
import BillingForm from "./components/BillingForm";
import PaymentHistoryBox from "./components/PaymentHistoryBox";
import useStyles from "./styles";

const BillingPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const transactions = useSelector(selectTransactionList);
  useEffect(() => {
    const own = true;
    dispatch(getTransactionList(own));
  }, [dispatch]);

  return (
    <Box px={1}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Box
            className={clsx(classes.formContainer, classes.leftBox)}
            display="flex"
            alignItems="center"
            flexDirection="column"
            height="100%"
          >
            <FormTitle title={"Billing Information"} />
            <Box mt={4}>
              <BillingForm />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            className={classes.formContainer}
            display="flex"
            flexDirection="column"
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <FormTitle title={"Payment History"} />
            </Box>

            <Box mt={4}>
              {Array.isArray(transactions) &&
                transactions.length > 0 &&
                transactions.map(transaction => (
                  <PaymentHistoryBox
                    transaction={transaction}
                    key={transaction._id}
                  />
                ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BillingPage;
