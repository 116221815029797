import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      margin: "0",
    },
    margin: "0 auto",
    maxWidth: "1024px",
    paddingTop: "30px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 3px 8px rgb(61 74 84 / 10%), 0 3px 12px rgb(61 74 84 / 6%)",
  },
  topContainer: {
    padding: "0 16px 24px",
  },
  topActionContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  backButtonContainer: {
    color: "#687882",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      color: "#444e55",
    },
  },
  backIcon: {
    fontSize: "1.05rem",
    marginBottom: "2px",
  },
  titleContainer: {},
  title: {
    fontSize: "1.728em",
    fontWeight: 900,
  },
  expandCollapseContainer: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    color: "#687882",
    "& svg": {
      fill: "#687882",
    },
    "&:hover": {
      color: "#444e55",
      "& svg": {
        fill: "#444e55",
      },
    },
  },
  expandCollapseIcon: {
    width: 16,
    height: 16,
  },
  subTitle: {
    fontWeight: 600,
  },
}));
