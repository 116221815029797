// Tour steps
const TOUR_STEPS = [
  {
    target: ".tour-step1",
    content:
      "Decide whether to test in tutor mode (view explanations as you answer) and timed mode (untimed/timed)",
    placement: "right-start",
    disableBeacon: true,
  },
  {
    target: ".tour-step2",
    content: "Select a specific group of questions to work on",
    placement: "right-start",
    disableBeacon: true,
  },
  {
    target: ".tour-step3",
    content: "Choose questions that cover specific subjects",
    placement: "right-start",
    disableBeacon: true,
  },
  {
    target: ".tour-step4",
    content:
      "Choose questions from systems which you want to include in the test",
    placement: "right-end",
    disableBeacon: true,
  },
  {
    target: ".tour-step5",
    content: "Specify the number of questions you want to include in the test",
    placement: "right-end",
    disableBeacon: true,
  },
  {
    target: ".tour-step6",
    content: "Click to start your personalized test",
    placement: "right-end",
    disableBeacon: true,
  },
];

const INITIAL_STATE = {
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0, // Make the component controlled
  steps: TOUR_STEPS,
  key: new Date(), // This field makes the tour to re-render when the tour is restarted
};

export { INITIAL_STATE };
