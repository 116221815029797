import React, { useState, useMemo, useEffect } from "react";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import {
  CustomConfirmDialogActions,
  CustomActionBtn,
  CustomCancelBtn,
} from "../../../Components/CustomDialogElements";
import useStyles from "./styles";
import { steps } from "./constants";
import { backArrowIcon } from "../../../resources/assets";
import StepConnector from "./components/StepConnector";
import PricingSection from "./PricingSection";
import CartSection from "./CartSection";
import PaymentSection from "./PaymentSection";
import { clearCart, removeFromCart } from "../../../actions/cartAction";
import usePath from "../../../hooks/usePath";
import { CART_URL, CHECKOUT_URL, PRICING_URL } from "../../../routes/constants";
import { selectCartItems } from "../../../selectors/cart";
import { selectUserAuthToken } from "../../../selectors/user";
import { selectSubscription } from "../../../selectors/subscription";
import { SUBSCRIPTION_STATUS } from "../../../constants";
import { checkIsFreeTrialPlan } from "./helper";

const CheckoutPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cartItems = useSelector(selectCartItems);
  const authToken = useSelector(selectUserAuthToken);
  const subscription = useSelector(selectSubscription);
  const enableFreeTrial =
    !authToken ||
    (subscription &&
      subscription.status !== SUBSCRIPTION_STATUS.TRIAL &&
      !subscription.paidBefore);
  const isTrialPlanInCart = checkIsFreeTrialPlan(cartItems);

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const { fullPath } = usePath();
  const history = useHistory();
  const [completedSteps, setCompletedSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(2);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const totalSteps = steps.length;
  const lastStep = totalSteps - 1;

  // Handle Confirmtion Modal Start
  const handleOpenRemoveModal = () => {
    setShowRemoveModal(true);
  };

  const handleCloseRemoveModal = () => {
    setShowRemoveModal(false);
  };

  const handleRemoveCartItem = () => {
    dispatch(removeFromCart());
    handleCloseRemoveModal();
  };

  const handleNavigate = selectedStep => {
    let url = "";
    switch (selectedStep) {
      case 0:
        url = PRICING_URL;
        break;
      case 1:
        url = CART_URL;
        break;
      case 2:
        url = CHECKOUT_URL;
        break;
    }
    setActiveStep(selectedStep);
    history.push(url);
  };

  const handleGoToSelectedStep = selectedStep => {
    if (
      (selectedStep === lastStep && Boolean(cartItems.length)) ||
      Boolean(cartItems.length)
    ) {
      if (selectedStep > activeStep) {
        setCompletedSteps(prev => [...prev, activeStep]);
      } else if (selectedStep < activeStep) {
        setCompletedSteps(prev =>
          [...prev].filter(step => step !== selectedStep)
        );
      }
      handleNavigate(selectedStep);
    }
  };

  const handleNext = () => {
    const newStep = activeStep + 1;
    if (newStep < totalSteps) {
      handleNavigate(newStep);
      setCompletedSteps(prev => [...prev, activeStep]);
    }
  };

  const handleBack = () => {
    const newStep = activeStep - 1;
    if (newStep >= 0) {
      handleNavigate(newStep);
      setCompletedSteps(prev => [...prev].filter(step => step !== newStep));
    }
  };

  const shouldShowBackButton = useMemo(() => activeStep > 0, [activeStep]);

  const renderCurrentStepForm = useMemo(() => {
    switch (activeStep) {
      case 0:
        return (
          <PricingSection
            handleNext={handleNext}
            enableFreeTrial={enableFreeTrial}
          />
        );
      case 1:
        return (
          <CartSection
            handleNext={handleNext}
            handleBack={handleBack}
            handleOpenRemoveModal={handleOpenRemoveModal}
          />
        );
      case 2:
        return (
          <PaymentSection
            handleOpenRemoveModal={handleOpenRemoveModal}
            handleBack={handleBack}
          />
        );
      default:
        return null;
    }
  }, [activeStep]);

  useEffect(() => {
    switch (fullPath) {
      case PRICING_URL:
      case `${PRICING_URL}/`:
        return setActiveStep(0);
      case CART_URL:
      case `${CART_URL}/`:
        return setActiveStep(1);
      case CHECKOUT_URL:
      case `${CHECKOUT_URL}/`:
        return setActiveStep(2);
    }
  }, [fullPath]);

  useEffect(() => {
    if (isTrialPlanInCart && !enableFreeTrial) {
      dispatch(clearCart());
    }
  }, [enableFreeTrial, isTrialPlanInCart]);

  useEffect(() => {
    if (Boolean(cartItems.length)) {
      let completedStepsArr = [];
      if (fullPath === CART_URL || fullPath === `${CART_URL}/`) {
        completedStepsArr = [0];
      } else if (fullPath === CHECKOUT_URL || fullPath === `${CHECKOUT_URL}/`) {
        completedStepsArr = [0, 1];
      }
      setCompletedSteps(completedStepsArr);
    }
  }, [cartItems, fullPath]);

  return (
    <>
      <Dialog onClose={handleCloseRemoveModal} open={showRemoveModal}>
        <DialogContent>
          Are you sure you wish to remove it from cart?
        </DialogContent>
        <CustomConfirmDialogActions>
          <CustomActionBtn onClick={handleRemoveCartItem}>
            Remove
          </CustomActionBtn>
          <CustomCancelBtn onClick={handleCloseRemoveModal}>
            Cancel
          </CustomCancelBtn>
        </CustomConfirmDialogActions>
      </Dialog>
      <Box className={classes.root}>
        {!smUp && shouldShowBackButton && (
          <Box mb={3} className={classes.backButton} onClick={handleBack}>
            <img src={backArrowIcon} alt="Back arrow icon" />
          </Box>
        )}
        <Box
          display="flex"
          justifyContent={
            smUp && shouldShowBackButton ? "space-between" : "center"
          }
          alignItems="center"
          width="100%"
        >
          {smUp && shouldShowBackButton && (
            <Box className={classes.backButton} onClick={handleBack}>
              <img src={backArrowIcon} alt="Back arrow icon" />
            </Box>
          )}
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            nonLinear
            classes={{ root: classes.stepperRoot }}
            connector={null}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                {index !== 0 && (
                  <StepConnector
                    key={label}
                    index={index}
                    active={activeStep + 1 === index}
                    completed={completedSteps.includes(index - 1)}
                  />
                )}
                <Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Box
                      className={clsx(
                        classes.stepIconRoot,
                        (completedSteps.includes(index) ||
                          activeStep === index) &&
                          classes.stepIconActive
                      )}
                      onClick={() => handleGoToSelectedStep(index)}
                    >
                      {index}
                    </Box>
                    <Box mt={2}>
                      <Box
                        onClick={() => handleGoToSelectedStep(index)}
                        className={clsx(
                          classes.stepLabel,
                          (completedSteps.includes(index) ||
                            activeStep === index) &&
                            classes.stepLabelActive
                        )}
                      >
                        {label}
                        {label === steps[1] &&
                          Array.isArray(cartItems) &&
                          Boolean(cartItems.length) && (
                            <div className={classes.cartCircle}>
                              {cartItems.length}
                            </div>
                          )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Step>
            ))}
          </Stepper>
          <Box></Box>
        </Box>

        <Box className={classes.stepContentContainer}>
          {renderCurrentStepForm}
        </Box>
      </Box>
    </>
  );
};

export default CheckoutPage;
