import { makeStyles } from "@material-ui/styles";
import { COLOR_WHITE } from "../../../styles/constants";

const MENU_ITEM_STYLING = theme => ({
  textAlign: "right",
  marginBottom: theme.spacing(1.5),
  color: COLOR_WHITE,
  cursor: "pointer",
});

export default makeStyles(theme => ({
  courseAppIconLink: {
    display: "flex",
    alignItems: "center",
    color: "#a7b1c2",
    cursor: "pointer",
  },
  appDrawerPaper: {
    padding: ".75em 2em",
    backgroundColor: theme.palette.primary.main,
    color: COLOR_WHITE,
    width: "90vw",
    maxWidth: "300px",
    boxShadow: "none",
  },
  appDrawerContent: {
    borderBottom: "1px solid #d9d9d9",
    paddingBottom: "1rem",
  },
  appDrawerHeader: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  appDrawerCloseIcon: {
    fontSize: 22,
    color: COLOR_WHITE,
    cursor: "pointer",
    marginTop: theme.spacing(2),
  },
  appDrawerMenuItem: {
    fontSize: "1.6rem",
    fontWeight: "bold",
    ...MENU_ITEM_STYLING(theme),
  },
  appDrawerMenuSubItem: {
    fontSize: "1.2rem",
    fontWeight: "300",
    ...MENU_ITEM_STYLING(theme),
  },
  appDrawerMenuBox: {
    marginTop: theme.spacing(4),
  },
}));
