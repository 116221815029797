import React from "react";
import DoneIcon from "@material-ui/icons/Done";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import VisibilityIcon from "@material-ui/icons/Visibility";
import RemoveIcon from "@material-ui/icons/Remove";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import BlockOutlinedIcon from "@material-ui/icons/BlockOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import MoneyOffOutlinedIcon from "@material-ui/icons/MoneyOffOutlined";

import { COLOR_GREY, ERROR_COLOR, SUCCESS_COLOR } from "../../styles/constants";
import styles from "./styles";

const fontSize = 22;
const useStyles = makeStyles(theme => styles(theme));

export const CustomEditIcon = props => {
  const { onClickHandler, style } = props;
  return (
    <Tooltip title="Edit">
      <EditOutlinedIcon
        className="clickable"
        onClick={onClickHandler}
        color="secondary"
        style={{ ...style, fontSize }}
      />
    </Tooltip>
  );
};

export const CustomDeleteIcon = props => {
  const { onClickHandler, style } = props;
  return (
    <Tooltip title="Delete">
      <DeleteOutlinedIcon
        className="clickable"
        onClick={onClickHandler}
        style={{ color: ERROR_COLOR, fontSize, ...style }}
      />
    </Tooltip>
  );
};

export const CustomViewIcon = props => {
  const { onClickHandler, style } = props;
  return (
    <Tooltip title="View">
      <VisibilityIcon
        className="clickable"
        onClick={onClickHandler}
        color="secondary"
        style={{ ...style, fontSize }}
      />
    </Tooltip>
  );
};

export const CustomResolveIcon = props => {
  const { onClickHandler, style } = props;
  return (
    <Tooltip title="Resolve">
      <DoneIcon
        className="clickable"
        onClick={onClickHandler}
        style={{ ...style, color: SUCCESS_COLOR, fontSize }}
      />
    </Tooltip>
  );
};
export const CustomUnresolveIcon = props => {
  const { onClickHandler, style } = props;
  return (
    <Tooltip title="Unresolve">
      <RemoveIcon
        className="clickable"
        onClick={onClickHandler}
        style={{ ...style, color: ERROR_COLOR, fontSize }}
      />
    </Tooltip>
  );
};

export const CustomBlockIcon = props => {
  const { onClickHandler, style } = props;
  return (
    <Tooltip title="Block">
      <BlockOutlinedIcon
        className="clickable"
        onClick={onClickHandler}
        style={{ ...style, color: ERROR_COLOR, fontSize }}
      />
    </Tooltip>
  );
};

export const CustomExpandIcon = props => {
  const { onClickHandler, expanded, expandIconSize } = props;
  const classes = useStyles({ expandIconSize });

  return (
    <KeyboardArrowUpIcon
      onClick={onClickHandler}
      className={clsx(
        classes.expandIcon,
        !expanded && classes.inActiveExpandIcon
      )}
    />
  );
};

export const CustomRefundIcon = props => {
  const { onClickHandler, style, disabled } = props;
  return (
    <Tooltip title="Refund">
      <MoneyOffOutlinedIcon
        className="clickable"
        onClick={onClickHandler}
        style={{
          ...style,
          color: disabled ? COLOR_GREY : ERROR_COLOR,
          cursor: disabled && "default",
          fontSize,
        }}
      />
    </Tooltip>
  );
};
