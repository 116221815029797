import React, { Fragment, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import FlagIcon from "@material-ui/icons/Flag";
import moment from "moment";

import {
  CustomTableCell,
  CustomTableSortLabel,
} from "../../Components/CustomTableElements";
import { headCells, showRowsArr } from "./testResultArr";
import sortedQuestion from "./sortedQuestion";
import styles from "./styles";
import { QUESTION_STATUS } from "../../constants";
import FilterMenu from "../../Components/FilterMenu";

const useStyles = makeStyles(theme => styles(theme));

const TestResult = ({ userExam }) => {
  const classes = useStyles();

  const [isAsc, setIsAsc] = useState(true);
  const [orderBy, setOrderBy] = useState("id");
  const [anchorElRowMenu, setAnchorElRowMenu] = useState(null);
  const [showRowsList, setShowRowsList] = useState([
    QUESTION_STATUS["CORRECT"],
    QUESTION_STATUS["INCORRECT"],
    QUESTION_STATUS["MARKED"],
    QUESTION_STATUS["OMITTED"],
  ]);

  // Handle Rows Menu Start
  const handleOpenMenu = event => {
    setAnchorElRowMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElRowMenu(null);
  };

  const handleShowRowsList = event => {
    const status = event.target.name;

    if (event.target.checked) {
      if (showRowsList.includes(status)) {
        setShowRowsList(showRowsList.filter(elem => elem !== status));
      } else {
        setShowRowsList([...showRowsList, status]);
      }
    } else {
      setShowRowsList(showRowsList.filter(elem => elem !== status));
    }
  };
  const renderSelectedFilter = () => {
    if (showRowsList.length === 0) return "None";
    if (showRowsList.length === 1) return `${showRowsList[0]} Only`;
    if (showRowsList.length >= 4) return "All";
    else {
      return showRowsList.join(",");
    }
  };

  // Handle Rows Menu End

  const onSort = value => {
    if (orderBy === value) {
      setIsAsc(!isAsc);
    } else {
      setOrderBy(value);
    }
  };

  if (!userExam) return null;

  const allQuestions = sortedQuestion(userExam, isAsc, orderBy);
  const totalScore = `${(
    (userExam._totalScore / userExam.exam.questions.length) *
    100
  ).toFixed(0)}%`;

  const avgScorePercentage = `${userExam._avgScore.toFixed(0)}%`;

  const shouldShowRow = answer => {
    let questionStatus = [];
    if (answer) {
      if (answer.flag === true) {
        questionStatus.push(QUESTION_STATUS["MARKED"]);
      }
      if (answer.omitted === true) {
        questionStatus.push(QUESTION_STATUS["OMITTED"]);
      } else if (answer._answerRight) {
        questionStatus.push(QUESTION_STATUS["CORRECT"]);
      } else {
        questionStatus.push(QUESTION_STATUS["INCORRECT"]);
      }
    } else {
      questionStatus.push(QUESTION_STATUS["OMITTED"]);
    }
    let shouldShowRow;
    if (questionStatus.length > 1) {
      shouldShowRow =
        showRowsList.includes(questionStatus[0]) ||
        showRowsList.includes(questionStatus[1]);
    } else {
      shouldShowRow = showRowsList.includes(questionStatus[0]);
    }

    return shouldShowRow;
  };

  return (
    userExam && (
      <Fragment>
        {/* Your Score & Test Settings Section Start Here */}

        <Grid container direction="column">
          <div className={classes.root}>
            <Grid item>
              <Grid
                container
                alignItems="center"
                justify="center"
                className={classes.summaryStats}
                spacing={10}
              >
                <Grid item xs={12} sm={6} lg={5} xl={4}>
                  <div className={classes.scoreStats}>
                    <div className={classes.scoreStatsTitle}>Your Score</div>

                    <div className={classes.scoreStatsArea}>
                      <div
                        className={classes.userScore}
                        style={{ left: totalScore }}
                      >
                        <span>{totalScore}</span>
                        <ArrowDropDownIcon className={classes.userScoreIcon} />
                      </div>
                      <div
                        className={classes.avgScoreLine}
                        style={{ left: avgScorePercentage }}
                      >
                        <div className={classes.avgScore}>
                          {`Avg: ${avgScorePercentage}`}
                          <div className={classes.arrowUp}></div>
                        </div>
                      </div>

                      <div className={classes.scoreBar}>
                        <div
                          className={classes.userScoreBar}
                          style={{ width: totalScore }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} lg={5} xl={4}>
                  <div className={classes.testStats}>
                    <div className={classes.testStatsTitle}>Test Settings</div>
                    <div
                      className={classes.spaceBetweenContainer}
                      style={{ borderBottom: "1px solid #e8e8e8" }}
                    >
                      <div>Mode</div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span className={classes.chip}>
                          {userExam.exam.timed ? "Timed" : "Untimed"}
                        </span>
                        {userExam.exam.tutor && (
                          <span className={classes.chip}>Tutor</span>
                        )}
                      </div>
                    </div>
                    <div className={classes.spaceBetweenContainer}>
                      <div>Question Pool</div>
                      <div>
                        <span className={classes.chip}>
                          {userExam.exam.question_mode.charAt(0).toUpperCase() +
                            userExam.exam.question_mode.slice(1)}
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
        {/* Your Score & Test Settings Section End Here* /}

      {/* Questions Table Section Start Here*/}
        <div className={classes.rowsFilterContainer}>
          <FilterMenu
            handleOpenMenu={handleOpenMenu}
            handleCloseMenu={handleCloseMenu}
            anchorEl={anchorElRowMenu}
            menuItemArr={showRowsArr}
            text={renderSelectedFilter()}
            selectedFilter={showRowsList}
            handleSelectedFilter={handleShowRowsList}
            filterType={"ARRAY"}
            style={{ marginLeft: "auto" }}
          />
        </div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <CustomTableCell></CustomTableCell>
                {headCells.map(headCell => {
                  return (
                    <CustomTableCell
                      key={headCell.id}
                      padding={"normal"}
                      sortDirection={isAsc ? "asc" : "desc"}
                    >
                      <CustomTableSortLabel
                        active={orderBy === headCell.id}
                        direction={isAsc ? "asc" : "desc"}
                        onClick={() => onSort(headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <span className={classes.visuallyHidden}>
                            {!isAsc ? "sorted descending" : "sorted ascending"}
                          </span>
                        ) : null}
                      </CustomTableSortLabel>
                    </CustomTableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {allQuestions &&
                allQuestions.map(question => {
                  const answer = userExam.userExamAnswers.filter(
                    userAnswer => userAnswer.question._id === question._id
                  )[0];
                  const timeSpent = !answer.answer ? 0 : answer._timeUsed;
                  const correctOthersPercentage = question.answers.filter(
                    answer => {
                      if (answer.validAnswer) return answer;
                    }
                  )[0]["percentage"];

                  return (
                    <Fragment key={question._id}>
                      {shouldShowRow(answer) && (
                        <TableRow>
                          <CustomTableCell style={{ width: 99 }} align="right">
                            <div
                              style={{
                                display: "inline-flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {answer && answer.flag && (
                                <FlagIcon
                                  className={classes.icon}
                                  color="secondary"
                                  style={{ marginRight: 6 }}
                                />
                              )}

                              {!answer ? (
                                <RemoveCircleOutlineIcon
                                  className={classes.icon}
                                  color="secondary"
                                />
                              ) : answer && answer.omitted ? (
                                <RemoveCircleOutlineIcon
                                  className={classes.icon}
                                  color="secondary"
                                />
                              ) : answer && answer._answerRight ? (
                                <DoneIcon
                                  className={clsx(
                                    classes.icon,
                                    classes.correctIcon
                                  )}
                                />
                              ) : (
                                <CloseIcon
                                  className={clsx(
                                    classes.icon,
                                    classes.incorrectIcon
                                  )}
                                />
                              )}
                            </div>
                          </CustomTableCell>
                          <CustomTableCell>{question._ref}</CustomTableCell>
                          <CustomTableCell>
                            {question.category.name}
                          </CustomTableCell>
                          <CustomTableCell>
                            {question.subCategory.name}
                          </CustomTableCell>
                          <CustomTableCell>
                            {`${correctOthersPercentage.toFixed(0)}%`}
                          </CustomTableCell>

                          <CustomTableCell>
                            {moment.utc(timeSpent).format("HH:mm:ss")}
                          </CustomTableCell>
                        </TableRow>
                      )}
                    </Fragment>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* Questions Table Section End Here */}
      </Fragment>
    )
  );
};

export default TestResult;
