import * as ActionTypes from "../actions/transactionAction";
import * as UserTypes from "../actions/userAction";

const {
  GET_TRANSACTION_LIST,
  GET_TRANSACTION_BY_ID,
  GET_TRANSACTION_FORM_BY_ID,
  UPDATE_TRANSACTION,
} = ActionTypes;
const { USER_LOGOUT } = UserTypes;
const initialAppState = {
  transactions: [],
  transaction: null,
  form: null,
};

const transactionReducer = (state = initialAppState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TRANSACTION_LIST.AWAIT:
    case GET_TRANSACTION_BY_ID.AWAIT:
    case UPDATE_TRANSACTION.AWAIT:
    case GET_TRANSACTION_LIST.FAIL:
    case GET_TRANSACTION_BY_ID.FAIL:
    case UPDATE_TRANSACTION.FAIL:
      return { ...state };
    case GET_TRANSACTION_LIST.SUCCESS:
      return {
        ...state,
        transactions: payload,
      };
    case GET_TRANSACTION_BY_ID.SUCCESS:
      return {
        ...state,
        transaction: payload,
      };
    case GET_TRANSACTION_FORM_BY_ID.SUCCESS:
      return {
        ...state,
        form: payload,
      };
    case UPDATE_TRANSACTION.SUCCESS:
      return {
        ...state,
        form: payload,
      };
    case USER_LOGOUT: {
      return initialAppState;
    }
    default:
      return state;
  }
};

export default transactionReducer;
