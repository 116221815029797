import { Box, Grid } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import UserProfileForm from "../../../Components/Form/UserProfile/UserProfileForm";
import ChangePasswordForm from "./components/ChangePasswordForm";
import FormTitle from "../FormTitle";
import useStyles from "./styles";

const ProfilePage = () => {
  const classes = useStyles();

  return (
    <Box px={1}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Box
            className={clsx(classes.formContainer, classes.leftBox)}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <FormTitle title={"User Information"} />
            <Box mt={4}>
              <UserProfileForm />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            className={classes.formContainer}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <FormTitle title={"Change Password"} />
            <Box mt={4}>
              <ChangePasswordForm />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProfilePage;
