import ReactHtmlParser from "react-html-parser";
import React from "react";
import watermark from "watermarkjs";
import shortid from "shortid";
import ShowMoreText from "react-show-more-text";
import imageCompression from "browser-image-compression";
import moment from "moment";
import {
  ACTIVATE_USER_SUB_PATH,
  FLASHCARD_LIST_URL,
  FORGOT_PASSWORD_URL,
  LOGIN_URL,
  NOTE_LIST_URL,
  ORDER_RECEIPT_URL,
  RESET_PASSWORD_URL,
  TEST_DETAIL_URL,
} from "../routes/constants";
import jwt_decode from "jwt-decode";
import { safeFormatDefaultDateTime } from "./date-helper";
import { getCountryByCode, getStateByCodeAndCountry } from "./csc-helper";

export * from "./category-helper";
export * from "./flashcard-helper";
export * from "./date-helper";
export * from "./csc-helper";

export function nth(n) {
  return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
}

export function toTwoDecimals(n) {
  return Math.round(n * 100) / 100;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export const getSecondsFromHHMMSS = value => {
  const [str1, str2, str3] = value.split(":");

  const val1 = Number(str1);
  const val2 = Number(str2);
  const val3 = Number(str3);

  if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
    return val1;
  }

  if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
    return val1 * 60 + val2;
  }

  if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
    return val1 * 60 * 60 + val2 * 60 + val3;
  }

  return 0;
};

export const toHHMMSS = secs => {
  const secNum = Math.round(secs);
  let hours = 0;
  let minutes = 0;
  let seconds = secNum;
  if (secNum >= 60) {
    hours = Math.floor(secNum / 3600);
    minutes = Math.floor(secNum / 60) % 60;
    seconds = secNum % 60;
  }

  return [hours, minutes, seconds]
    .map(val => (val < 10 ? `0${val}` : val))
    .join(":");
};

// Route Specific Functions
export const isNotShowHeaderPage = pathname => {
  const temp = pathname.toLowerCase();
  return (
    temp === LOGIN_URL ||
    temp === RESET_PASSWORD_URL ||
    temp === ORDER_RECEIPT_URL ||
    temp === FORGOT_PASSWORD_URL ||
    temp.substr(0, 22) === TEST_DETAIL_URL ||
    temp.substr(0, 14) === ACTIVATE_USER_SUB_PATH
  );
};

export const isTopicDetailPage = pathname =>
  pathname.match(/\/topics\/[abc|0-9]+/);

export const isTestDetailPage = pathname => {
  return pathname.substr(0, 22) === TEST_DETAIL_URL;
};

export const isDisableTextSelectionPage = pathname => {
  return (
    isTopicDetailPage(pathname) ||
    pathname === NOTE_LIST_URL ||
    pathname === FLASHCARD_LIST_URL
  );
};

export const shuffleArray = array => {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

export const shortenString = str => {
  let newStr = str.split(".")[0];
  if (newStr.length > 90) {
    newStr = newStr.substring(0, 90) + "...";
  }
  return newStr;
};

export const extractHTMLString = s => {
  var span = document.createElement("span");
  span.innerHTML = s;
  return span.textContent || span.innerText;
};

export const trimStr = str =>
  str
    .trim()
    .replace(/  |\r\n|\n|\r/gm, "")
    .replace(/\s/g, "");

export const compareObjects = (obj1, obj2) => {
  if (JSON.stringify(obj1) === JSON.stringify(obj2)) {
    return true;
  } else {
    return false;
  }
};

const parseResponsiveImage = attribs => {
  const { src, width, height } = attribs;
  return (
    <img
      src={src}
      key={src}
      alt="Amckey"
      width={width}
      height={height}
      className="img-responsive"
    />
  );
};

export const parseResponsiveHTML = html => {
  return ReactHtmlParser(html, {
    transform: node => {
      if (node.name === "img") {
        return parseResponsiveImage(node.attribs);
      }
    },
  });
};

export const parseLightBoxHTML = (html, setShowImagePreview) => {
  const parsed = ReactHtmlParser(html, {
    transform: node => {
      if (node.name === "img") {
        const { src, width, height } = node.attribs;
        return (
          <img
            src={src}
            width={width}
            height={height}
            className={"clickable img-responsive"}
            key={src}
            onClick={() =>
              setShowImagePreview({
                show: true,
                imgAttr: { url: src, width, height },
              })
            }
          />
        );
      }
    },
  });

  return parsed;
};

export const parseTopicReferencesHTML = (html, setShowImagePreview) => {
  return ReactHtmlParser(html, {
    transform: node => {
      if (node.name === "img") {
        return parseLightBoxHTML(html, setShowImagePreview);
      }

      if (node.name === "a") {
        const { href } = node.attribs;
        return (
          <a href={href} target={"_blank"} key={href}>
            {href}
          </a>
        );
      }
    },
  });
};

export const convertPXToNum = pixelVal => {
  return Number(pixelVal.split("px")[0]);
};

export const convertVWToPX = width => {
  const vwNum = Number(width.split("vw")[0]);

  return vwNum * (document.documentElement.clientWidth / 100);
};

export const convertVHToPX = height => {
  const vhNum = Number(height.split("vh")[0]);

  return vhNum * (document.documentElement.clientHeight / 100);
};

export const validateMaxLength = (html, maxLength) =>
  extractHTMLString(html).length > maxLength ? "Max characters exceeded" : true;

function getSafeRanges(dangerous) {
  const a = dangerous.commonAncestorContainer;
  // Starts -- Work inward from the start, selecting the largest safe range
  const s = new Array(0),
    rs = new Array(0);

  let xm;

  if (dangerous.startContainer !== a)
    for (let i = dangerous.startContainer; i !== a; i = i.parentNode) s.push(i);
  if (0 < s.length)
    for (let i = 0; i < s.length; i++) {
      const xs = document.createRange();
      if (i) {
        xs.setStartAfter(s[i - 1]);
        xs.setEndAfter(s[i].lastChild);
      } else {
        xs.setStart(s[i], dangerous.startOffset);
        xs.setEndAfter(
          s[i].nodeType === Node.TEXT_NODE ? s[i] : s[i].lastChild
        );
      }
      rs.push(xs);
    }

  // Ends -- basically the same code reversed
  const e = new Array(0),
    re = new Array(0);
  if (dangerous.endContainer !== a)
    for (let i = dangerous.endContainer; i !== a; i = i.parentNode) e.push(i);
  if (0 < e.length)
    for (let i = 0; i < e.length; i++) {
      const xe = document.createRange();
      if (i) {
        xe.setStartBefore(e[i].firstChild);
        xe.setEndBefore(e[i - 1]);
      } else {
        xe.setStartBefore(
          e[i].nodeType === Node.TEXT_NODE ? e[i] : e[i].firstChild
        );
        xe.setEnd(e[i], dangerous.endOffset);
      }
      re.unshift(xe);
    }

  // Middle -- the uncaptured middle
  if (0 < s.length && 0 < e.length) {
    xm = document.createRange();
    xm.setStartAfter(s[s.length - 1]);
    xm.setEndBefore(e[e.length - 1]);
  } else {
    return [dangerous];
  }

  // Concat
  rs.push(xm);
  const response = rs.concat(re);

  // Send to Console
  return response;
}

function highlightRange(range) {
  const newNode = document.createElement("div");
  newNode.setAttribute(
    "style",
    "background-color: yellow; display: inline; color: #000000"
  );
  newNode.setAttribute("class", "one");

  range.surroundContents(newNode);
}

export const highlightSelection = userSelection => {
  // const userSelection = window.getSelection().getRangeAt(0);
  const safeRanges = getSafeRanges(userSelection);

  for (let i = 0; i < safeRanges.length; i++) {
    highlightRange(safeRanges[i]);
  }
};

export const sizeInMB = sizeInBytes =>
  `${(sizeInBytes / (1024 * 1024)).toFixed(2)}MB`;

export const preprocessImg = async blobInfo => {
  let newImage = blobInfo.blob();

  // Generate random randomFilename
  const randomFilename = `${shortid.generate()}${
    blobInfo.filename().match(/\.[0-9a-z]+$/i)[0]
  }`;

  // Add Watermark to Image
  await watermark([newImage])
    .blob(
      watermark.text.lowerRight("@AMCKEY", "16px sans-serif", "#f15a25", 1.0)
    )
    .then(function(newBlob) {
      newImage = newBlob;
    });

  // Compress Image
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  try {
    newImage = await imageCompression(newImage, options);
  } catch (error) {
    console.log(error);
  }

  return {
    filename: randomFilename,
    blob: newImage,
    size: sizeInMB(newImage.size),
  };
};

export const parseQuestionHtml = html => {
  const parsedHTML = ReactHtmlParser(html, {
    transform: node => {
      if (node.name === "img") {
        const { src } = node.attribs;
        return (
          <a href={src} key={src} target="_blank">
            {src}
          </a>
        );
      }
    },
  });

  return <ShowMoreText lines={1}>{parsedHTML}</ShowMoreText>;
};

export const parseDateHtml = (datetime, width = 100) => {
  let dateTxt = safeFormatDefaultDateTime(datetime);
  if (!dateTxt)
    return (
      <div style={{ width }}>
        <span>N/A</span>
      </div>
    );
  const splitDateTime = moment(datetime)
    .format("DD/MM/YYYY,h:mm:ss A")
    .split(",");

  return (
    <div style={{ width }}>
      <div>{splitDateTime[0]}</div>
      <div>{splitDateTime[1]}</div>
    </div>
  );
};

export const parseCapitalizeHtml = value => {
  return <span style={{ textTransform: "capitalize" }}>{value}</span>;
};

export const parseAuthToken = authToken => {
  if (authToken) {
    const decoded = jwt_decode(authToken);
    return decoded.id;
  }

  return null;
};

export const parseUsername = (firstName, lastName) =>
  `${firstName ? `${firstName} ` : ""}${lastName ? `${lastName}` : ""}`;

export const safeParseCountry = countryCode => {
  return countryCode
    ? getCountryByCode(countryCode)
      ? getCountryByCode(countryCode).name
      : countryCode
    : "";
};

export const safeParseState = (stateCode, countryCode) => {
  return stateCode && countryCode
    ? getStateByCodeAndCountry(stateCode, countryCode)
      ? getStateByCodeAndCountry(stateCode, countryCode).name
      : ""
    : "";
};

export const getLabelFromValue = (list, val) => {
  return val ? list.find(item => item.value === val).label : "";
};

export const parseUploadToSpacesParams = (blob, filename) => ({
  Bucket: "amckey",
  Key: filename,
  Body: blob,
  ACL: "public-read",
});
