import { CART_ITEMS } from "../localStorage/constants";

const MODULE_NAME = "CART";

export const CART_ACTION = {
  ADD_ITEM: `${MODULE_NAME}/ADD_ITEM`,
  REMOVE_ITEM: `${MODULE_NAME}/REMOVE_ITEM`,
  SET_PAYMENT_METHOD: `${MODULE_NAME}/SET_PAYMENT_METHOD`,
  SET_IS_VALID_TO_PAY: `${MODULE_NAME}/SET_IS_VALID_TO_PAY`,
  SET_PAYPAL_ORDER_CONFIG: `${MODULE_NAME}/SET_PAYPAL_ORDER_CONFIG`,
  SET_STRIPE_ORDER_CONFIG: `${MODULE_NAME}/SET_STRIPE_ORDER_CONFIG`,
  CLEAR_PAYMENT_CONFIGS: `${MODULE_NAME}/CLEAR_PAYMENT_CONFIGS`,
  CLEAR: `${MODULE_NAME}/CLEAR`,
};

export const addToCart = pricePlan => (dispatch, getState) => {
  dispatch({
    type: CART_ACTION.ADD_ITEM,
    payload: {
      pricePlan,
    },
  });

  localStorage.setItem(CART_ITEMS, JSON.stringify(getState().cart.cartItems));
};

export const removeFromCart = pricePlanId => (dispatch, getState) => {
  dispatch({
    type: CART_ACTION.REMOVE_ITEM,
    payload: {
      pricePlanId,
    },
  });
  localStorage.setItem(CART_ITEMS, JSON.stringify(getState().cart.cartItems));
};

export const clearCart = () => dispatch => {
  localStorage.removeItem(CART_ITEMS);
  dispatch({
    type: CART_ACTION.CLEAR,
  });
};

export const clearPaymentConfigs = () => dispatch => {
  dispatch({
    type: CART_ACTION.CLEAR_PAYMENT_CONFIGS,
  });
};

export const setPayPalOrderConfig = paypalOrderConfig => dispatch => {
  dispatch({
    type: CART_ACTION.SET_PAYPAL_ORDER_CONFIG,
    payload: paypalOrderConfig,
  });
};

export const setStripeOrderConfig = stripeOrderConfig => dispatch => {
  dispatch({
    type: CART_ACTION.SET_STRIPE_ORDER_CONFIG,
    payload: stripeOrderConfig,
  });
};

export const setPaymentMethod = paymentMethod => dispatch => {
  dispatch({
    type: CART_ACTION.SET_PAYMENT_METHOD,
    payload: paymentMethod,
  });
};

export const setIsValidToPay = isValidToPay => dispatch => {
  dispatch({
    type: CART_ACTION.SET_IS_VALID_TO_PAY,
    payload: isValidToPay,
  });
};
