import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  LIST_ITEM_ACTIVE_COLOR,
  LIST_ITEM_MUTE_COLOR,
} from "../../styles/constants";

const useStyles = makeStyles(theme => ({
  statColumn: {
    display: "flex",
    flexDirection: "column",
  },
  statTitleRow: {
    color: LIST_ITEM_ACTIVE_COLOR,
    marginBottom: theme.spacing(1),
    fontSize: "16px",
  },
  statRow: {
    color: LIST_ITEM_MUTE_COLOR,
    display: "flex",
    justifyContent: "space-between",
    height: "40px",
    alignItems: "flex-end",
    paddingBottom: "8px",
    borderBottom: "1px solid #e7eaec",
    fontSize: "15px",
  },
  chip: {
    marginLeft: "auto",
    height: "21px",
    lineHeight: "21px",
    paddingLeft: "7px",
    paddingRight: "7px",
    borderRadius: "11px",
    backgroundColor: "#e8e8e8",
    color: "#4a4a4a",
    textAlign: "center",
    fontSize: 12,
    fontWeight: 600,
  },
}));

const StatsColumn = ({ title, stats }) => {
  const classes = useStyles();

  return (
    <div className={classes.statColumn}>
      <div className={classes.statTitleRow}>{title}</div>
      {stats.map(item => (
        <div className={classes.statRow} key={item.x}>
          <div className={classes.subStatTitle}>{item.x}</div>
          <div className={classes.chip}>{item.y}</div>
        </div>
      ))}
    </div>
  );
};

export default StatsColumn;
