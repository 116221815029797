import * as UIActions from "./uiAction";
import * as TransactionApi from "../services/transaction";
import { AppConfig } from "../configs";
import { handleAxiosError } from "./errorAction";
import history from "../routes/history";
import { MANAGE_TRANSACTION_URL } from "../routes/constants";

const { client } = AppConfig;

const MODULE_NAME = "TRANSACTION";

export const GET_TRANSACTION_LIST = {
  AWAIT: `${MODULE_NAME}/LIST/AWAIT`,
  SUCCESS: `${MODULE_NAME}/LIST/SUCCESS`,
  FAIL: `${MODULE_NAME}/LIST/FAIL`,
};
export const GET_TRANSACTION_BY_ID = {
  AWAIT: `${MODULE_NAME}/BY_ID/AWAIT`,
  SUCCESS: `${MODULE_NAME}/BY_ID/SUCCESS`,
  FAIL: `${MODULE_NAME}/BY_ID/FAIL`,
};

export const GET_TRANSACTION_FORM_BY_ID = {
  AWAIT: `${MODULE_NAME}/FORM_BY_ID/AWAIT`,
  SUCCESS: `${MODULE_NAME}/FORM_BY_ID/SUCCESS`,
  FAIL: `${MODULE_NAME}/FORM_BY_ID/FAIL`,
};

export const UPDATE_TRANSACTION = {
  AWAIT: `${MODULE_NAME}/UPDATE/AWAIT`,
  SUCCESS: `${MODULE_NAME}/UPDATE/SUCCESS`,
  FAIL: `${MODULE_NAME}/UPDATE/FAIL`,
};
export const SET_TRANSACTION = `${MODULE_NAME}/SET_TRANSACTION`;

export const getTransactionList = (own = false) => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({
    type: GET_TRANSACTION_LIST.AWAIT,
  });
  try {
    const res = await client.query({
      query: TransactionApi.GET_TRANSACTIONS,
      variables: { own },
    });
    if (res && res.data && res.data.transactions) {
      dispatch({
        type: GET_TRANSACTION_LIST.SUCCESS,
        payload: res.data.transactions,
      });
    }
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    handleAxiosError(
      dispatch,
      err,
      "We are having problem getting transactions. Please try again later."
    );
    dispatch({
      type: GET_TRANSACTION_LIST.FAIL,
    });
  }
};

export const getTransactionById = (id, isForm = false) => async dispatch => {
  dispatch(UIActions.appLoading());
  if (isForm) {
    dispatch({
      type: GET_TRANSACTION_FORM_BY_ID.AWAIT,
    });
  } else {
    dispatch({
      type: GET_TRANSACTION_BY_ID.AWAIT,
    });
  }
  try {
    const res = await client.query({
      query: TransactionApi.GET_TRANSACTION,
      variables: { _id: id },
    });
    if (res && res.data && res.data.transaction) {
      if (isForm) {
        dispatch({
          type: GET_TRANSACTION_FORM_BY_ID.SUCCESS,
          payload: res.data.transaction,
        });
      } else {
        dispatch({
          type: GET_TRANSACTION_BY_ID.SUCCESS,
          payload: res.data.transaction,
        });
      }
    }
    dispatch(UIActions.appReady());
  } catch (err) {
    handleAxiosError(
      dispatch,
      err,
      "We are having problem getting transactions. Please try again later."
    );
    if (isForm) {
      dispatch({
        type: GET_TRANSACTION_FORM_BY_ID.FAIL,
      });
    } else {
      dispatch({
        type: GET_TRANSACTION_BY_ID.FAIL,
      });
    }
  }
};

export const updateTransaction = data => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({
    type: UPDATE_TRANSACTION.AWAIT,
  });

  try {
    const res = await client.mutate({
      mutation: TransactionApi.UPDATE_TRANSACTION,
      variables: data,
    });
    dispatch({
      type: UPDATE_TRANSACTION.SUCCESS,
      payload: res.data.updateTransaction,
    });
    dispatch(UIActions.showSuccess("Transaction updated successfully."));
    dispatch(UIActions.appReady());
    history.push(MANAGE_TRANSACTION_URL);
    return res.data;
  } catch (err) {
    handleAxiosError(dispatch, err, "Error updating transaction");
    dispatch({
      type: UPDATE_TRANSACTION.FAIL,
    });
  }
};

export const setTransaction = transaction => ({
  type: SET_TRANSACTION,
  payload: transaction,
});
