import { Box } from "@material-ui/core";
import React from "react";

const FormTitle = props => {
  const { title } = props;
  return (
    <Box fontSize={"1.8rem"} component="h3">
      {title}
    </Box>
  );
};

export default FormTitle;
