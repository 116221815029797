const styles = theme => ({
  root: {
    height: "100%",
    padding: theme.spacing(5),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(3),
    },
  },
  rowsFilterContainer: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px",
    paddingTop: "25px",
    paddingBottom: "25px",
    color: "#979797",
    marginRight: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      marginRight: theme.spacing(3),
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  summaryStats: {
    marginTop: 20,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 10,
    },
  },
  scoreStats: {
    maxWidth: 350,
    margin: "0 auto",
  },
  scoreStatsTitle: {
    marginBottom: 20,
  },
  scoreStatsArea: {
    height: 100,
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  userScore: {
    position: "absolute",
    top: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 40,
    marginLeft: -20,
    zIndex: 2,
    color: "#69c869",
  },
  userScoreIcon: {
    marginTop: -3,
    color: " #69c869",
  },
  scoreBar: {
    position: "relative",
    height: 35,
    width: "100%",
    borderRadius: "17.5px",
    backgroundColor: "#f4f5f7",
    overflow: "hidden",
  },
  userScoreBar: {
    position: "absolute",
    height: "100%",
    top: 0,
    left: 0,
    backgroundColor: "#69c869",
  },
  avgScoreLine: {
    backgroundColor: "#e3e3e3",
    position: "absolute",
    top: 33,
    width: 2,
    height: 35,
    zIndex: 2,
  },
  avgScore: {
    width: 75,
    marginLeft: -37.5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 42,
    height: 38,
    borderRadius: 2,
    backgroundColor: "#f4f5f7",
    color: "#757575",
    fontSize: 13,
  },
  arrowUp: {
    position: "absolute",
    bottom: "100%",
    left: "44%",
    width: 0,
    height: 0,
    borderLeft: "6px solid transparent",
    borderRight: "6px solid transparent",
    borderBottom: "6px solid #f4f5f7",
  },
  testStats: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 300,
    margin: "0 auto",
  },
  testStatsTitle: {
    marginBottom: 20,
    color: `${theme.palette.text.primary} !important`,
  },
  spaceBetweenContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#757575",
    fontSize: 15,
    width: "100%",
    padding: "10px 0",
  },
  chip: {
    marginLeft: 5,
    height: 26,
    borderRadius: 17.5,
    backgroundColor: "#f4f5f7",
    fontSize: 12,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 10,
  },
  icon: {
    width: "22px",
    height: "22px",
    cursor: "pointer",
  },
  incorrectIcon: {
    color: "#df4545",
  },
  correctIcon: {
    color: "#66b847",
  },
});

export default styles;
