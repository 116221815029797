import {
  BILLING_URL,
  PRICING_URL,
  PROFILE_URL,
  SUBSCRIPTIONS_URL,
} from "../../routes/constants";
import { faHome, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

export const TOP_BAR_HEIGHT = 40;
export const MAIN_BAR_HEIGHT = 64;
export const USER_TABS_BAR_HEIGHT = 70;

export const courseAppNavLinks = [
  {
    icon: faHome,
    title: "My Account",
    route: SUBSCRIPTIONS_URL,
  },
  {
    icon: faSignOutAlt,
    title: "Log out",
  },
];

export const socialMediaIcons = [
  {
    imgPath: require("../../resources/assets/svg/facebook.svg"),
    alt: "Facebook Icon",
  },
  {
    imgPath: require("../../resources/assets/svg/twitter.svg"),
    alt: "Twitter Icon",
  },
  {
    imgPath: require("../../resources/assets/svg/linkedin.svg"),
    alt: "LinkedIn Icon",
  },
  {
    imgPath: require("../../resources/assets/svg/youtube.svg"),
    alt: "Youtube Icon",
  },
];

export const appMenuItems = [
  {
    title: "Home",
    route: `/`,
  },
  {
    title: "Subscription Plan",
    route: `/subscription-plan`,
  },
  {
    title: "Services",
    route: `/services`,
  },
  {
    title: "About",
    route: `/about`,
  },
  {
    title: "Blog",
    route: `/blog`,
  },
  {
    title: "Contact",
    route: `/contact`,
  },
];

export const userTabs = [
  {
    title: "Subscriptions",
    route: SUBSCRIPTIONS_URL,
  },
  {
    title: "Profile",
    route: PROFILE_URL,
  },
  {
    title: "Billing",
    route: BILLING_URL,
  },
];
