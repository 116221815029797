import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import TextField from "@material-ui/core/TextField";
import { useForm, Controller } from "react-hook-form/dist/index.ie11";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import DoneIcon from "@material-ui/icons/Done";
import { useTheme } from "@material-ui/core/styles";

import * as NoteActions from "../../actions/noteAction";
import styles from "./styles";
import QuestionExplanation from "../../Components/QuestionExplanation";
import {
  CustomConfirmDialogActions,
  CustomActionBtn,
  CustomCancelBtn,
} from "../../Components/CustomDialogElements";

const Accordion = withStyles({
  root: {
    width: "100%",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
    fontSize: 13,
    color: "grey",
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    borderTop: "1px solid #e3e6e8",
    padding: theme.spacing(2),
    display: "block",
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles(theme => styles(theme));

const NoteCard = props => {
  const {
    note,
    showQuestion = false,
    setShowImagePreview,
    setSelectedQuestionID,
    updateNote,
    removeNote,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const { reset, handleSubmit, errors, control } = useForm({
    mode: "onChange",
    defaultValues: {
      content: note.content,
    },
  });
  const [editing, setEditing] = useState(false);
  const [noteContent, setNoteContent] = useState(note.content);
  const [showModal, setShowModal] = useState(false);
  const [expanded, setExpanded] = useState(false);

  // Handle Confirmtion Modal Start
  const handleOpenModal = event => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  // Handle Confirmtion Modal End

  // Handle Accordion State Start
  const onClickAccordion = () => {
    setExpanded(!expanded);
  };
  // Handle Accordion State End

  const onSubmit = async form => {
    const res = await updateNote({
      _id: note._id,
      input: {
        content: form.content,
      },
    });

    if (res) {
      setNoteContent(res.updateNote.content);
      reset({
        content: res.updateNote.content,
      });
    }

    setEditing(false);
  };

  if (!noteContent) return null;

  return (
    <Fragment>
      <div className={classes.noteCardContainer}>
        <div className={classes.noteCardHeaderContainer}>
          <div className={classes.noteCardHeader}>
            <div>
              <span className={classes.questionID}>
                Question ID: {note.question._ref}
              </span>
              <span className={classes.category}>
                {note.category.name} - {note.subCategory.name}
              </span>
            </div>
            <div className={classes.noteCardActions}>
              {!editing ? (
                <Fragment>
                  <Tooltip title="Edit Note">
                    <EditOutlinedIcon
                      className={classes.actionsIcon}
                      style={{ marginRight: 6 }}
                      color="secondary"
                      onClick={() => setEditing(true)}
                    />
                  </Tooltip>

                  <div className={classes.verticalLine}></div>

                  <Tooltip title="Delete Icon">
                    <DeleteOutlinedIcon
                      className={classes.actionsIcon}
                      style={{ marginLeft: 6 }}
                      color="secondary"
                      onClick={handleOpenModal}
                    />
                  </Tooltip>

                  <Dialog onClose={handleCloseModal} open={showModal}>
                    <DialogContent>
                      Are you sure you wish to delete this note?
                    </DialogContent>
                    <CustomConfirmDialogActions
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: 15,
                      }}
                    >
                      <CustomActionBtn onClick={() => removeNote(note._id)}>
                        Delete
                      </CustomActionBtn>
                      <CustomCancelBtn onClick={handleCloseModal}>
                        Cancel
                      </CustomCancelBtn>
                    </CustomConfirmDialogActions>
                  </Dialog>
                </Fragment>
              ) : (
                <Fragment>
                  <span
                    style={{
                      marginRight: 6,
                      display: "flex",
                      justifyContent: "center",
                    }}
                    onClick={handleSubmit(onSubmit)}
                  >
                    {mdUp ? (
                      <span className={classes.optionTxt}>Save</span>
                    ) : (
                      <DoneIcon className={classes.actionsIcon} />
                    )}
                  </span>
                  <div className={classes.verticalLine}></div>

                  <span
                    style={{
                      marginLeft: 6,
                      display: "flex",
                      justifyContent: "center",
                    }}
                    onClick={() => setEditing(false)}
                  >
                    {mdUp ? (
                      <span className={classes.optionTxt}>Cancel</span>
                    ) : (
                      <ClearOutlinedIcon className={classes.actionsIcon} />
                    )}
                  </span>
                </Fragment>
              )}
            </div>
          </div>
        </div>
        <div>
          {editing ? (
            <Controller
              control={control}
              name={"content"}
              rules={{ required: "Note cannot be empty" }}
              render={({ onChange, value, name, ref }) => (
                <div className={classes.noteCardContentContainer}>
                  <TextField
                    name={name}
                    color="secondary"
                    multiline
                    style={{
                      width: "100%",
                    }}
                    inputRef={ref}
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    error={!!errors.content}
                    helperText={errors.content && errors.content.message}
                    InputProps={{
                      style: { fontSize: 13 },
                    }}
                    FormHelperTextProps={{
                      style: { fontSize: 13 },
                    }}
                  />
                </div>
              )}
            />
          ) : showQuestion ? (
            <Accordion expanded={expanded}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ fill: "#d1d1d1" }} />}
                onClick={onClickAccordion}
              >
                {noteContent}
              </AccordionSummary>
              <AccordionDetails>
                <QuestionExplanation
                  question={note.question}
                  setShowImagePreview={setShowImagePreview}
                  setSelectedQuestionID={setSelectedQuestionID}
                />
              </AccordionDetails>
            </Accordion>
          ) : (
            <div className={classes.noteCardContentContainer}>
              {noteContent}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  updateNote: data => dispatch(NoteActions.updateNote(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NoteCard);
