import {
  COLOR_BLACK,
  COLOR_GREY,
  ERROR_COLOR,
  SUCCESS_COLOR,
} from "../../../styles/constants";
import React from "react";
import {
  EMAIL_VERIFICATION_STATUS,
  FREE_TRIAL_PLAN_NAME,
  SUBSCRIPTION_STATUS,
  TRANSACTION_SOURCE,
  USER_STATUS,
} from "../../../constants";
import {
  capitalizeFirstLetter,
  parseCapitalizeHtml,
  parseDateHtml,
} from "../../../utils";
import CustomTooltip from "../../../Components/CustomTooltip";
import { Box } from "@material-ui/core";
import SingleColumnTooltipBody from "../../../Components/TootipBody/SingleColumnTooltipBody";
import MultipleColumnTooltipBody from "../../../Components/TootipBody/MultipleColumnTooltipBody";
import { EMAIL_HISTORY_COLUMNS } from "./constants";
import DoneIcon from "@material-ui/icons/Done";

const USER_STATUS_LABEL = {
  VERIFIED: "Verified",
  UNVERIFIED: "Unverified",
};

export const parseUserPlanHtml = subscription => {
  let text = "";
  let color = COLOR_BLACK;
  if (!subscription || !subscription._planHistory) {
    text = "N/A";
  } else {
    if (
      Array.isArray(subscription._planHistory) &&
      subscription._planHistory.length > 0
    ) {
      const sortedPlanHistory = subscription._planHistory.sort((a, b) =>
        a._createdOn < b._createdOn ? 1 : -1
      );
      const latestPlan = sortedPlanHistory[0];
      switch (latestPlan.status) {
        case SUBSCRIPTION_STATUS.ACTIVE:
          color = SUCCESS_COLOR;
          text = latestPlan.term.title;
          break;
        case SUBSCRIPTION_STATUS.INACTIVE:
          color = ERROR_COLOR;
          text = capitalizeFirstLetter(latestPlan.status);
          break;
        case SUBSCRIPTION_STATUS.EXPIRED:
          color = COLOR_GREY;
          if (latestPlan.source === TRANSACTION_SOURCE.FREE) {
            text = "N/A";
          } else {
            text = capitalizeFirstLetter(latestPlan.status);
          }
          break;
        case SUBSCRIPTION_STATUS.REFUNDED:
          text = capitalizeFirstLetter(latestPlan.status);
          break;
      }

      if (
        latestPlan.status === SUBSCRIPTION_STATUS.INACTIVE ||
        latestPlan.status === SUBSCRIPTION_STATUS.EXPIRED
      ) {
        const mappedList = sortedPlanHistory.map(p => p.term.title);

        return (
          <CustomTooltip
            placement="right"
            arrow
            title={<SingleColumnTooltipBody rowArr={mappedList} />}
          >
            <Box component="span" color={color}>
              {text}
            </Box>
          </CustomTooltip>
        );
      }
    }
  }

  return (
    <Box component="span" color={color}>
      {text}
    </Box>
  );
};

export const parseUserStatusHtml = status => {
  switch (status) {
    case USER_STATUS.ACTIVE:
      return USER_STATUS_LABEL.VERIFIED;
    case USER_STATUS.INACTIVE:
      return USER_STATUS_LABEL.UNVERIFIED;
    default:
      return parseCapitalizeHtml(status);
  }
};

export const parseUserEmailHtml = (latestEmail, emailHistory) => {
  if (Array.isArray(emailHistory) && emailHistory.length > 0) {
    const sortedEmailHistory = emailHistory.sort((a, b) =>
      a._registeredOn > b._registeredOn ? 1 : -1
    );
    const mappedEmailHistory = sortedEmailHistory.map(item => [
      item.email,
      parseDateHtml(item._registeredOn),
      item.status === EMAIL_VERIFICATION_STATUS.VERIFIED ? (
        <DoneIcon style={{ color: SUCCESS_COLOR }} />
      ) : null,
    ]);

    const showEmailHistory = sortedEmailHistory.length > 1;
    if (showEmailHistory) {
      return (
        <CustomTooltip
          placement="right"
          arrow
          title={
            <MultipleColumnTooltipBody
              data={mappedEmailHistory}
              columns={EMAIL_HISTORY_COLUMNS}
            />
          }
        >
          <Box component="span">{`${latestEmail} *`}</Box>
        </CustomTooltip>
      );
    } else {
      return latestEmail;
    }
  }

  return "N/A";
};

export const convertMinutesToDaysHours = num => {
  let days = Math.floor(num / 1440);
  let hours = Math.floor((num % 1440) / 60);
  // let minutes = (num % 1440) % 60;

  return `${days} day${days > 1 ? "s" : ""} ${hours} hour${
    hours > 1 ? "s" : ""
  }`;
};
