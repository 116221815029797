import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  receiptContainer: {
    marginTop: 85,
    "@media print": {
      marginTop: 0,
    },
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 750,
    },
    [theme.breakpoints.up("md")]: {
      width: 970,
    },
    [theme.breakpoints.up("lg")]: {
      width: 1170,
    },
  },
  userAddressGrid: {
    "@media print": {
      maxWidth: "66.666667%",
      flexBasis: "66.666667%",
    },
  },
  billingAddressGrid: {
    "@media print": {
      maxWidth: "33.333333%",
      flexBasis: "33.333333%",
    },
  },
  receiptItemTable: {
    width: "100%",
    borderCollapse: "collapse",
  },
  itemTableHead: {
    backgroundColor: "#fafafa",
  },
}));
