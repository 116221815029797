import { CART_ACTION } from "../actions/cartAction";
import { PAYMENT_METHOD_LIST } from "../views/app/Checkout/constants";

const initialState = {
  cartItems: [],
  paypalOrderConfig: null,
  stripeOrderConfig: null,
  isValidToPay: false,
  paymentMethod: PAYMENT_METHOD_LIST[0].value,
};

export const cartReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CART_ACTION.ADD_ITEM:
      const { pricePlan } = payload;
      return {
        ...state,
        cartItems: [pricePlan],
      };
    case CART_ACTION.REMOVE_ITEM:
      return {
        ...state,
        cartItems: state.cartItems.filter(x => x._id !== payload._id),
      };
    case CART_ACTION.SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: payload,
      };
    case CART_ACTION.SET_IS_VALID_TO_PAY:
      return {
        ...state,
        isValidToPay: payload,
      };
    case CART_ACTION.SET_PAYPAL_ORDER_CONFIG:
      return {
        ...state,
        paypalOrderConfig: payload,
      };
    case CART_ACTION.SET_STRIPE_ORDER_CONFIG:
      return {
        ...state,
        stripeOrderConfig: payload,
      };
    case CART_ACTION.CLEAR_PAYMENT_CONFIGS:
      return {
        ...state,
        paypalOrderConfig: null,
        stripeOrderConfig: null,
        isValidToPay: false,
        paymentMethod: PAYMENT_METHOD_LIST[0].value,
      };
    case CART_ACTION.CLEAR:
      return initialState;
    default:
      return state;
  }
};
