import { Dialog, DialogContent } from "@material-ui/core";
import React from "react";
import {
  CustomActionBtn,
  CustomCancelBtn,
  CustomConfirmDialogActions,
} from "../CustomDialogElements";

const ConfirmationModal = props => {
  const { open, onClose, actionFn, ctaTitle, ctaTxt } = props;
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogContent>{ctaTitle}</DialogContent>
      <CustomConfirmDialogActions>
        <CustomActionBtn onClick={actionFn}>{ctaTxt}</CustomActionBtn>
        <CustomCancelBtn onClick={onClose}>Cancel</CustomCancelBtn>
      </CustomConfirmDialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
