import React, { Fragment, useEffect } from "react";
import { Button, MenuItem, TextField } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form/dist/index.ie11";
import Chip from "@material-ui/core/Chip";
import FormHelperText from "@material-ui/core/FormHelperText";
import Box from "@material-ui/core/Box";

import useStyles from "./styles";
import clsx from "clsx";

const CategoryFormModal = ({
  instance,
  visible,
  onSubmit,
  subjects,
  onClose,
  setCategoryName,
}) => {
  const classes = useStyles();

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    control,
    reset,
    errors,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      type: "subject",
      parentIds: [],
      name: "",
    },
  });
  const parentIds = watch("parentIds");
  const categoryType = watch("type");

  const onChangeSelectedCategory = selectedId => {
    if (!parentIds.includes(selectedId)) {
      let tempParentIds = [...parentIds];
      tempParentIds.push(selectedId);
      setValue("parentIds", tempParentIds, {
        shouldValidate: true,
      });
    }
    return;
  };

  const handleDeleteParentCategories = categoryToDelete => {
    const tempParentIds = parentIds.filter(id => id !== categoryToDelete);
    setValue("parentIds", tempParentIds, {
      shouldValidate: true,
    });
  };

  useEffect(() => {
    if (instance) {
      setCategoryName(instance.name);
    } else {
      setCategoryName(undefined);
    }

    reset(
      instance
        ? {
            type: instance.type,
            parentIds: instance.parent_ids ? instance.parent_ids : [],
            name: instance.name,
          }
        : { type: "subject", parentIds: [], name: "" }
    );
  }, [visible, instance]);

  return (
    <Fragment>
      <div className={`modalCont ${visible && "show"}`} onClick={onClose}>
        <div
          className={clsx(
            `modal createCategoryModal ${visible && "show"}`,
            classes.createCategoryModal
          )}
          onClick={e => e.stopPropagation()}
        >
          <div className="titleCont">
            <div className="fTitle">
              {instance ? "Edit" : "Create"} Category
            </div>
          </div>
          <div className="bodyCont">
            <form className="createCategoryForm">
              <Controller
                control={control}
                name="type"
                defaultValue="subject"
                render={({ onChange, onBlur, value, name, ref }) => (
                  <TextField
                    name={name}
                    value={value}
                    label="Type"
                    inputRef={ref}
                    InputProps={{
                      style: { fontSize: 15 },
                    }}
                    InputLabelProps={{
                      style: { fontSize: 15, color: "rgba(0, 0, 0, 0.25)" },
                    }}
                    FormHelperTextProps={{
                      style: { fontSize: 13 },
                    }}
                    onChange={e => onChange(e.target.value)}
                    select
                    color="secondary"
                  >
                    <MenuItem value="subject">Subject</MenuItem>
                    <MenuItem value="system">System</MenuItem>
                  </TextField>
                )}
              />
              <Controller
                control={control}
                name="parentIds"
                rules={{
                  validate: val =>
                    val.length > 0 || categoryType === "subject"
                      ? true
                      : "Parent Categories must not be empty",
                }}
                defaultValue={[]}
                render={() =>
                  categoryType === "system" && (
                    <Fragment>
                      <Box mb={1}>
                        <TextField
                          value=""
                          label="Subject Category"
                          onChange={e =>
                            onChangeSelectedCategory(e.target.value)
                          }
                          InputProps={{
                            style: { fontSize: 15 },
                          }}
                          InputLabelProps={{
                            style: {
                              fontSize: 15,
                              color: "rgba(0, 0, 0, 0.25)",
                            },
                          }}
                          FormHelperTextProps={{
                            style: { fontSize: 13 },
                          }}
                          fullWidth
                          select
                          color="secondary"
                        >
                          <MenuItem value="" disabled>
                            Please choose a subject category
                          </MenuItem>
                          {subjects.map(subject => (
                            <MenuItem value={subject._id} key={subject._id}>
                              {subject.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>

                      <Box py={1} borderBottom={"1px solid #313131"}>
                        <Box
                          style={{ fontSize: 15, color: "rgba(0, 0, 0, 0.25)" }}
                        >
                          Parent Categories
                        </Box>
                        <Box display="flex" width="100%" flexWrap="wrap">
                          {parentIds &&
                            parentIds.length > 0 &&
                            parentIds.map(categoryId => {
                              const found = subjects.find(
                                subject => categoryId === subject._id
                              );
                              if (found) {
                                return (
                                  <Box mt={1} mr={1} key={categoryId}>
                                    <Chip
                                      label={found.name}
                                      onDelete={() => {
                                        handleDeleteParentCategories(
                                          categoryId
                                        );
                                      }}
                                    />
                                  </Box>
                                );
                              }

                              return null;
                            })}
                        </Box>
                      </Box>
                      {Boolean(errors.parentIds) && (
                        <FormHelperText error>
                          {errors.parentIds.message}
                        </FormHelperText>
                      )}
                    </Fragment>
                  )
                }
              />

              <TextField
                name="name"
                label="Name"
                inputRef={register({ required: "Name cannot be empty" })}
                error={!!errors.name}
                helperText={errors.name && errors.name.message}
                InputProps={{
                  style: { fontSize: 15 },
                }}
                InputLabelProps={{
                  style: { fontSize: 15, color: "rgba(0, 0, 0, 0.25)" },
                }}
                FormHelperTextProps={{
                  style: { fontSize: 13 },
                }}
                color="secondary"
              />
            </form>
          </div>
          <div className="actionCont">
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleSubmit(data => onSubmit(instance, data))}
            >
              {instance ? "Update" : "Create"} Category
            </Button>
            <Button variant="outlined" color="default" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CategoryFormModal;
