import { isAfterDatetime, isBeforeDatetime } from ".";

export const checkShouldBlockUser = userInfo => {
  if (userInfo.blockedStartDate && userInfo.blockedEndDate) {
    const today = new Date();
    const blockedStartDate = new Date(userInfo.blockedStartDate);
    const blockedEndDate = new Date(userInfo.blockedEndDate);

    return (
      isBeforeDatetime(blockedEndDate) &&
      isAfterDatetime(today, blockedStartDate)
    );
  } else {
    return false;
  }
};
