import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&$focused": {
      backgroundColor: "transparent",
    },
  },
  input: {
    paddingLeft: 0,
  },
  focused: {},
  formControlLabel: {
    left: "-12px !important",
  },
}));
