import { useState } from "react";
const useModal = () => {
  const [open, setOpen] = useState(false);

  // Handle Confirmtion Modal Start
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // Handle Confirmtion Modal End
  return {
    open,
    handleOpen,
    handleClose,
  };
};

export default useModal;
