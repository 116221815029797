import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DoneIcon from "@material-ui/icons/Done";
import Radio from "@material-ui/core/Radio";

export const CorrectAnsIcon = withStyles(theme => ({
  root: {
    width: "25px",
    height: "25px",
    color: "#66b847",
  },
}))(DoneIcon);

export const CustomRadio = withStyles(theme => ({
  root: {
    "& svg": {
      width: "1.2em",
      height: "1.2em",
    },
  },
}))(Radio);

export const CustomFormControlLabel = withStyles(theme => ({
  label: {
    fontSize: 14,
  },
  disabled: {
    color: `${theme.palette.text.primary} !important`,
  },
}))(FormControlLabel);
