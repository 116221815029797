import React from "react";
import {
  LIST_ITEM_ACTIVE_COLOR,
  YOUR_SCORE_RANK_COLOR,
  MEDIAN_SCORE_RANK_COLOR,
} from "../../styles/constants";
import ordinal from "ordinal";
import { LineChart, Line, XAxis, ResponsiveContainer } from "recharts";

import { bellCurveArr } from "./bellCurveArr";

const textX = x => {
  return x - 12;
};

const CustomizedMedianScoreLabel = props => {
  const { x, value, medianRank, index } = props;

  if (
    value === bellCurveArr[medianRank - 1]["cy"] &&
    index === medianRank - 1
  ) {
    return (
      <g>
        <text
          x={textX(x)}
          y={15}
          dy="-5.13"
          fill={MEDIAN_SCORE_RANK_COLOR}
          style={{ userSelect: "none" }}
        >
          <tspan>{`${ordinal(medianRank)}`}</tspan>
        </text>

        <line
          fill="none"
          stroke={MEDIAN_SCORE_RANK_COLOR}
          strokeWidth="1"
          strokeOpacity="1"
          x1={x}
          x2={x}
          y1={270}
          y2={12}
        ></line>
      </g>
    );
  } else {
    return null;
  }
};
const CustomizedDot = props => {
  const { cx, cy, index, payload, yourScoreRank } = props;

  if (
    payload["cy"] === bellCurveArr[yourScoreRank - 1]["cy"] &&
    index === yourScoreRank - 1
  ) {
    return (
      <g>
        <text
          x={textX(cx)}
          y={cy - 10}
          overflow="hidden"
          dy="-5.13"
          fill={YOUR_SCORE_RANK_COLOR}
          style={{ userSelect: "none" }}
        >
          <tspan>{`${ordinal(yourScoreRank)}`}</tspan>
        </text>
        <circle
          cx={cx}
          cy={cy}
          r="8"
          fill="#ffffff"
          stroke={YOUR_SCORE_RANK_COLOR}
          strokeWidth={1}
        ></circle>

        <circle
          cx={cx}
          cy={cy}
          r="5"
          fill={YOUR_SCORE_RANK_COLOR}
          stroke={YOUR_SCORE_RANK_COLOR}
        ></circle>
      </g>
    );
  } else {
    return null;
  }
};

const PercentileCurve = ({ yourScore, medianScore }) => {
  const rankStat = yourScore.rank ? (
    <CustomizedDot yourScoreRank={yourScore.rank} />
  ) : null;
  const scoreStat = medianScore.rank ? (
    <CustomizedMedianScoreLabel medianRank={medianScore.rank} />
  ) : null;
  return (
    <ResponsiveContainer width={"100%"} height={300}>
      <LineChart data={bellCurveArr} margin={{ top: 30, left: 20, right: 50 }}>
        <XAxis tick={false} stroke={"#d9d7d7"} height={30} />
        <Line
          type="monotone"
          dataKey="cy"
          dot={rankStat}
          label={scoreStat}
          stroke={LIST_ITEM_ACTIVE_COLOR}
          isAnimationActive={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default PercentileCurve;
