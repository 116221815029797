import moment from "moment";
import { toTwoDecimals } from "../../../utils";

// Transform Performance by Date Data
const parsePerformanceByDate = (userExamListData, index) => {
  const userExamsByDate = {};
  // Create an objects that `date` is the key, `array of tests in that specific date` is the value
  userExamListData.forEach(userExam => {
    const dateKey = moment(userExam._createdOn).format("MMM DD YYYY");
    userExamsByDate[dateKey] = userExamsByDate[dateKey]
      ? userExamsByDate[dateKey].concat([userExam])
      : [userExam];
  });

  // Return object of the date and the total score of test(s) in the date and the number of test(s)
  const testScoreSummariesOnDate = Object.keys(userExamsByDate).map(date => {
    const allUserExamsOnDate = userExamsByDate[date];
    // Accumulate score from each test on that date into totalScore
    const totalScoreOnDate = toTwoDecimals(
      allUserExamsOnDate
        .map(
          userExam =>
            (userExam._totalScore / userExam.exam.questions.length) * 100
        )
        .reduce((acc, curr) => acc + curr, 0)
    );
    // Accumulate others average score from each test on that date into totalOthersAvgScoreOnDate
    const totalOthersAvgScoreOnDate = toTwoDecimals(
      allUserExamsOnDate
        .map(userExam => userExam._avgScore)
        .reduce((acc, curr) => acc + curr, 0)
    );
    return {
      date,
      totalScoreOnDate,
      totalOthersAvgScoreOnDate,
      totalUserExamsOnDate: allUserExamsOnDate.length,
    };
  });

  // Initialize cumulative variables
  let cumulativeScore = 0;
  let cumulativeOthersAvgScore = 0;
  let cummulativeTestCount = 0;

  // Return object of the date
  // and the average score of test(s) in the date
  // and cumulative average score of test(s)untill the date
  // and cumulative average others average score of test(s)untill the date
  const performanceByDateData = testScoreSummariesOnDate.map(scoreData => {
    // Accumulate yourScore, others average score and number of tests
    cumulativeScore += scoreData.totalScoreOnDate;
    cumulativeOthersAvgScore += scoreData.totalOthersAvgScoreOnDate;
    cummulativeTestCount += scoreData.totalUserExamsOnDate;

    // Calculate average by dividing the number of tests
    const yourAverageScore = toTwoDecimals(
      scoreData.totalScoreOnDate / scoreData.totalUserExamsOnDate
    );

    // Calculate average by dividing the cumulative number of tests
    const cumulativeAverageScore = toTwoDecimals(
      cumulativeScore / cummulativeTestCount
    );
    const cumulativeAverageOthersAvgScore = toTwoDecimals(
      cumulativeOthersAvgScore / cummulativeTestCount
    );

    return {
      date: scoreData.date,
      yourAverageScore,
      cumulativeAverageScore,
      cumulativeAverageOthersAvgScore,
    };
  });
  return performanceByDateData;
};

export { parsePerformanceByDate };
