export const hightlightParser = (keyword, colsArr) => {
  return keyword
    ? colsArr.map(col => {
        const escaped = RegExp.escape(keyword);
        if (typeof col === "object") {
          const newObj = col;

          newObj.text.replace(
            new RegExp(escaped, "gi"),
            match =>
              `<font style="background-color: yellow; color:blue">${match}</font>`
          );
          return newObj;
        }
        return col.replace(
          new RegExp(escaped, "gi"),
          match =>
            `<font style="background-color: yellow; color:blue">${match}</font>`
        );
      })
    : colsArr;
};
