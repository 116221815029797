export const headCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "subjects",
    label: "SUBJECTS",
  },
  {
    id: "systems",
    label: "SYSTEMS",
  },
  {
    id: "correctAnswerPercentage",
    label: "% CORRECT OTHERS",
  },
  {
    id: "actions",
    label: "ACTIONS",
  },
];
