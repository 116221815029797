import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./styles";

const useStyles = makeStyles(theme => styles(theme));

const ProgressBar = props => {
  const { progressWidth } = props;
  const classes = useStyles();

  return (
    <div className={classes.barContainer}>
      <div
        className={clsx(classes.progressBar, classes.blueBG)}
        style={{
          width: progressWidth,
        }}
      ></div>
    </div>
  );
};

export default ProgressBar;
