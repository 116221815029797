import * as ActionTypes from "../actions/signupAction";

const { CHECK_USER_EXISTS, CLEAR_CHECK_USER_EXISTS } = ActionTypes;

export const initialState = {
  checkUserExists: null,
};

const signupReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CHECK_USER_EXISTS.SUCCESS: {
      return {
        ...state,
        checkUserExists: payload,
      };
    }
    case CLEAR_CHECK_USER_EXISTS: {
      return {
        ...state,
        checkUserExists: null,
      };
    }

    default:
      return state;
  }
};

export default signupReducer;
