import React, { useState, useEffect, Fragment } from "react";
import clsx from "clsx";
import { useForm } from "react-hook-form/dist/index.ie11";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Rnd } from "react-rnd";

import {
  uworldCloseIcon,
  uworldDeleteNotesBtn,
  uworldSaveCloseBtn,
  restoreWindowIcon,
} from "../../../../../resources/assets";
import styles from "../styles";
import {
  CustomConfirmDialogActions,
  CustomActionBtn,
  CustomCancelBtn,
} from "../../../../../Components/CustomDialogElements";

const useStyles = makeStyles(theme => styles(theme));

// Constants
const DEFAULT_SIZE = { width: "400px", height: "400px" };
const DEFAULT_SM_SIZE = { width: "320px", height: "400px" };
const DEFAULT_XS_SIZE = { width: "280px", height: "400px" };

const MAX_SIZE = { width: 600, height: 600 };
const MIN_SIZE = { width: 280, height: 300 };

const NoteModal = props => {
  const { visible, isDarkMode, instance, onSubmit, onRemove, onClose } = props;
  const classes = useStyles();
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const y = window.innerHeight - 467;
  const DEFAULT_POSITION = {
    x: window.innerWidth - 550,
    y,
  };
  const DEFAULT_SM_POSITION = {
    x: window.innerWidth - 320,
    y,
  };

  const DEFAULT_XS_POSITION = {
    x: window.innerWidth > 300 ? window.innerWidth - 300 : 0,
    y,
  };
  const PRESET_SIZE = xsDown
    ? DEFAULT_XS_SIZE
    : smDown
    ? DEFAULT_SM_SIZE
    : DEFAULT_SIZE;
  const PRESET_POSITION = xsDown
    ? DEFAULT_XS_POSITION
    : smDown
    ? DEFAULT_SM_POSITION
    : DEFAULT_POSITION;

  const { register, handleSubmit, reset, errors, watch } = useForm({
    mode: "onChange",
    defaultValues: {
      content: instance ? instance.content : "",
    },
  });
  const watchContent = watch("content");

  const [size, setSize] = useState(PRESET_SIZE);
  const [position, setPosition] = useState(PRESET_POSITION);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUnsavedWarningModal, setShowUnsavedWarningModal] = useState(false);

  useEffect(() => {
    setPosition(PRESET_POSITION);
  }, [xsDown, smDown]);

  // Handle Confirmtion Modal Start
  const handleOpenDeleteModal = event => {
    if (instance) {
      setShowDeleteModal(true);
    } else {
      //Close Note modal
      onClose();
    }
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleCloseUnsavedWarningModal = () => {
    setShowUnsavedWarningModal(false);
  };

  const handleOpenUnsavedWarningModal = () => {
    setShowUnsavedWarningModal(true);
  };

  const onCloseAllModal = () => {
    handleCloseUnsavedWarningModal();
    onClose();
  };
  // Handle Confirmtion Modal End

  // Handle Actions Button Start
  const onSaveNote = () => {
    handleSubmit(data => {
      onSubmit(data, instance);
    })();
    onClose();
  };

  const onRemoveNote = () => {
    onRemove(instance);
    handleCloseDeleteModal();
    onClose();
  };

  const onRestoreWindowSize = () => {
    setSize(PRESET_SIZE);
  };

  const onClickCloseBtn = () => {
    const initialContent = instance ? instance.content : "";

    if (watchContent !== initialContent) {
      handleOpenUnsavedWarningModal();
    } else {
      onClose();
    }
  };
  // Handle Actions Button End

  const getTextAreaRows = height => {
    if (height > 590 && height <= 600) {
      return 28;
    } else if (height > 580 && height <= 590) {
      return 27;
    } else if (height > 550 && height <= 580) {
      return 26;
    } else if (height > 550 && height <= 575) {
      return 25;
    } else if (height > 525 && height <= 550) {
      return 24;
    } else if (height > 510 && height <= 525) {
      return 23;
    } else if (height > 500 && height <= 510) {
      return 22;
    } else if (height > 475 && height <= 500) {
      return 21;
    } else if (height > 460 && height <= 475) {
      return 20;
    } else if (height > 450 && height <= 460) {
      return 19;
    } else if (height > 425 && height <= 450) {
      return 18;
    } else if (height > 400 && height <= 425) {
      return 17;
    } else if (height > 390 && height <= 400) {
      return 16;
    } else if (height > 375 && height <= 390) {
      return 15;
    } else if (height > 350 && height <= 375) {
      return 14;
    } else if (height > 340 && height <= 350) {
      return 13;
    } else if (height > 325 && height <= 340) {
      return 12;
    } else if (height > 310 && height <= 325) {
      return 11;
    } else if (height >= 300 && height <= 310) {
      return 10;
    }
  };

  useEffect(() => {
    reset({ content: instance ? instance.content : "" });
  }, [instance]);

  // Reset position when note modal mounted/unmounted
  useEffect(() => {
    setSize(PRESET_SIZE);
    setPosition(PRESET_POSITION);
  }, [visible]);

  if (!visible) return null;

  return (
    <Fragment>
      {/* Delete Note Confirmation Modal */}
      <Dialog onClose={handleCloseDeleteModal} open={showDeleteModal}>
        <DialogContent>
          Are you sure you wish to delete this note?
        </DialogContent>
        <CustomConfirmDialogActions>
          <CustomActionBtn onClick={onRemoveNote}>Delete</CustomActionBtn>
          <CustomCancelBtn onClick={handleCloseDeleteModal}>
            Cancel
          </CustomCancelBtn>
        </CustomConfirmDialogActions>
      </Dialog>

      {/* Unsaved Warning Modal */}
      <Dialog
        onClose={handleCloseUnsavedWarningModal}
        open={showUnsavedWarningModal}
      >
        <DialogContent>
          Any unsaved notes will be lost. Do you really wish to close the notes?
        </DialogContent>
        <CustomConfirmDialogActions>
          <CustomActionBtn onClick={onCloseAllModal}>Yes</CustomActionBtn>
          <CustomCancelBtn onClick={handleCloseUnsavedWarningModal}>
            No
          </CustomCancelBtn>
        </CustomConfirmDialogActions>
      </Dialog>

      {/* Note Modal */}
      <Rnd
        className={classes.zIndex1}
        bounds={"window"}
        minHeight={MIN_SIZE.height}
        minWidth={MIN_SIZE.width}
        maxHeight={MAX_SIZE.height}
        maxWidth={MAX_SIZE.width}
        size={{ width: size.width, height: size.height }}
        position={{ x: position.x, y: position.y }}
        onDragStop={(e, d) => {
          setPosition({ x: d.x, y: d.y });
        }}
        onResize={(e, direction, ref, delta, position) => {
          setSize({ width: ref.style.width, height: ref.style.height });
          setPosition({
            ...position,
          });
        }}
        enableUserSelectHack={false}
      >
        <div
          className={clsx(
            isDarkMode ? classes.modalContDark : classes.modalContLight,
            classes.modalCont
          )}
        >
          <div className={classes.header}>
            <div></div>
            <span className={classes.headerTitle}>Edit Item Notes</span>
            <div className={classes.dFlex}>
              <div>
                <img
                  src={restoreWindowIcon}
                  alt="Restore window icon"
                  className={classes.restoreIcon}
                  onClick={onRestoreWindowSize}
                />
              </div>

              <div
                style={{ backgroundImage: `url(${uworldCloseIcon})` }}
                className={classes.closeIcon}
                onClick={onClickCloseBtn}
              />
            </div>
          </div>
          <div className={classes.formCont}>
            <TextField
              id="content"
              name="content"
              inputRef={register({ required: "Note cannot be empty" })}
              variant="outlined"
              error={!!errors.content}
              helperText={errors.content && errors.content.message}
              InputProps={{
                classes: {
                  root: clsx(
                    isDarkMode
                      ? classes.textAreaRootDark
                      : classes.textAreaRootLight,
                    classes.textAreaRoot
                  ),
                  focused: classes.focused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              InputLabelProps={{
                style: { fontSize: 15, color: "rgba(0, 0, 0, 0.25)" },
              }}
              FormHelperTextProps={{
                style: { fontSize: 13 },
              }}
              multiline
              fullWidth
              rows={getTextAreaRows(Number(size.height.substr(0, 3)))}
            />
          </div>

          <div className={classes.actionCont}>
            <button
              className={classes.saveBtn}
              style={{ backgroundImage: `url(${uworldSaveCloseBtn})` }}
              onClick={onSaveNote}
            ></button>

            <button
              className={classes.deleteBtn}
              style={{ backgroundImage: `url(${uworldDeleteNotesBtn})` }}
              onClick={handleOpenDeleteModal}
            ></button>
          </div>
        </div>
      </Rnd>
    </Fragment>
  );
};

export default NoteModal;
