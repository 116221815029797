import { CATEGORY_TYPE } from "../../../constants";

export const categoriesArr = [
  {
    key: CATEGORY_TYPE.SYSTEM,
    title: "By System",
  },
  {
    key: CATEGORY_TYPE.SUBJECT,
    title: "By Subject",
  },
];

export const sortCategories = data => {
  return data.sort((a, b) => {
    return a.name > b.name ? 1 : -1;
  });
};

export const sortTopics = data => {
  return data.sort((a, b) => {
    return a.title > b.title ? 1 : -1;
  });
};
