import clsx from "clsx";
import React from "react";
import { CURRENCY_FORMAT } from "../../../constants";
import useStyles from "./styles";

const ReceiptItemTable = ({ transaction }) => {
  const classes = useStyles();
  const orderTotal = `${CURRENCY_FORMAT}${
    Number(transaction.term.price) ? transaction.term.price.toFixed(2) : "0.00"
  }`;

  return (
    <table
      className={clsx("table table-bordered", classes.receiptItemTable)}
      cellSpacing="0"
      cellPadding="0"
      rules="all"
      border="1"
      id="gvorders"
    >
      <tbody>
        <tr>
          <th className={classes.itemTableHead} scope="col">
            Item Description
          </th>
          <th className={classes.itemTableHead} scope="col">
            Price (USD)
          </th>
        </tr>
        <tr>
          <td>1. {transaction.term.title}</td>
          <td style={{ width: 135 }}>
            <div>{orderTotal}</div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ReceiptItemTable;
