import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import PrimaryTextField from "../../../../Components/PrimaryTextField";
import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
} from "@material-ui/core";
import { changePassword } from "../../../../actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import { parseAuthToken } from "../../../../utils";
import { PrimaryButton } from "../../../../Components/CustomButton";
import { selectUserChangePasswordStatus } from "../../../../selectors/user";
import { REQUEST_STATUS } from "../../../../constants";
import {
  CustomCancelBtn,
  CustomConfirmDialogActions,
  CustomPrimaryActionBtn,
} from "../../../../Components/CustomDialogElements";

const ChangePasswordForm = () => {
  const dispatch = useDispatch();
  const { authToken } = useSelector(state => state.UserStore);
  const status = useSelector(selectUserChangePasswordStatus);
  const { handleSubmit, control, reset, errors, getValues } = useForm({
    mode: "onChange",
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  // Handle Confirmtion Modal Start
  const handleOpenChangePasswordModal = event => {
    setShowChangePasswordModal(true);
  };

  const handleCloseChangePasswordModal = () => {
    setShowChangePasswordModal(false);
  };
  // Handle Confirmtion Modal End
  const inputType = useMemo(() => (showPassword ? "text" : "password"), [
    showPassword,
  ]);

  const onSubmit = data => {
    const user_id = parseAuthToken(authToken);
    const inputData = {
      input: { user_id, ...data, confirmPassword: undefined },
    };
    dispatch(changePassword(inputData));
    handleCloseChangePasswordModal();
  };

  const handleResetFields = useCallback(() => {
    reset({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  }, [reset]);

  const handleChangeShowPassword = event => {
    setShowPassword(event.target.checked);
  };

  useEffect(() => {
    if (status === REQUEST_STATUS.SUCCESS) {
      handleResetFields();
    }
  }, [status, handleResetFields]);

  return (
    <>
      <Dialog
        onClose={handleCloseChangePasswordModal}
        open={showChangePasswordModal}
      >
        <DialogContent>
          Are you sure you wish to change your password?
        </DialogContent>
        <CustomConfirmDialogActions>
          <CustomPrimaryActionBtn onClick={handleSubmit(onSubmit)}>
            Update
          </CustomPrimaryActionBtn>
          <CustomCancelBtn onClick={handleCloseChangePasswordModal}>
            Cancel
          </CustomCancelBtn>
        </CustomConfirmDialogActions>
      </Dialog>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="currentPassword"
              rules={{ required: "Required" }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="Current or Temporary Password"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.currentPassword)}
                  helperText={
                    errors.currentPassword && errors.currentPassword.message
                  }
                  inputProps={{ autoComplete: "new-password" }}
                  type={inputType}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="newPassword"
              rules={{
                required: "Required",
                minLength: { value: 8, message: "Minimum 8 characters" },
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="New Password"
                  placeholder="New Password (minimum 8 characters)"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.newPassword)}
                  helperText={errors.newPassword && errors.newPassword.message}
                  type={inputType}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="confirmPassword"
              rules={{
                required: "Required",
                validate: value =>
                  value === getValues("newPassword") ||
                  "Confirm password does not match with password",
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="Confirm Password"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.confirmPassword)}
                  helperText={
                    errors.confirmPassword && errors.confirmPassword.message
                  }
                  type={inputType}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showPassword}
                  onChange={handleChangeShowPassword}
                  color="primary"
                />
              }
              label="Show Password"
            />
          </Grid>
          <Grid item xs={12}>
            <Box mt={3}>
              <PrimaryButton
                color="primary"
                variant="contained"
                onClick={handleOpenChangePasswordModal}
                fullWidth
              >
                Update
              </PrimaryButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default ChangePasswordForm;
