import React from "react";
import { Box } from "@material-ui/core";

const MUICustomHeader = ({ children, label = "" }) => (
  <th
    className="MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-92 MUIDataTableHeadCell-fixedHeader-93"
    scope="col"
    data-colindex="1"
    key={label}
  >
    <span className="MUIDataTableHeadCell-contentWrapper-102">
      <span className="MuiButton-label">
        <div
          className="MUIDataTableHeadCell-sortAction-97"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className="MUIDataTableHeadCell-data-96">
            <Box display="flex" alignItems="center">
              {children}
            </Box>
          </div>
        </div>
      </span>
    </span>
  </th>
);
export default MUICustomHeader;
