import { makeStyles } from "@material-ui/styles";
import { COLOR_WHITE } from "styles/constants";

export default makeStyles(theme => ({
  planBoxContainer: {
    position: "relative",
    borderRadius: 20,
    backgroundColor: theme.palette.primary.main,
    height: 230,
    marginBottom: theme.spacing(7),
  },
  priceBoxContainer: {
    position: "absolute",
    backgroundColor: COLOR_WHITE,
    boxShadow: "0px 0px 20px 5px rgba(0, 0, 0, 0.08)",
    borderRadius: 10,
    height: 90,
    width: "80%",
    top: "78%",
    left: "10%",
    zIndex: 1,
    cursor: "pointer",
  },
  titleLine: {
    width: 155,
    borderTop: `5px solid ${theme.palette.primary.main}`,
  },
  arrowIcon: {
    width: 12.5,
    height: 25,
    marginBottom: theme.spacing(0.5),
  },
}));
