import React from "react";
import { SUBSCRIPTION_STATUS } from "../../../constants";
import { formatDateWithDefaultTimeZone } from "../../../utils";

const PLAN_HISTORY_TIME_TITLE = {
  EXPIRES: "Expires",
  EXPIRED: "Expired",
  VALIDITY: "Validity",
  REFUNDS: "Refunded",
};

export const parsePlanHistoryTimeText = planHistory => {
  const { status, _expiredOn, term, _refundedOn } = planHistory;
  let title;
  let subTitle;

  switch (status) {
    case SUBSCRIPTION_STATUS.ACTIVE:
      title = PLAN_HISTORY_TIME_TITLE.EXPIRES;
      subTitle = `${formatDateWithDefaultTimeZone(_expiredOn)}`;
      break;
    case SUBSCRIPTION_STATUS.EXPIRED:
      title = PLAN_HISTORY_TIME_TITLE.EXPIRED;
      subTitle = `${formatDateWithDefaultTimeZone(_expiredOn)}`;
      break;
    case SUBSCRIPTION_STATUS.INACTIVE:
      title = PLAN_HISTORY_TIME_TITLE.VALIDITY;
      subTitle = (
        <>
          <strong>{`${term.term} ${term.unit}${
            term.term > 1 ? "s" : ""
          }`}</strong>{" "}
          after activation
        </>
      );
      break;
    case SUBSCRIPTION_STATUS.REFUNDED:
      title = PLAN_HISTORY_TIME_TITLE.REFUNDS;
      subTitle = `${formatDateWithDefaultTimeZone(_refundedOn)}`;
      break;
    default:
      title = "";
      subTitle = "";
      break;
  }

  return { title, subTitle };
};
