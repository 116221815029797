import React from "react";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form/dist/index.ie11";

import PrimaryTextField from "../../PrimaryTextField";
import { resetPassword } from "../../../actions/userAction";
import { Box, Grid } from "@material-ui/core";
import { PrimaryButton } from "../../CustomButton";
import usePath from "../../../hooks/usePath";
import VisitorFormTitle from "../../../views/auth/components/VisitorFormTitle";

const ResetPasswordForm = () => {
  const dispatch = useDispatch();
  const { queryParams } = usePath();
  const { handleSubmit, control, reset, errors, watch } = useForm({
    mode: "onChange",
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });
  let pwd = watch("password");

  const onSubmit = data => {
    if (queryParams) {
      const { id = null, resetCode = null } = queryParams;
      if (id && resetCode) {
        const input = {
          _id: id,
          resetCode,
          password: data.password,
        };
        dispatch(resetPassword({ input }));
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <VisitorFormTitle title="Reset Password" />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="password"
          rules={{
            required: "Password cannot be empty",
            minLength: { value: 8, message: "Minimum 8 characters" },
          }}
          render={({ onChange, _onBlur, value, name, ref }) => (
            <PrimaryTextField
              label="New Password"
              placeholder="New Password (minimum 8 characters)"
              name={name}
              onChange={e => {
                onChange(e.target.value);
              }}
              value={value}
              inputRef={ref}
              error={Boolean(errors.password)}
              helperText={errors.password && errors.password.message}
              type="password"
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="confirmPassword"
          rules={{
            required: "Required",
            validate: value =>
              value === pwd || "Confirm password does not match with password",
          }}
          render={({ onChange, onBlur, value, name, ref }) => (
            <PrimaryTextField
              label="Confirm Password"
              name={name}
              onChange={e => {
                onChange(e.target.value);
              }}
              value={value}
              inputRef={ref}
              error={Boolean(errors.confirmPassword)}
              helperText={
                errors.confirmPassword && errors.confirmPassword.message
              }
              type="password"
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" mt={3} width="100%">
          <PrimaryButton
            color="primary"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            fullWidth
          >
            Submit
          </PrimaryButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ResetPasswordForm;
