import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form/dist/index.ie11";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReply } from "@fortawesome/free-solid-svg-icons";
import DateFnsUtils from "@date-io/date-fns";

import { MANAGE_DISCOUNT_CODE_URL } from "../../../../routes/constants";
import {
  createVoucher,
  getVoucherById,
} from "../../../../actions/voucherAction";
import FormContainer from "../../../../Components/Layout/FormContainer";
import useStyles from "./styles";
import {
  DISCOUNT_AUDIENCE_TYPE,
  DISCOUNT_INCLUDED_PLAN_TYPE,
  RESOURCE_STATUS,
  validateDate,
} from "../../../../constants";
import { SELECT_INPUT_WIDTH, textFieldProps } from "../../helper";
import {
  DISCOUNT_AUDIENCE_TYPE_LIST,
  DISCOUNT_INCLUDED_PLAN_TYPE_LIST,
  DISCOUNT_MODE_LIST,
  DISCOUNT_UNIT_LIST,
} from "../constants";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { convertDateGMT_Plus08ToPlus10 } from "../../../../utils";
import { Autocomplete } from "@material-ui/lab";
import { selectUserList } from "../../../../selectors/user";
import { getUserListForVoucherForm } from "../../../../actions/userAction";
import { selectPlanList } from "../../../../selectors/plan";
import { getPlans } from "../../../../actions/planAction";
import { voucherAdapter } from "./helper";

const CreateVoucherForm = () => {
  const classes = useStyles();
  const { handleSubmit, control, reset, errors, watch, getValues } = useForm({
    mode: "onChange",
    defaultValues: {
      code: "",
      unit: "",
      mode: "",
      discount: 0,
      audienceType: "",
      audienceIds: [],
      includedPlanType: "",
      pricePlanIds: [],
      limit: 0,
    },
  });
  const [expiredDate, setExpiredDate] = useState(null);
  const validateExpiredDate = validateDate(expiredDate, "Expired date");
  const dispatch = useDispatch();
  const users = useSelector(selectUserList).filter(
    user => user.status !== RESOURCE_STATUS.DELETED
  );
  const plans = useSelector(selectPlanList);
  const pricePlans =
    Array.isArray(plans) && plans.length > 0 && plans[0].price
      ? plans[0].price
      : [];

  const audienceType = watch("audienceType");
  const includedPlanType = watch("includedPlanType");

  useEffect(() => {
    dispatch(getUserListForVoucherForm());
    dispatch(getPlans());
  }, [dispatch]);

  const onSubmitCreate = rawData => {
    const data = voucherAdapter({ ...rawData, expiredDate });

    dispatch(createVoucher(data));
  };

  return (
    <FormContainer>
      <Link to={MANAGE_DISCOUNT_CODE_URL}>
        <FontAwesomeIcon icon={faReply} />
        Back to discount code list
      </Link>

      <div>
        <h3>Create Discount Code</h3>
        <form onSubmit={handleSubmit(onSubmitCreate)}>
          <Controller
            control={control}
            name="code"
            rules={{
              required: "Required",
            }}
            render={({ onChange, onBlur, value, name, ref }) => (
              <TextField
                name={name}
                label="Code"
                inputRef={ref}
                value={value}
                onChange={e => onChange(e.target.value)}
                error={!!errors.code}
                helperText={errors.code && errors.code.message}
                {...textFieldProps}
              />
            )}
          />
          <Box display="flex" alignItems="flex-end">
            <Box mr={1}>
              <Controller
                control={control}
                name="discount"
                rules={{
                  required: "Required",
                }}
                render={({ onChange, onBlur, value, name, ref }) => (
                  <TextField
                    name={name}
                    label="Discount"
                    inputRef={ref}
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    error={!!errors.discount}
                    helperText={errors.discount && errors.discount.message}
                    variant="filled"
                    type="number"
                    {...textFieldProps}
                    InputProps={{
                      classes: { ...classes, formControlLabel: undefined },
                      style: { fontSize: 15 },
                    }}
                    InputLabelProps={{
                      classes: {
                        formControl: classes.formControlLabel,
                      },
                    }}
                    style={{ width: 110 }}
                  />
                )}
              />
            </Box>
            <Box>
              <Controller
                control={control}
                name="unit"
                rules={{
                  required: "Required",
                }}
                render={({ onChange, onBlur, value, name, ref }) => (
                  <TextField
                    name={name}
                    label="Unit"
                    inputRef={ref}
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    error={!!errors.unit}
                    helperText={errors.unit && errors.unit.message}
                    select
                    {...textFieldProps}
                    style={{ width: 100 }}
                  >
                    <MenuItem value={null} disabled>
                      None
                    </MenuItem>
                    {DISCOUNT_UNIT_LIST.map(option => (
                      <MenuItem value={option.value} key={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Box>
          </Box>
          <Controller
            control={control}
            name="mode"
            rules={{
              required: "Required",
            }}
            render={({ onChange, onBlur, value, name, ref }) => (
              <TextField
                name={name}
                label="Discount mode"
                inputRef={ref}
                value={value}
                onChange={e => onChange(e.target.value)}
                error={!!errors.mode}
                helperText={errors.mode && errors.mode.message}
                select
                {...textFieldProps}
              >
                <MenuItem value={null} disabled>
                  None
                </MenuItem>
                {DISCOUNT_MODE_LIST.map(option => (
                  <MenuItem value={option.value} key={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
              margin="normal"
              value={expiredDate}
              label="Expired Date (GMT+10)"
              format="yyyy-MM-dd hh:mm a"
              onChange={date => {
                setExpiredDate(date);
              }}
              error={Boolean(validateExpiredDate)}
              helperText={validateExpiredDate}
              style={{ maxWidth: SELECT_INPUT_WIDTH }}
              color="secondary"
              InputLabelProps={{ shrink: true }}
            />
          </MuiPickersUtilsProvider>
          <Controller
            control={control}
            name="audienceType"
            rules={{
              required: "Required",
            }}
            render={({ onChange, onBlur, value, name, ref }) => (
              <>
                <Box mt={1}>
                  <FormControl
                    component="fieldset"
                    error={Boolean(errors.audienceType)}
                  >
                    <FormLabel color="secondary">Limit to user</FormLabel>
                    <RadioGroup
                      aria-label="audienceType"
                      name={name}
                      value={value}
                      onChange={onChange}
                      row
                    >
                      {DISCOUNT_AUDIENCE_TYPE_LIST.map(item => (
                        <FormControlLabel
                          key={item.value}
                          value={item.value}
                          control={<Radio />}
                          label={item.label}
                        />
                      ))}
                    </RadioGroup>
                    {errors.audienceType && (
                      <FormHelperText>
                        {errors.audienceType.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </>
            )}
          />
          {audienceType === DISCOUNT_AUDIENCE_TYPE.CUSTOM && (
            <Controller
              control={control}
              name="audienceIds"
              render={({ onChange, onBlur, value, name, ref }) => (
                <Autocomplete
                  multiple
                  value={
                    Array.isArray(value) && value.length > 0
                      ? value.map(item => {
                          if (typeof item === "string") {
                            const audience = users.find(
                              user => user._id === item
                            );

                            return audience;
                          }

                          return item;
                        })
                      : []
                  }
                  options={users}
                  getOptionLabel={option => (option ? option.email : "")}
                  onChange={(e, val) => {
                    onChange(val);
                  }}
                  noOptionsText="No users"
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Users"
                      color="secondary"
                    />
                  )}
                />
              )}
            />
          )}
          <Controller
            control={control}
            name="includedPlanType"
            rules={{
              required: "Required",
            }}
            render={({ onChange, onBlur, value, name, ref }) => (
              <>
                <Box mt={2}>
                  <FormControl
                    component="fieldset"
                    error={Boolean(errors.includedPlanType)}
                  >
                    <FormLabel color="secondary">Limit to plan</FormLabel>
                    <RadioGroup
                      aria-label="includedPlanType"
                      name={name}
                      value={value}
                      onChange={onChange}
                      row
                    >
                      {DISCOUNT_INCLUDED_PLAN_TYPE_LIST.map(item => (
                        <FormControlLabel
                          key={item.value}
                          value={item.value}
                          control={<Radio />}
                          label={item.label}
                        />
                      ))}
                    </RadioGroup>
                    {errors.includedPlanType && (
                      <FormHelperText>
                        {errors.includedPlanType.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </>
            )}
          />
          {includedPlanType === DISCOUNT_INCLUDED_PLAN_TYPE.CUSTOM && (
            <Controller
              control={control}
              name="pricePlanIds"
              render={({ onChange, onBlur, value, name, ref }) => (
                <Autocomplete
                  multiple
                  value={
                    Array.isArray(value) && value.length > 0
                      ? value.map(item => {
                          if (typeof item === "string") {
                            const pricePlan = pricePlans.find(
                              pricePlan => pricePlan._id === item
                            );

                            return pricePlan;
                          }

                          return item;
                        })
                      : []
                  }
                  options={pricePlans}
                  getOptionLabel={option => (option ? option.title : "")}
                  onChange={(e, val) => {
                    onChange(val);
                  }}
                  noOptionsText="No plans"
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Plans"
                      color="secondary"
                    />
                  )}
                />
              )}
            />
          )}

          <Controller
            control={control}
            name="limit"
            rules={{
              required: "Required",
            }}
            render={({ onChange, onBlur, value, name, ref }) => (
              <TextField
                name={name}
                label="Limit Usage"
                inputRef={ref}
                value={value}
                onChange={e => onChange(e.target.value)}
                error={!!errors.limit}
                helperText={errors.limit && errors.limit.message}
                variant="filled"
                type="number"
                {...textFieldProps}
                InputProps={{
                  classes: { ...classes, formControlLabel: undefined },
                  style: { fontSize: 15 },
                }}
                InputLabelProps={{
                  classes: {
                    formControl: classes.formControlLabel,
                  },
                }}
                style={{ width: 110 }}
              />
            )}
          />
        </form>
      </div>
      <Button
        onClick={handleSubmit(onSubmitCreate)}
        color="secondary"
        variant="contained"
        className="submitButton"
        size="large"
        style={{ marginTop: 40 }}
      >
        Create
      </Button>
    </FormContainer>
  );
};

export default CreateVoucherForm;
