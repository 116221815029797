import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  historyContainer: {
    backgroundColor: "#ededed",
    padding: theme.spacing(2),
    marginBottom: 15,
    borderRadius: 6,
    border: "1px solid #dbdbdb",
  },
  greyTxt: {
    color: "#818181",
    fontWeight: 600,
  },
  viewReceiptBtn: {
    maxWidth: 140,
  },
}));
