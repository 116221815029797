import React, { Fragment, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";

import * as ExamActions from "../../actions/examAction";
import QuestionInfoModal from "./modal/QuestionInfoModal";
import { parseDateHtml, parseQuestionHtml } from "../../utils";
import {
  CustomDeleteIcon,
  CustomEditIcon,
  CustomViewIcon,
} from "../../Components/CustomActionIcons";
import { COURSE_APP_SUB_ROUTE } from "../../routes/constants";
import { parseBooleanValueHTML } from "./helper";
import useModal from "../../hooks/useModal";
import ConfirmationModal from "../../Components/Modal/ConfirmationModal";

const ManageQuestion = ({ history, getQuestionList, removeQuestion }) => {
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(undefined);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const { open, handleOpen, handleClose } = useModal();

  const fetchQuestionList = async () => {
    const res = await getQuestionList();

    if (res && res.questions) setQuestions(res.questions);
  };

  useEffect(() => {
    fetchQuestionList();
  }, []);

  const handleRemoveQuestion = async () => {
    const data = await removeQuestion(selectedQuestion._id);
    if (data) {
      setQuestions(questions =>
        questions.filter(question => question._id !== selectedQuestion._id)
      );
    }
    handleClose();
  };

  return (
    <Fragment>
      <ConfirmationModal
        open={open}
        onClose={handleClose}
        actionFn={handleRemoveQuestion}
        ctaTitle={"Are you wish to delete this question?"}
        ctaTxt={"Delete"}
      />
      <div className="pageCont manageQuestionPage">
        <div className="bodyCont">
          <div className="pageAction">
            <div className="pageActionRight">
              <Button
                variant="contained"
                color="secondary"
                onClick={() =>
                  history.push(`${COURSE_APP_SUB_ROUTE}/manage-question/create`)
                }
              >
                Create Question
              </Button>
            </div>
          </div>
          <div className="pageContent">
            <MUIDataTable
              data={questions.map(question => [
                question._ref,
                question._createdOn,
                question.question,
                question.category && question.category.name
                  ? question.category.name
                  : "",
                question.subCategory && question.subCategory.name
                  ? question.subCategory.name
                  : "",
                question.trial,
              ])}
              columns={[
                "ID",
                {
                  name: "Date Created",
                  options: {
                    customBodyRenderLite: dataIndex => {
                      let val = questions[dataIndex]._createdOn;
                      return parseDateHtml(val);
                    },
                    setCellHeaderProps: () => ({
                      className: "globalCustomMuiTableHead",
                    }),
                  },
                },
                {
                  name: "Question",
                  options: {
                    customBodyRender: value => (
                      <div style={{ width: 270, wordWrap: "break-word" }}>
                        {parseQuestionHtml(value)}
                      </div>
                    ),
                  },
                },
                "Subject",
                "System",
                {
                  name: "Trial",
                  options: {
                    customBodyRenderLite: dataIndex => {
                      let val = questions[dataIndex].trial;
                      return parseBooleanValueHTML(val);
                    },
                  },
                },
                {
                  name: "Action",
                  options: {
                    customBodyRenderLite: dataIndex => (
                      <div className="actionDiv">
                        <CustomViewIcon
                          onClickHandler={() => {
                            setSelectedQuestion(questions[dataIndex]);
                            setShowInfoModal(show => !show);
                          }}
                        />
                        <CustomEditIcon
                          onClickHandler={() => {
                            history.push(
                              `${COURSE_APP_SUB_ROUTE}/manage-question/${questions[dataIndex]._id}/edit`
                            );
                          }}
                          style={{ marginLeft: 8 }}
                        />
                        <CustomDeleteIcon
                          onClickHandler={() => {
                            setSelectedQuestion(questions[dataIndex]);
                            handleOpen();
                          }}
                          style={{ marginLeft: 8 }}
                        />
                      </div>
                    ),
                  },
                },
              ]}
              options={{
                download: false,
                print: false,
                selectableRows: "none",
                // onRowsDelete: (rowsDeleted, data, newTableData) => {
                //   (async () => {
                //     const deletedIds = [];
                //     await Promise.all(
                //       rowsDeleted.data.map(async ({ dataIndex }) => {
                //         const data = await removeQuestion(
                //           questions[dataIndex]._id
                //         );
                //         if (data) {
                //           deletedIds.push(questions[dataIndex]._id);
                //         }
                //       })
                //     );

                //     setQuestions(questions =>
                //       questions.filter(
                //         question => !deletedIds.includes(question._id)
                //       )
                //     );
                //   })();

                //   return false;
                // },
              }}
            />
          </div>
        </div>
        <QuestionInfoModal
          visible={showInfoModal}
          question={selectedQuestion}
          onClose={() => setShowInfoModal(false)}
        />
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  getQuestionList: () => dispatch(ExamActions.getQuestionList()),
  removeQuestion: id => dispatch(ExamActions.removeQuestion(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageQuestion)
);
