import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useSelector, useDispatch } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import {
  CustomConfirmDialogActions,
  CustomPrimaryActionBtn,
  CustomCancelBtn,
} from "../../../Components/CustomDialogElements";
import useStyles from "./styles";
import clsx from "clsx";
import { SUBSCRIPTION_STATUS } from "../../../constants";
import { useHistory } from "react-router";
import { CHECKOUT_URL, WELCOME_URL } from "../../../routes/constants";
import { parsePlanHistoryTimeText } from "./helper";
import {
  activateSubscription,
  getSubscriptionByProfile,
} from "../../../actions/subscriptionAction";
import { parseAuthToken } from "../../../utils";

const SubscriptionsPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { authToken } = useSelector(state => state.UserStore);
  const { subscription } = useSelector(state => state.SubscriptionStore);
  const userId = parseAuthToken(authToken);
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [selectedPlanHistoryID, setSelectedPlanHistoryID] = useState(null);
  const [showActivateModal, setShowActivateModal] = useState(false);

  // Handle Confirmtion Modal Start
  const handleOpenActivateModal = () => {
    setShowActivateModal(true);
  };

  const handleCloseActivateModal = () => {
    setShowActivateModal(false);
  };

  const handleSelectPlan = id => {
    setSelectedPlanHistoryID(id);
    handleOpenActivateModal();
  };

  const handleActivateSubscription = () => {
    if (selectedPlanHistoryID) {
      dispatch(activateSubscription(subscription._id, selectedPlanHistoryID));
      handleCloseActivateModal();
    }
  };

  const onLaunchSubscription = () => {
    history.push(WELCOME_URL);
  };

  const onRenewSubscription = () => {
    history.push(CHECKOUT_URL);
  };

  useEffect(() => {
    dispatch(getSubscriptionByProfile(userId));
  }, [dispatch, userId]);

  if (
    !subscription ||
    !Array.isArray(subscription._planHistory) ||
    !(subscription._planHistory.length > 0)
  )
    return <Box component={"h3"}>No results found</Box>;

  return (
    <>
      <Dialog onClose={handleCloseActivateModal} open={showActivateModal}>
        <DialogContent>
          Are you sure you wish to activate this subscription plan?
        </DialogContent>
        <CustomConfirmDialogActions>
          <CustomPrimaryActionBtn onClick={handleActivateSubscription}>
            Confirm
          </CustomPrimaryActionBtn>
          <CustomCancelBtn onClick={handleCloseActivateModal}>
            Cancel
          </CustomCancelBtn>
        </CustomConfirmDialogActions>
      </Dialog>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {subscription._planHistory.map(p => {
          const isActive =
            (p.status === SUBSCRIPTION_STATUS.ACTIVE ||
              p.status === SUBSCRIPTION_STATUS.TRIAL) &&
            p.status !== SUBSCRIPTION_STATUS.EXPIRED;
          const isInActive = p.status === SUBSCRIPTION_STATUS.INACTIVE;
          const isRefunded = p.status === SUBSCRIPTION_STATUS.REFUNDED;
          const { title, subTitle } = parsePlanHistoryTimeText(p);

          return (
            <Box
              className={clsx(
                isActive && classes.activeSubscriptionBox,
                classes.subscriptionBox
              )}
              mb={2}
              key={p._id}
            >
              <Grid container justify="space-between" spacing={4}>
                <Grid item xs={12} md={6}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems={!mdUp ? "center" : ""}
                    px={mdUp ? 4 : 2}
                  >
                    <Box fontSize="1.1rem" mb={1}>
                      {p.term.title || "Plan"}
                    </Box>
                    <Box fontSize="0.8rem">
                      <Box component="span" color="primary.main">
                        {title}:{" "}
                      </Box>
                      <Box component="span">{subTitle}</Box>
                    </Box>
                  </Box>
                </Grid>
                {!isRefunded && (
                  <Grid item xs={12} md={6}>
                    <Box
                      display="flex"
                      justifyContent={"center"}
                      alignItems="center"
                      height="100%"
                      px={mdUp ? 4 : 2}
                      className={classes.buttonContainer}
                    >
                      <Box mr={3}>
                        {isActive ? (
                          <Button
                            color="primary"
                            variant="contained"
                            className={classes.ctaButton}
                            onClick={onLaunchSubscription}
                          >
                            Launch
                          </Button>
                        ) : mdUp ? (
                          <Box minWidth="6rem"></Box>
                        ) : (
                          <></>
                        )}
                      </Box>

                      <Box>
                        {isInActive ? (
                          <Button
                            color="primary"
                            variant="contained"
                            className={classes.ctaButton}
                            onClick={() => handleSelectPlan(p._id)}
                          >
                            Activate
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            variant="contained"
                            className={classes.ctaButton}
                            onClick={onRenewSubscription}
                          >
                            Renew
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default SubscriptionsPage;
