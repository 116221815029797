import {
  LIGHT_PRIMARY_COLOR,
  LIGHT_SECONDARY_COLOR,
  LIGHT_TERTIARY_COLOR,
  LIGHT_FONT_COLOR,
  LIGHT_DIVIDER_COLOR,
  DARK_SECONDARY_COLOR,
  DARK_TERTIARY_COLOR,
  COLOR_WHITE,
  DARK_DIVIDER_COLOR,
} from "../../styles/constants";

const styles = theme => ({
  statsIcon: { fontSize: 24 },
  dFlex: {
    display: "flex",
  },
  themeModeIcon: {
    color: "#fcfcfc",
    fontSize: "30px",
  },
  sideMenu: {
    [theme.breakpoints.down("md")]: {
      position: "fixed",
      zIndex: 2010,
      left: 0,
    },
    overflowY: "auto",
  },
  revealBottomTitle: {
    fontSize: "11pt",
  },
  revealBottomTitleLight: {
    color: "#000000",
  },
  revealBottomTitleDark: {
    color: COLOR_WHITE,
  },
  revealBottomSubtitle: {
    fontSize: "9pt",
    paddingTop: 4,
  },
  revealBottomSubtitleLight: {
    color: "#6a6a6a",
  },
  revealBottomSubtitleDark: {
    color: "#A6A6A6",
  },
  // Settings Menu Styling
  settingsPaper: {
    padding: 15,
    borderRadius: 1,
    boxShadow: "0 -10px 20px rgb(0 0 0 / 50%)",
    backgroundColor: "#F1F1F1",
    color: "#000",
    width: "90vw",
    maxWidth: "300px",
  },
  settingsHeader: {
    paddingTop: 10,
    paddingBottom: 20,
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  settingsHeaderTitle: { fontSize: "1.3em", fontWeight: 600 },
  settingsHeaderLabel: {
    color: "#757575",
    fontSize: "1em",
    textAlign: "left",
    paddingBottom: 5,
    paddingLeft: 10,
    fontWeight: 600,
  },
  settingsCont: {
    borderRadius: 8,
    backgroundColor: "#fff",
  },
  settingsOption: {
    display: "flex",
    padding: "10.5px 0",
    margin: "0 15px",
    alignItems: "center",
  },
  settingLabel: {
    fontSize: ".8em",
    fontWeight: 500,
    flex: 1,
  },
  btnGroup: {
    position: "relative",
    display: "inline-block",
    verticalAlign: "middle",
  },
  fontSettingsBtnCont: {
    border: 0,
    color: "#000",
    cursor: "pointer",
    "&:active": {
      boxShadow: "inset 0 3px 5px rgb(0 0 0 / 13%)",
      webkitBoxShadow: "inset 0 3px 5px rgb(0 0 0 / 13%)",
    },
  },
  minusBtnCont: {
    width: 40,
    padding: "2px 12px 2px 12px",
  },
  addBtnCont: {
    width: 40,
    padding: "2px 6px 2px 12px",
  },
  fontCaseBtnCont: {
    width: 50,
    padding: "2px 12px",
    borderRight: "1px solid #DDDDDD",
    borderLeft: "1px solid #DDDDDD",
  },
  sideMenuDark: {
    backgroundColor: `${DARK_SECONDARY_COLOR} !important`,
    color: COLOR_WHITE,
    borderRight: `1px solid ${DARK_DIVIDER_COLOR} !important`,
  },
  headContDark: {
    background: DARK_SECONDARY_COLOR,
  },
  headContLight: {
    background: LIGHT_PRIMARY_COLOR,
  },
  footContDark: {
    background: DARK_SECONDARY_COLOR,
  },
  footContLight: {
    background: LIGHT_PRIMARY_COLOR,
  },
  // questionCont equal to all body content container
  questionCont: {
    padding: "20px;",
    overflowY: "auto",
    height: "100%",
    width: "100%",
  },
  questionContLight: {
    color: "#000000",
    background: LIGHT_SECONDARY_COLOR,
  },
  questionContDark: {
    color: COLOR_WHITE,
  },
  submitDisabledBtnDark: {
    backgroundColor: "rgba(255, 255, 255, 0.12) !important",
    color: "rgba(255, 255, 255, 0.3) !important",
  },
  // Lab Values Styling
  labContLight: {
    background: COLOR_WHITE,
  },
  labContDark: {
    color: COLOR_WHITE,
  },
  labCont: {
    marginLeft: "auto",
    padding: "0px 0 22px 2px",
    // border: "2px solid #3852a4",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      height: "100%",
      width: "100%",
      left: 0,
    },
    [theme.breakpoints.up("lg")]: {
      width: 720,
    },
  },
  searchCont: {
    marginTop: 10,
    marginBottom: 36,
    padding: "0 10px",
  },
  searchBox: {
    maxWidth: 120,
    height: 22,
    fontSize: "10pt",
    border: "1px solid rgba(0,0,0,.3)",
    borderRadius: 4,
    "&:focus": {
      outline: "none",
    },
  },
  searchBoxLight: {
    color: LIGHT_FONT_COLOR,
  },
  searchBoxDark: {
    backgroundColor: DARK_TERTIARY_COLOR,
    color: COLOR_WHITE,
  },
  SICheckBoxLabel: {
    fontSize: 15,
  },
  labContent: {
    overflow: "auto",
    padding: 10,
    paddingBottom: 30,
    height: "90%",
  },
  labContentLight: {
    backgroundColor: LIGHT_TERTIARY_COLOR,
    color: LIGHT_FONT_COLOR,
    borderLeft: "1px solid #aab",
    borderTop: "1px solid #aab",
  },
  labContentDark: {
    backgroundColor: DARK_SECONDARY_COLOR,
    color: COLOR_WHITE,
    borderLeft: `1px solid ${DARK_DIVIDER_COLOR} !important`,
    borderTop: `1px solid ${DARK_DIVIDER_COLOR} !important`,
  },
  labTable: {
    width: "100%",
    marginTop: 10,
    borderCollapse: "collapse",
  },
  labTableTd: {
    padding: "0 10px",
    width: "33%",
  },
  hr: {
    margin: "7px 0",
    border: 0,
  },
  hrLight: {
    borderTop: `1px solid ${LIGHT_DIVIDER_COLOR}`,
  },
  hrDark: {
    borderTop: `1px solid ${DARK_DIVIDER_COLOR}`,
  },
  labTabHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "3px 3px 0 0",
    height: 24,
    color: "#000",
    border: "none",
    verticalAlign: "middle",
    marginLeft: 20,
    padding: "0 5px",
    fontSize: ".8em",
    textAlign: "center",
    cursor: "pointer",
    backgroundColor: "#f7f9ff",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 10,
      height: 30,
    },
  },
  labTabHeaderLight: {
    borderLeft: "1px solid #aab",
    borderTop: "1px solid #aab",
    borderRight: "1px solid #aab",
  },
  labTabHeaderDark: {
    borderLeft: `1px solid ${DARK_DIVIDER_COLOR}`,
    borderTop: `1px solid ${DARK_DIVIDER_COLOR}`,
    borderRight: `1px solid ${DARK_DIVIDER_COLOR}`,
  },
  labTabHeaderActive: {
    backgroundColor: LIGHT_TERTIARY_COLOR,
  },
  highlightMenuCont: {
    height: 40,
    zIndex: 2000,
    background: "#242422!important",
    display: "flex",
    color: " #fff!important",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
  },
  highlightMenuBtn: {
    padding: "6px 12px",
    height: "40px",
    background: "#242422!important",
    color: " #fff!important",
    display: "flex",
    alignItems: "center",
    borderLeft: "1px solid #4A4A4A!important",
    cursor: "pointer",
    fontSize: 15,
    "&:hover": {
      background: "#4a4a4a !important",
    },
  },
  hightLightIcon: {
    fontSize: 24,
  },
  toolIcon: {
    fontSize: 18,
    marginRight: 8,
  },
  stackCont: {
    position: "relative",
    marginLeft: "-2px",
    width: "20px",
    verticalAlign: "text-top",
    height: "20px",
    marginRight: 8,
  },
  flashIcon: {
    position: "absolute",
    fontSize: 18,
    left: 0,
  },
  squareIcon: {
    position: "absolute",
    left: 4,
    fontSize: 18,
  },
});
export default styles;
