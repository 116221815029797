import { connect } from "react-redux";
import React, { Fragment, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";

import * as ExamActions from "../../../../actions/examAction";
import {
  getCategorizedCount,
  parseCapitalizeHtml,
  parseDateHtml,
} from "../../../../utils";
import ConfirmationModal from "../../../../Components/Modal/ConfirmationModal";
import useModal from "../../../../hooks/useModal";
import TableTitle from "./TableTitle";
import { DELETED_STATUS_FILTER } from "../constants";
import { handleRemoveTableRow } from "../helper";

const CategoryTable = ({ getCategoryList, removeCategory }) => {
  const [categories, setCategories] = useState([]);
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const { open, handleOpen, handleClose } = useModal();
  const handleOpenDeleteModal = () => {
    if (selectedIndexes.length > 0) {
      handleOpen();
    }
  };

  const fetchCategories = async () => {
    const res = await getCategoryList(DELETED_STATUS_FILTER);
    if (res && res.categories) {
      setCategories(res.categories);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const onRemoveCategory = async () => {
    await handleRemoveTableRow(
      categories,
      setCategories,
      selectedIndexes,
      setSelectedIndexes,
      removeCategory,
      handleClose
    );
  };

  return (
    <Fragment>
      <ConfirmationModal
        open={open}
        onClose={handleClose}
        actionFn={onRemoveCategory}
        ctaTitle={
          "Are you sure you wish to permanently delete these category(s)?"
        }
        ctaTxt={"Delete"}
      />
      <TableTitle
        title="Category"
        handleOpenDeleteModal={handleOpenDeleteModal}
      />
      <MUIDataTable
        data={categories.map(category => [
          category._ref,
          category._createdOn,
          category.name,
          category.type,
          Array.isArray(category.parents) && category.parents.length > 0
            ? category.parents.map(parent => parent.name).join(", ")
            : "N/A",
          getCategorizedCount(category._count, "all"),
        ])}
        columns={[
          "ID",
          {
            name: "Date Created",
            options: {
              customBodyRenderLite: dataIndex => {
                let val = categories[dataIndex]._createdOn;
                return parseDateHtml(val);
              },
              setCellHeaderProps: () => ({
                className: "globalCustomMuiTableHead",
              }),
            },
          },
          {
            name: "Name",
            options: {
              customBodyRender: value => {
                return (
                  <div style={{ width: 250, wordBreak: "break-word" }}>
                    {value}
                  </div>
                );
              },
            },
          },
          {
            name: "Type",
            options: {
              customBodyRender: value => {
                return parseCapitalizeHtml(value);
              },
            },
          },
          "Parents",
          {
            name: "Question Count",
            options: {
              customBodyRender: value => {
                return <span>{value}</span>;
              },
              setCellHeaderProps: () => ({
                className: "globalCustomMuiTableHead",
              }),
            },
          },
        ]}
        options={{
          download: false,
          print: false,
          sort: false,
          onRowSelectionChange: (
            currentRowsSelected,
            allRowsSelected,
            rowsSelected
          ) => {
            setSelectedIndexes(rowsSelected);
          },
          customToolbarSelect: () => {},
          rowsSelected: selectedIndexes,
        }}
      />
    </Fragment>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  getCategoryList: filter => dispatch(ExamActions.getCategoryList(filter)),
  removeCategory: (id, permanent) =>
    dispatch(ExamActions.removeCategory(id, permanent)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryTable);
