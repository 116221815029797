import { Box } from "@material-ui/core";
import React from "react";
import useStyles from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import history from "../../../routes/history";
import { useDispatch } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { logout } from "../../../actions/userAction";

const CourseAppNavLink = props => {
  const { icon, title, route } = props;
  const classes = useStyles();
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleClick = () => {
    if (title === "Log out") {
      handleLogout();
    } else {
      history.push(route);
    }
  };

  return (
    <Box mr={1.5} className={classes.courseAppIconLink} onClick={handleClick}>
      <FontAwesomeIcon icon={icon} color={"#a7b1c2"} />
      {lgUp && (
        <Box fontWeight={600} pl={1}>
          {title}
        </Box>
      )}
    </Box>
  );
};

export default CourseAppNavLink;
