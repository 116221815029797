import { Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import React, { Fragment, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";

import * as ExamActions from "../../actions/examAction";
import CategoryFormModal from "./modal/CategoryFormModal";
import {
  CustomDeleteIcon,
  CustomEditIcon,
} from "../../Components/CustomActionIcons";
import {
  getCategorizedCount,
  parseCapitalizeHtml,
  parseDateHtml,
} from "../../utils";
import ConfirmationModal from "../../Components/Modal/ConfirmationModal";
import useModal from "../../hooks/useModal";

const ManageCategory = ({
  getCategoryList,
  createCategory,
  updateCategory,
  removeCategory,
}) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(undefined);
  const [showFormModal, setShowFormModal] = useState(false);
  const [categoryName, setCategoryName] = useState();
  const { open, handleOpen, handleClose } = useModal();

  const fetchCategories = async () => {
    const res = await getCategoryList();
    if (res && res.categories) {
      setCategories(res.categories);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const onCreateCategory = async ({ type, parentIds, name }) => {
    let trimmedName = name.trim();
    const res = await createCategory({
      input: parentIds
        ? { name: trimmedName, type, parent_ids: parentIds }
        : { name: trimmedName, type },
    });
    if (res && res.createCategory) {
      const createCategoryData = res.createCategory;
      setCategories(categories => categories.concat(createCategoryData));
    }

    setShowFormModal(false);
  };

  const onUpdateCategory = async (instance, { type, parentIds, name }) => {
    const isNameChanged = categoryName !== name;
    const res = await updateCategory({
      input: {
        _id: instance._id,
        type,
        name: isNameChanged ? name.trim() : undefined,
        parent_ids: parentIds ? parentIds : undefined,
      },
    });
    if (res && res.updateCategory) {
      fetchCategories();
    }

    setShowFormModal(false);
  };

  const handleRemoveCategory = async () => {
    const data = await removeCategory(selectedCategory._id);
    if (data) {
      setCategories(categories =>
        categories.filter(category => category._id !== selectedCategory._id)
      );
    }
    handleClose();
  };

  return (
    <Fragment>
      <ConfirmationModal
        open={open}
        onClose={handleClose}
        actionFn={handleRemoveCategory}
        ctaTitle={"Are you wish to delete this category?"}
        ctaTxt={"Delete"}
      />
      <div className="pageCont manageCategoryPage">
        <div className="bodyCont">
          <div className="pageAction">
            <div className="pageActionRight">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setSelectedCategory(undefined);
                  setShowFormModal(show => !show);
                }}
              >
                Create Category
              </Button>
            </div>
          </div>
          <div className="pageContent">
            <MUIDataTable
              data={categories.map(category => [
                category._ref,
                category._createdOn,
                category.name,
                category.type,
                Array.isArray(category.parents) && category.parents.length > 0
                  ? category.parents.map(parent => parent.name).join(", ")
                  : "N/A",
                getCategorizedCount(category._count, "all"),
              ])}
              columns={[
                "ID",
                {
                  name: "Date Created",
                  options: {
                    customBodyRenderLite: dataIndex => {
                      let val = categories[dataIndex]._createdOn;
                      return parseDateHtml(val);
                    },
                    setCellHeaderProps: () => ({
                      className: "globalCustomMuiTableHead",
                    }),
                  },
                },
                {
                  name: "Name",
                  options: {
                    customBodyRender: value => {
                      return (
                        <div style={{ width: 250, wordBreak: "break-word" }}>
                          {value}
                        </div>
                      );
                    },
                  },
                },
                {
                  name: "Type",
                  options: {
                    customBodyRender: value => {
                      return parseCapitalizeHtml(value);
                    },
                  },
                },
                "Parents",
                {
                  name: "Question Count",
                  options: {
                    customBodyRender: value => {
                      return <span>{value}</span>;
                    },
                    setCellHeaderProps: () => ({
                      className: "globalCustomMuiTableHead",
                    }),
                  },
                },
                {
                  name: "Action",
                  options: {
                    customBodyRenderLite: dataIndex => (
                      <div className="actionDiv">
                        <CustomEditIcon
                          onClickHandler={() => {
                            setSelectedCategory(categories[dataIndex]);
                            setShowFormModal(true);
                          }}
                          style={{ marginLeft: 8 }}
                        />
                        <CustomDeleteIcon
                          onClickHandler={() => {
                            setSelectedCategory(categories[dataIndex]);
                            handleOpen();
                          }}
                          style={{ marginLeft: 8 }}
                        />
                      </div>
                    ),
                  },
                },
              ]}
              options={{
                download: false,
                print: false,
                selectableRows: "none",
                // onRowsDelete: (rowsDeleted, data, newTableData) => {
                //   (async () => {
                //     const deletedIds = [];
                //     await Promise.all(
                //       rowsDeleted.data.map(async ({ dataIndex }) => {
                //         const data = await removeCategory(
                //           categories[dataIndex]._id
                //         );
                //         if (data) {
                //           deletedIds.push(categories[dataIndex]._id);
                //         }
                //       })
                //     );

                //     setCategories(categories =>
                //       categories.filter(
                //         category => !deletedIds.includes(category._id)
                //       )
                //     );
                //   })();

                //   return false;
                // },
              }}
            />
          </div>
        </div>
        <CategoryFormModal
          visible={showFormModal}
          instance={selectedCategory}
          subjects={categories.filter(category => category.type === "subject")}
          onSubmit={(instance, data) => {
            instance
              ? onUpdateCategory(instance, data)
              : onCreateCategory(data);
          }}
          onClose={() => setShowFormModal(false)}
          setCategoryName={setCategoryName}
        />
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  getCategoryList: () => dispatch(ExamActions.getCategoryList()),
  createCategory: data => dispatch(ExamActions.createCategory(data)),
  updateCategory: data => dispatch(ExamActions.updateCategory(data)),
  removeCategory: id => dispatch(ExamActions.removeCategory(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageCategory)
);
