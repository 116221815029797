import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Line,
  Legend,
  Label,
} from "recharts";

import {
  YOUR_SCORE_RANK_COLOR,
  MEDIAN_SCORE_RANK_COLOR,
} from "../../../styles/constants";
import styles from "./styles";

const useStyles = makeStyles(theme => styles(theme));

const customXAxisTickFormatter = tick => {
  const len = tick.length;
  return tick.substring(0, len - 5);
};

const CustomTooltip = props => {
  const { active, payload } = props;
  const classes = useStyles();

  if (active && payload && payload.length) {
    const values = payload[0].payload;
    return (
      <div className={classes.customTooltip}>
        <div className={classes.centerContainer}>
          <div>{values.date}</div>
          <div>
            <span
              className={classes.stats}
              style={{ color: YOUR_SCORE_RANK_COLOR }}
            >{`${values.yourAverageScore}%`}</span>
            <span
              className={classes.stats}
              style={{
                color: MEDIAN_SCORE_RANK_COLOR,
                borderLeft: "1px solid #313131",
                borderRight: "1px solid #313131",
              }}
            >{`${values.cumulativeAverageScore}%`}</span>
            <span
              className={classes.stats}
              style={{ color: "grey" }}
            >{`${values.cumulativeAverageOthersAvgScore}%`}</span>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

const PerformanceGraph = ({ performanceByDateData }) => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <ResponsiveContainer width={"100%"} height={500}>
      <ComposedChart
        data={performanceByDateData}
        margin={{
          top: 20,
          right: 20,
          bottom: xsDown ? 75 : 85,
          left: 20,
        }}
      >
        <CartesianGrid vertical={false} stroke="#e8e8e8" />
        <XAxis
          type="category"
          dataKey="date"
          axisLine={{ stroke: "#e8e8e8" }}
          tick={{ fill: "#777777" }}
          tickLine={false}
          tickFormatter={
            performanceByDateData.length > 10 ? customXAxisTickFormatter : null
          }
          label={
            <Label value="Date" position="bottom" fill="rgb(155, 155, 155)" />
          }
        />
        <YAxis
          type="number"
          axisLine={{ stroke: "#e8e8e8" }}
          tick={{ fill: "rgb(155, 155, 155)" }}
          tickLine={false}
          label={
            <Label
              value="Score"
              position="insideLeft"
              angle={-90}
              fill="rgb(155, 155, 155)"
            />
          }
        />
        <Legend
          verticalAlign="bottom"
          align="right"
          iconSize={10}
          wrapperStyle={{
            top: xsDown ? 410 : 425,
          }}
          payload={[
            {
              id: "ys",
              value: "Your Score",
              type: "circle",
              color: YOUR_SCORE_RANK_COLOR,
            },
            {
              id: "ycas",
              value: "Your Cumulative Average Score",
              type: "plainline",
              color: MEDIAN_SCORE_RANK_COLOR,
              payload: { strokeDasharray: "0 0" },
            },
            {
              id: "ocas",
              value: "Other's Cumulative Average score",
              type: "plainline",
              color: "grey",
              payload: { strokeDasharray: "0 0" },
            },
          ]}
        />
        <Tooltip cursor={false} content={CustomTooltip} />

        <Scatter dataKey="yourAverageScore" fill={YOUR_SCORE_RANK_COLOR} />
        <Line
          type="monotone"
          dataKey="cumulativeAverageScore"
          stroke={MEDIAN_SCORE_RANK_COLOR}
          activeDot={false}
          dot={false}
          name="IGNORE"
        />
        <Line
          type="monotone"
          dataKey="cumulativeAverageOthersAvgScore"
          stroke={"grey"}
          activeDot={false}
          dot={false}
          name="IGNORE"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default PerformanceGraph;
