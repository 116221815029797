import { gql } from "@apollo/client";

export const GET_FLASHCARDS = gql`
  query Flashcards($filter: FlashcardQueryFilterInput) {
    flashcards(filter: $filter) {
      _id
      front
      back
      tags
      status
      question_id
      question {
        category_id
        subCategory_id
      }
      category {
        _id
        name
      }
      subCategory {
        _id
        name
      }
    }
  }
`;

export const CREATE_FLASHCARD = gql`
  mutation CreateFlashcard($input: CreateFlashcardInput!) {
    createFlashcard(input: $input) {
      _id
      front
      back
      tags
      status
      question_id
      category {
        _id
        name
      }
      subCategory {
        _id
        name
      }
    }
  }
`;

export const UPDATE_FLASHCARD = gql`
  mutation UpdateFlashcard($_id: ObjectId!, $input: UpdateFlashcardInput!) {
    updateFlashcard(_id: $_id, input: $input) {
      _id
      front
      back
      tags
      status
      question_id
      category {
        _id
        name
      }
      subCategory {
        _id
        name
      }
    }
  }
`;

export const REMOVE_FLASHCARD = gql`
  mutation RemoveFlashcard($_id: ObjectId!) {
    removeFlashcard(_id: $_id)
  }
`;
