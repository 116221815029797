import React from "react";
import FormCardContainer from "./components/FormCardContainer";
import ForgotPasswordForm from "../../Components/Form/ForgotPassword/ForgotPasswordForm";

const ForgotPasswordPage = () => {
  return (
    <>
      <FormCardContainer>
        <ForgotPasswordForm />
      </FormCardContainer>
    </>
  );
};

export default ForgotPasswordPage;
