import { Dialog, DialogContent } from "@material-ui/core";
import React from "react";
import {
  CustomPrimaryActionBtn,
  CustomCancelBtn,
  CustomConfirmDialogActions,
} from "../CustomDialogElements";

const PrimaryConfirmationModal = props => {
  const {
    open,
    onClose,
    actionFn,
    ctaTitle,
    ctaTxt,
    secondaryTxt = "Cancel",
  } = props;
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogContent>{ctaTitle}</DialogContent>
      <CustomConfirmDialogActions>
        <CustomPrimaryActionBtn onClick={actionFn}>
          {ctaTxt}
        </CustomPrimaryActionBtn>
        <CustomCancelBtn onClick={onClose}>{secondaryTxt}</CustomCancelBtn>
      </CustomConfirmDialogActions>
    </Dialog>
  );
};

export default PrimaryConfirmationModal;
