export const bellCurveArr = [
  { cy: 0.00176967 },
  { cy: 0.00217456 },
  { cy: 0.002661 },
  { cy: 0.00324271 },
  { cy: 0.00393519 },
  { cy: 0.00475571 },
  { cy: 0.00572343 },
  { cy: 0.00685947 },
  { cy: 0.00818685 },
  { cy: 0.00973051 },
  { cy: 0.01151719 },
  { cy: 0.01357531 },
  { cy: 0.01593475 },
  { cy: 0.01862659 },
  { cy: 0.02168271 },
  { cy: 0.02513541 },
  { cy: 0.02901689 },
  { cy: 0.03335862 },
  { cy: 0.0381907 },
  { cy: 0.0435411 },
  { cy: 0.04943489 },
  { cy: 0.05589334 },
  { cy: 0.06293306 },
  { cy: 0.0705651 },
  { cy: 0.07879405 },
  { cy: 0.08761717 },
  { cy: 0.09702358 },
  { cy: 0.10699358 },
  { cy: 0.117498 },
  { cy: 0.12849775 },
  { cy: 0.13994356 },
  { cy: 0.15177583 },
  { cy: 0.1639248 },
  { cy: 0.17631084 },
  { cy: 0.18884508 },
  { cy: 0.20143024 },
  { cy: 0.21396168 },
  { cy: 0.22632871 },
  { cy: 0.23841612 },
  { cy: 0.25010588 },
  { cy: 0.261279 },
  { cy: 0.27181752 },
  { cy: 0.28160652 },
  { cy: 0.29053622 },
  { cy: 0.29850402 },
  { cy: 0.30541644 },
  { cy: 0.31119095 },
  { cy: 0.31575761 },
  { cy: 0.31906049 },
  { cy: 0.32105877 },
  { cy: 0.32172765 },
  { cy: 0.32105877 },
  { cy: 0.31906049 },
  { cy: 0.31575761 },
  { cy: 0.31119095 },
  { cy: 0.30541644 },
  { cy: 0.29850402 },
  { cy: 0.29053622 },
  { cy: 0.28160652 },
  { cy: 0.27181752 },
  { cy: 0.261279 },
  { cy: 0.25010588 },
  { cy: 0.23841612 },
  { cy: 0.22632871 },
  { cy: 0.21396168 },
  { cy: 0.20143024 },
  { cy: 0.18884508 },
  { cy: 0.17631084 },
  { cy: 0.1639248 },
  { cy: 0.15177583 },
  { cy: 0.13994356 },
  { cy: 0.12849775 },
  { cy: 0.117498 },
  { cy: 0.10699358 },
  { cy: 0.09702358 },
  { cy: 0.08761717 },
  { cy: 0.07879405 },
  { cy: 0.0705651 },
  { cy: 0.06293306 },
  { cy: 0.05589334 },
  { cy: 0.04943489 },
  { cy: 0.0435411 },
  { cy: 0.0381907 },
  { cy: 0.03335862 },
  { cy: 0.02901689 },
  { cy: 0.02513541 },
  { cy: 0.02168271 },
  { cy: 0.01862659 },
  { cy: 0.01593475 },
  { cy: 0.01357531 },
  { cy: 0.01151719 },
  { cy: 0.00973051 },
  { cy: 0.00818685 },
  { cy: 0.00685947 },
  { cy: 0.00572343 },
  { cy: 0.00475571 },
  { cy: 0.00393519 },
  { cy: 0.00324271 },
  { cy: 0.002661 },
  { cy: 0.00217456 },
];
