import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";

import { getPlans } from "../../../../actions/planAction";
import * as UIActions from "../../../../actions/uiAction";
import useStyles from "./styles";
import { COLOR_BLACK } from "../../../../styles/constants";
import { addToCart } from "../../../../actions/cartAction";
import PlanBox from "../components/PlanBox";
import { PLAN_BOX_TYPE } from "../components/PlanBox/constants";
import { selectCartItems } from "../../../../selectors/cart";
import { FREE_TRIAL_PRICE_PLAN } from "../../../../constants";

const PricingSection = props => {
  const { handleNext, enableFreeTrial } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { plans, loading } = useSelector(state => state.plan);
  const cartItems = useSelector(selectCartItems);
  const selectedCartItem = Boolean(cartItems.length) ? cartItems[0] : null;

  const handleAddCartItem = pricePlan => {
    if (Boolean(cartItems.length)) {
      dispatch(
        UIActions.addError(
          "You already have an item in your cart, please remove it if you wish to change your plan."
        )
      );
    } else {
      dispatch(addToCart(pricePlan));
      handleNext();
    }
  };

  useEffect(() => {
    if (loading) {
      dispatch(getPlans());
    }
  }, [dispatch, loading]);

  if (!plans) return null;

  return plans.map(plan => (
    <Grid key={plan._id} container direction="column" justify="center">
      <Grid item>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          height="100%"
          fontSize="2.5rem"
          fontWeight="bold"
          color={COLOR_BLACK}
          mb={2}
        >
          <span>{plan.name}</span>
          <div className={classes.titleLine}></div>
        </Box>
      </Grid>
      <Grid item>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          fontSize="1rem"
          color={COLOR_BLACK}
          mb={3}
        >
          <span>Choose your best Plans.</span>
        </Box>
      </Grid>
      <Grid item>
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <PlanBox
              disabled={!enableFreeTrial}
              type={PLAN_BOX_TYPE.OPTION}
              pricePlan={FREE_TRIAL_PRICE_PLAN}
              selected={
                selectedCartItem
                  ? selectedCartItem.title == FREE_TRIAL_PRICE_PLAN.title
                  : false
              }
              handleAddCartItem={() => {
                if (enableFreeTrial) {
                  handleAddCartItem({
                    title: FREE_TRIAL_PRICE_PLAN.title,
                    price: FREE_TRIAL_PRICE_PLAN.price,
                    plan_id: plan._id,
                    planName: plan.name,
                  });
                }
              }}
            />
          </Grid>
          {plan.price.map(pricePlan => (
            <Grid key={pricePlan._id} item xs={12} md={4}>
              <PlanBox
                type={PLAN_BOX_TYPE.OPTION}
                pricePlan={pricePlan}
                selected={
                  selectedCartItem
                    ? selectedCartItem.pricePlanID == pricePlan._id
                    : false
                }
                handleAddCartItem={() =>
                  handleAddCartItem({
                    title: pricePlan.title,
                    pricePlanID: pricePlan._id,
                    price: pricePlan.price,
                    plan_id: plan._id,
                    planName: plan.name,
                  })
                }
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  ));
};

export default PricingSection;
