import { Box, TextField, Button } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import React, { useMemo, useEffect } from "react";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import DnsOutlinedIcon from "@material-ui/icons/DnsOutlined";
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined";
import LocationCityOutlinedIcon from "@material-ui/icons/LocationCityOutlined";

import MUICustomHeader from "../../../../../Components/MUICustomHeader";
import {
  NUMBER_REGEX,
  XS_MUIDATATABLE_OPTIONS,
} from "../../../../../constants";
import useStyles from "./styles";
import { parseDateHtml, safeParseCountry } from "../../../../../utils";
import { Controller, useForm } from "react-hook-form";
import { SELECT_INPUT_WIDTH, textFieldProps } from "../../../helper";
import ConfirmationModal from "../../../../../Components/Modal/ConfirmationModal";
import useModal from "../../../../../hooks/useModal";
import { useDispatch } from "react-redux";
import { updateSubscription } from "../../../../../actions/subscriptionAction";

const ResetHistoriesTable = props => {
  const { user, isEditing = false } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleSubmit, control, errors, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      resetLimit: "",
    },
  });
  const { open, handleOpen, handleClose } = useModal();

  const handleUpdateResetLimit = ({ resetLimit }) => {
    const parsedResetLimit = parseInt(resetLimit);
    const data = {
      _id: user.subscription._id,
      input: { resetLimit: parsedResetLimit },
    };
    dispatch(updateSubscription(data));
    handleClose();
  };

  useEffect(() => {
    if (user.subscription) {
      reset({
        resetLimit: user.subscription.resetLimit,
      });
    }
  }, [user]);

  const data = useMemo(
    () =>
      user.subscription &&
      Array.isArray(user.subscription._resetHistory) &&
      user.subscription._resetHistory.length > 0
        ? user.subscription._resetHistory
            .sort((a, b) => (a._createdOn < b._createdOn ? 1 : -1))
            .map((r, index) => [
              index + 1,
              r._createdOn,
              r.ipAddress,
              r.country,
              r.city,
            ])
        : [],
    [user]
  );
  const columns = [
    {
      name: "No",
      options: {
        customHeadRender: columnMeta => {
          return (
            <MUICustomHeader label={columnMeta.label}>
              <>
                {columnMeta.label}
                <FormatListNumberedIcon
                  className={classes.thIcon}
                  color="secondary"
                />
              </>
            </MUICustomHeader>
          );
        },
      },
    },
    {
      name: "Reset On",
      options: {
        customBodyRender: value => {
          return parseDateHtml(value);
        },
        customHeadRender: columnMeta => {
          return (
            <MUICustomHeader label={columnMeta.label}>
              <>
                {columnMeta.label}
                <CalendarTodayOutlinedIcon
                  className={classes.thIcon}
                  color="secondary"
                />
              </>
            </MUICustomHeader>
          );
        },
      },
    },
    {
      name: "IP Address",
      options: {
        customBodyRender: value => <Box width="100px">{value}</Box>,
        customHeadRender: columnMeta => {
          return (
            <MUICustomHeader label={columnMeta.label}>
              <>
                {columnMeta.label}
                <DnsOutlinedIcon className={classes.thIcon} color="secondary" />
              </>
            </MUICustomHeader>
          );
        },
      },
    },
    {
      name: "Country",
      options: {
        customBodyRender: value => safeParseCountry(value),
        customHeadRender: columnMeta => {
          return (
            <MUICustomHeader label={columnMeta.label}>
              <>
                {columnMeta.label}
                <PublicOutlinedIcon
                  className={classes.thIcon}
                  color="secondary"
                />
              </>
            </MUICustomHeader>
          );
        },
      },
    },
    {
      name: "City",
      options: {
        customHeadRender: columnMeta => {
          return (
            <MUICustomHeader label={columnMeta.label}>
              <>
                {columnMeta.label}
                <LocationCityOutlinedIcon
                  className={classes.thIcon}
                  color="secondary"
                />
              </>
            </MUICustomHeader>
          );
        },
      },
    },
  ];

  return (
    <>
      {isEditing && (
        <>
          <ConfirmationModal
            open={open}
            onClose={handleClose}
            actionFn={handleSubmit(handleUpdateResetLimit)}
            ctaTitle={"Are you wish to update this user's reset count?"}
            ctaTxt={"Update"}
          />
          <Box mb={2} style={{ maxWidth: SELECT_INPUT_WIDTH }}>
            <Controller
              control={control}
              name="resetLimit"
              rules={{
                required: "Reset count cannot be empty",
                min: {
                  value: 0,
                  message: "Reset count must be more than or equal to 0",
                },
                pattern: {
                  value: NUMBER_REGEX,
                  message: "Only allow positive integer",
                },
              }}
              render={({ onChange, _onBlur, value, name, ref }) => (
                <TextField
                  name={name}
                  variant="filled"
                  label="Reset Count"
                  inputRef={ref}
                  value={value}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  error={!!errors.resetLimit}
                  helperText={errors.resetLimit && errors.resetLimit.message}
                  type="number"
                  {...textFieldProps}
                />
              )}
            />
          </Box>
          <Box mb={3}>
            <Button color="secondary" variant="contained" onClick={handleOpen}>
              Update
            </Button>
          </Box>
        </>
      )}
      <Box width="100%" mb={2}>
        <MUIDataTable
          data={data}
          columns={columns}
          options={{ ...XS_MUIDATATABLE_OPTIONS }}
        />
      </Box>
    </>
  );
};

export default ResetHistoriesTable;
