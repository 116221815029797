import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import React from "react";

export const SecondaryButton = withStyles(theme => ({
  root: {
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#1e8ce3",
      boxShadow: "none",
    },
  },
  disabled: {
    backgroundColor: "#88cee7 !important",
    color: "#fff !important",
    opacity: "0.65 !important",
  },
}))(Button);

export const PrimaryButton = withStyles(theme => ({
  root: {
    height: 50,
    fontSize: "1rem",
    textTransform: "none",
    // boxShadow: "none",
    // backgroundColor: theme.palette.primary.main,
    // color: "#fff",
    // "&:hover": {
    //   // backgroundColor: theme.palette.primary.main,
    //   // boxShadow: "none",
    // },
  },
}))(props => <Button color="primary" variant="contained" {...props} />);
