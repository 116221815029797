import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useForm, Controller } from "react-hook-form/dist/index.ie11";
import { useDispatch, useSelector } from "react-redux";
import { EMAIL_REGEX } from "../../../constants";

import PrimaryTextField from "../../PrimaryTextField";
import { PrimaryButton } from "../../CustomButton";
import useStyles from "../styles";
import { selectCheckUserExists } from "../../../selectors/signup";
import { login } from "../../../actions/userAction";
import { selectUserLoginErrorMsg } from "../../../selectors/user";
import { ERROR_COLOR } from "../../../styles/constants";
import { clearCheckUserExists } from "../../../actions/signupAction";

const WelcomeUserForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const checkUserExists = useSelector(selectCheckUserExists);
  const loginErrorMsg = useSelector(selectUserLoginErrorMsg);

  const { handleSubmit, control, errors, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = data => {
    dispatch(login(data));
  };

  useEffect(() => {
    if (checkUserExists) {
      reset({
        email: checkUserExists.email,
        password: "",
      });
    }

    return () => {
      dispatch(clearCheckUserExists());
    };
  }, [checkUserExists]);

  if (!checkUserExists) return null;

  return (
    <>
      <Grid direction="column" container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box mb={1.5}>
              <Box
                component="h3"
                fontWeight="2rem"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                Log In
              </Box>
              <Box
                fontWeight="1.15rem"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                So good to see you, {checkUserExists.username}.
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="email"
              rules={{
                required: "Email cannot be empty",
                pattern: {
                  value: EMAIL_REGEX,
                  message: "Invalid Email Format",
                },
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="Email"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.email)}
                  helperText={errors.email && errors.email.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="password"
              rules={{ required: "Required" }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="Password"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.password)}
                  helperText={errors.password && errors.password.message}
                  type={"password"}
                  inputProps={{ autoComplete: "new-password" }}
                />
              )}
            />
          </Grid>

          {loginErrorMsg && (
            <Box p={1} fontSize={"0.9rem"} color={ERROR_COLOR}>
              {loginErrorMsg}
            </Box>
          )}

          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" mt={3}>
              <PrimaryButton
                color="primary"
                variant="contained"
                fullWidth
                onClick={handleSubmit(onSubmit)}
                className={classes.continueButton}
              >
                LOG IN
              </PrimaryButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default WelcomeUserForm;
