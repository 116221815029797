import * as ActionTypes from "../actions/planAction";
import * as UserTypes from "../actions/userAction";

const { PLAN_LIST_REQUEST } = ActionTypes;
const { USER_LOGOUT } = UserTypes;
const initialAppState = {
  loading: true,
  plans: null,
};

const planReducer = (state = initialAppState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PLAN_LIST_REQUEST.AWAIT:
      return { ...state, loading: true, ...state };
    case PLAN_LIST_REQUEST.SUCCESS:
      return {
        ...state,
        loading: false,
        plans: payload,
      };
    case PLAN_LIST_REQUEST.FAIL:
      return { ...state, loading: false, error: payload.error };
    case USER_LOGOUT: {
      return initialAppState;
    }
    default:
      return state;
  }
};

export default planReducer;
