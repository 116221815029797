import React, { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useForm, Controller } from "react-hook-form/dist/index.ie11";
import PrimaryTextField from "Components/PrimaryTextField";
import PrimarySelect from "Components/PrimarySelect";
import { updateUserProfile, getUserProfile } from "actions/userAction";
import {
  parseAuthToken,
  getAllCountries,
  getCountryByCode,
  getStatesOfCountry,
} from "utils";
import { PrimaryButton } from "Components/CustomButton";
import { Dialog, DialogContent } from "@material-ui/core";
import {
  CustomCancelBtn,
  CustomConfirmDialogActions,
  CustomPrimaryActionBtn,
} from "Components/CustomDialogElements";

const BillingForm = props => {
  const { authToken, userInfo } = useSelector(state => state.UserStore);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    reset,
    errors,
    watch,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      billing_firstName: "",
      billing_lastName: "",
      billing_address1: "",
      billing_address2: "",
      billing_city: "",
      billing_country: "",
      billing_state: "",
      billing_zipCode: "",
      billing_phoneNo: "",
    },
  });
  const watchCountry = watch("billing_country");
  const COUNTRIES = useMemo(() => getAllCountries(), []);
  const STATES = useMemo(() => {
    const states = getStatesOfCountry(watchCountry);
    if (watchCountry) {
      if (Array.isArray(states) && states.length > 0) {
        return states;
      } else {
        const foundCountry = getCountryByCode(watchCountry);
        return foundCountry ? [foundCountry] : [];
      }
    } else return [];
  }, [watchCountry]);

  const [showUpdateProfileModal, setShowUpdateProfileModal] = useState(false);

  // Handle Confirmtion Modal Start
  const handleOpenUpdateProfileModal = event => {
    setShowUpdateProfileModal(true);
  };

  const handleCloseUpdateProfileModal = () => {
    setShowUpdateProfileModal(false);
  };
  // Handle Confirmtion Modal End

  const onSubmit = data => {
    const userId = parseAuthToken(authToken);

    const inputData = {
      _id: userId,
      input: { ...data },
    };

    dispatch(updateUserProfile(inputData));
    handleCloseUpdateProfileModal();
  };

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  useEffect(() => {
    if (userInfo) {
      reset({
        billing_firstName: userInfo.billing_firstName || "",
        billing_lastName: userInfo.billing_lastName || "",
        billing_address1: userInfo.billing_address1 || "",
        billing_address2: userInfo.billing_address2 || "",
        billing_city: userInfo.billing_city || "",
        billing_country: userInfo.billing_country || "",
        billing_state: userInfo.billing_state || "",
        billing_zipCode: userInfo.billing_zipCode || "",
        billing_phoneNo: userInfo.billing_phoneNo || "",
      });
    }
  }, [userInfo]);

  return (
    <>
      <Dialog
        onClose={handleCloseUpdateProfileModal}
        open={showUpdateProfileModal}
      >
        <DialogContent>Are you sure you wish to update profile?</DialogContent>
        <CustomConfirmDialogActions>
          <CustomPrimaryActionBtn onClick={handleSubmit(onSubmit)}>
            Update
          </CustomPrimaryActionBtn>
          <CustomCancelBtn onClick={handleCloseUpdateProfileModal}>
            Cancel
          </CustomCancelBtn>
        </CustomConfirmDialogActions>
      </Dialog>

      <Grid direction="column" container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="billing_firstName"
              rules={{
                required: "First Name cannot be empty",
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="First Name"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.billing_firstName)}
                  helperText={
                    errors.billing_firstName && errors.billing_firstName.message
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="billing_lastName"
              rules={{
                required: "Last Name cannot be empty",
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="Last Name"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.billing_lastName)}
                  helperText={
                    errors.billing_lastName && errors.billing_lastName.message
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="billing_address1"
              rules={{ required: "Address cannot be empty" }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="Address (House Number & Street Name)"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.billing_address1)}
                  helperText={
                    errors.billing_address1 && errors.billing_address1.message
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="billing_address2"
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="Address 2"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.billing_address2)}
                  helperText={
                    errors.billing_address2 && errors.billing_address2.message
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="billing_city"
              rules={{ required: "City cannot be empty" }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="City"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.billing_city)}
                  helperText={
                    errors.billing_city && errors.billing_city.message
                  }
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="billing_country"
              rules={{ required: "Country cannot be empty" }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimarySelect
                  label="Select Country"
                  onChange={e => {
                    onChange(e.target.value);
                    setValue("state", "", { shouldValidate: true });
                  }}
                  value={value}
                  error={
                    errors.billing_country && errors.billing_country.message
                  }
                >
                  <option aria-label="None" value="" />
                  {COUNTRIES.map(country => (
                    <option key={country.isoCode} value={country.isoCode}>
                      {country.name}
                    </option>
                  ))}
                </PrimarySelect>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="billing_state"
              rules={{ required: "State cannot be empty" }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimarySelect
                  label="Select State"
                  disabled={!Boolean(watchCountry)}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  error={errors.billing_state && errors.billing_state.message}
                >
                  <option aria-label="None" value="" />
                  {STATES.length > 0 &&
                    STATES.map(state => (
                      <option key={state.isoCode} value={state.isoCode}>
                        {state.name}
                      </option>
                    ))}
                </PrimarySelect>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="billing_zipCode"
              rules={{ required: "Zip Code cannot be empty" }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="Zip Code"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.billing_zipCode)}
                  helperText={
                    errors.billing_zipCode && errors.billing_zipCode.message
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="billing_phoneNo"
              rules={{ required: "Telephone cannot be empty" }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="Telephone"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.billing_phoneNo)}
                  helperText={
                    errors.billing_phoneNo && errors.billing_phoneNo.message
                  }
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" mt={6}>
            <PrimaryButton
              color="primary"
              variant="contained"
              onClick={handleOpenUpdateProfileModal}
              fullWidth
            >
              Update
            </PrimaryButton>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default BillingForm;
