import { QUESTION_STATUS } from "../../constants";

export const headCells = [
  {
    id: "name",
    label: "NAME",
  },
  {
    id: "totalQuestion",
    label: "TOTAL Q",
  },
  {
    id: "correct",
    label: "CORRECT",
  },
  {
    id: "incorrect",
    label: "INCORRECT",
  },
  {
    id: "omitted",
    label: "OMITTED",
  },
];

export const showBarsArr = [
  {
    name: QUESTION_STATUS["CORRECT"],
    label: QUESTION_STATUS["CORRECT"],
  },
  {
    name: QUESTION_STATUS["INCORRECT"],
    label: QUESTION_STATUS["INCORRECT"],
  },
  {
    name: QUESTION_STATUS["OMITTED"],
    label: QUESTION_STATUS["OMITTED"],
  },
];
