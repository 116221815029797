import * as UIActions from "./uiAction";
import * as ExamApi from "../services/exam";
import { AppConfig } from "../configs";
import { CATEGORY_TYPE } from "../constants";
import { validationErrorAdapter } from "../utils/api-helper";

const { client, axiosClient } = AppConfig;

const MODULE_NAME = "CATEGORY";

export const CATEGORY_LIST_REQUEST = {
  AWAIT: `${MODULE_NAME}/LIST/AWAIT`,
  SUCCESS: `${MODULE_NAME}/LIST/SUCCESS`,
  FAIL: `${MODULE_NAME}/LIST/FAIL`,
};

const EXAM_MODULE_NAME = "EXAM";

export const USER_EXAM_LIST_BY_USER_ID_REQUEST = {
  AWAIT: `${EXAM_MODULE_NAME}/LIST_BY_USER_ID/AWAIT`,
  SUCCESS: `${EXAM_MODULE_NAME}/LIST_BY_USER_ID/SUCCESS`,
  FAIL: `${EXAM_MODULE_NAME}/LIST_BY_USER_ID/FAIL`,
};

export const getCategoryList = filter => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({ type: CATEGORY_LIST_REQUEST.AWAIT });
  try {
    const res = await client.query({
      query: ExamApi.categories,
      variables: { filter },
    });

    dispatch(UIActions.appReady());
    if (res.data && res.data.categories) {
      const categories = res.data.categories;
      let subjects = [];
      let systems = [];
      if (Array.isArray(categories)) {
        categories.map(category => {
          if (category.type === CATEGORY_TYPE.SUBJECT) {
            subjects.push(category);
          } else if (category.type === CATEGORY_TYPE.SYSTEM) {
            systems.push(category);
          }
        });
      }
      dispatch({
        type: CATEGORY_LIST_REQUEST.SUCCESS,
        payload: { subjects, systems },
      });
    }

    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    let errMsg;
    if (err.message) errMsg = err.message;
    else if (err.graphQLErrors)
      errMsg = err.graphQLErrors.map(item => item.message).join(". ");
    else
      errMsg =
        "We are having problem getting category list. Please try again later.";

    dispatch(UIActions.addError(errMsg));
    dispatch({ type: CATEGORY_LIST_REQUEST.FAIL, payload: { error: errMsg } });
  }
};

export const createCategory = data => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: ExamApi.createCategory,
      variables: data,
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors) {
      dispatch(UIActions.addError(validationErrorAdapter(err.graphQLErrors)));
    } else if (err.message) dispatch(UIActions.addError(err.message));
    else
      dispatch(UIActions.addError("There is some error with create category"));
  }
};

export const updateCategory = data => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: ExamApi.updateCategory,
      variables: data,
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors) {
      dispatch(UIActions.addError(validationErrorAdapter(err.graphQLErrors)));
    } else if (err.message) dispatch(UIActions.addError(err.message));
    else
      dispatch(
        UIActions.addError("There is some error with updating category")
      );
  }
};

export const removeCategory = (id, permanent) => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: ExamApi.removeCategory,
      variables: { _id: id, permanent },
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else dispatch(UIActions.addError("Error remove category"));
  }
};

export const getQuestionList = (filter, byAdmin = false) => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.query({
      query: ExamApi.questions,
      variables: { filter },
    });
    dispatch(UIActions.appReady());
    if (
      !byAdmin &&
      res &&
      res.data &&
      res.data.questions &&
      res.data.questions.length === 0
    )
      dispatch(UIActions.addError("No question(s) found"));
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.message) dispatch(UIActions.addError(err.message));
    else if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else
      dispatch(
        UIActions.addError(
          "We are having problem getting question list. Please try again later."
        )
      );
  }
};

export const getQuestionById = id => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.query({
      query: ExamApi.questionById,
      variables: { _id: id },
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.message) dispatch(UIActions.addError(err.message));
    else if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else
      dispatch(
        UIActions.addError(
          "Unable to retrieve question. Please try again later."
        )
      );
  }
};

export const createQuestion = data => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: ExamApi.createQuestion,
      variables: data,
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else dispatch(UIActions.addError("Error creating question"));
  }
};

export const updateQuestion = data => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: ExamApi.updateQuestion,
      variables: data,
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else dispatch(UIActions.addError("Error updating question"));
  }
};

export const removeQuestion = (id, permanent) => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: ExamApi.removeQuestion,
      variables: { _id: id, permanent },
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else dispatch(UIActions.addError("Error removing question"));
  }
};

export const createTest = data => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const { data: examData } = await client.mutate({
      mutation: ExamApi.createExam,
      variables: data,
    });
    let res;
    if (examData && examData.createExam._id) {
      res = await client.mutate({
        mutation: ExamApi.createUserExam,
        variables: {
          input: { exam_id: examData.createExam._id, status: "new" },
        },
      });
    }

    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else dispatch(UIActions.addError("Error creating test"));
  }
};

export const updateUserExam = data => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: ExamApi.UPDATE_USER_EXAM,
      variables: data,
    });

    dispatch(UIActions.showSuccess("Test updated successfully."));
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else dispatch(UIActions.addError("Error updating test"));
  }
};

export const retakeTest = id => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: ExamApi.createUserExam,
      variables: { input: { exam_id: id, status: "new" } },
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else dispatch(UIActions.addError("Error creating test"));
  }
};

export const removeTest = id => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: ExamApi.removeUserExam,
      variables: { _id: id },
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else dispatch(UIActions.addError("Error creating exam"));
  }
};

export const getUserExamList = filter => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.query({
      query: ExamApi.userExams,
      variables: { filter },
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else
      dispatch(
        UIActions.addError(
          "We are having problem getting exam list. Please try again later."
        )
      );
  }
};

export const getUserExamListByUserId = data => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({
    type: USER_EXAM_LIST_BY_USER_ID_REQUEST.AWAIT,
  });
  try {
    const res = await client.query({
      query: ExamApi.USER_EXAMS_BY_USER_ID,
      variables: data,
    });
    dispatch({
      type: USER_EXAM_LIST_BY_USER_ID_REQUEST.SUCCESS,
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch({
      type: USER_EXAM_LIST_BY_USER_ID_REQUEST.FAIL,
    });
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else
      dispatch(
        UIActions.addError(
          "We are having problem getting exam list. Please try again later."
        )
      );
  }
};

export const getUserExam = filter => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.query({
      query: ExamApi.userExam,
      variables: { filter },
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else
      dispatch(
        UIActions.addError(
          "We are having problem getting test. Please try again later."
        )
      );
  }
};

export const startExam = id => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: ExamApi.startExam,
      variables: { _id: id },
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else
      dispatch(
        UIActions.addError(
          "We are having problem starting test. Please try again later."
        )
      );
  }
};

export const pauseExam = id => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: ExamApi.pauseExam,
      variables: { _id: id },
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else
      dispatch(
        UIActions.addError(
          "We are having problem pausing test. Please try again later."
        )
      );
  }
};

export const resumeExam = id => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: ExamApi.resumeExam,
      variables: { _id: id },
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else
      dispatch(
        UIActions.addError(
          "We are having problem resume test. Please try again later."
        )
      );
  }
};

export const endExam = id => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: ExamApi.endExam,
      variables: { _id: id },
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else
      dispatch(
        UIActions.addError(
          "We are having problem ending test. Please try again later."
        )
      );
  }
};

export const enterQuestion = (userExam_id, question_id) => async dispatch => {
  dispatch(UIActions.appLoading());

  try {
    const res = await client.mutate({
      mutation: ExamApi.enterQuestion,
      variables: { question_id: question_id, userExam_id: userExam_id },
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else
      dispatch(
        UIActions.addError("Something unexpected happen. Please try again.")
      );
  }
};

export const pauseQuestion = (userExam_id, question_id) => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: ExamApi.pauseQuestion,
      variables: { question_id: question_id, userExam_id: userExam_id },
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else
      dispatch(
        UIActions.addError("Something unexpected happen. Please try again.")
      );
  }
};
export const resumeQuestion = (userExam_id, question_id) => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: ExamApi.resumeQuestion,
      variables: { question_id: question_id, userExam_id: userExam_id },
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else
      dispatch(
        UIActions.addError("Something unexpected happen. Please try again.")
      );
  }
};

export const omitAndEnterQuestion = (
  omitUserAnswer_id,
  userExam_id,
  question_id
) => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: ExamApi.omitAndEnterQuestion,
      variables: {
        omitUserAnswer_id: omitUserAnswer_id,
        question_id: question_id,
        userExam_id: userExam_id,
      },
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else
      dispatch(
        UIActions.addError("Something unexpected happen. Please try again.")
      );
  }
};

export const flagQuestion = (id, flag) => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: ExamApi.flagQuestion,
      variables: { _id: id, flag: flag },
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else
      dispatch(
        UIActions.addError("Something unexpected happen. Please try again.")
      );
  }
};

export const answerQuestion = (id, answer) => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: ExamApi.answerQuestion,
      variables: { _id: id, answer: answer },
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else
      dispatch(
        UIActions.addError("Something unexpected happen. Please try again.")
      );
  }
};

export const omitQuestion = id => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: ExamApi.omitQuestion,
      variables: { _id: id },
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else
      dispatch(
        UIActions.addError("Something unexpected happen. Please try again.")
      );
  }
};

export const examAnalysis = id => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: ExamApi.examAnalysis,
      variables: { _id: id },
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else
      dispatch(
        UIActions.addError(
          "We are having problem getting analysis. Please try again later."
        )
      );
  }
};

export const importQuestion = file => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const formData = new FormData();
    formData.append("file", file, "qbank.xlsx");
    formData.append("module", "data-import");
    formData.append("method", "question");

    const { data } = await axiosClient.post("upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (err) {
    dispatch(UIActions.appReady());
    dispatch(UIActions.addError("Something wrong when import"));
  }
};

export const getUserExamAnswer = id => async dispatch => {
  dispatch(UIActions.appLoading());

  try {
    const res = await client.query({
      query: ExamApi.USER_EXAM_ANSWER,
      variables: { _id: id },
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.message) dispatch(UIActions.addError(err.message));
    else if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else
      dispatch(
        UIActions.addError(
          "Unable to retrieve question. Please try again later."
        )
      );
  }
};
