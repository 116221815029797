import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import styles from "../FlashcardModal/styles";
import BackViewCardFooter from "./BackViewCardFooter";

const useStyles = makeStyles(theme => styles(theme));

const DoubleSideView = props => {
  const { isDarkMode, flashcard, showFrontView, toggleShowFrontView } = props;
  const classes = useStyles();

  return (
    <div className={classes.doubleSideViewCont}>
      <div className={classes.sideCardCont}>
        <div
          className={clsx(
            isDarkMode ? classes.cardFaceDark : classes.cardFaceLight,
            classes.cardFace,

            isDarkMode
              ? classes.cardFaceActiveDark
              : classes.cardFaceActiveLight,

            classes.mlAuto
          )}
        >
          Front
        </div>
        <div
          className={clsx(
            isDarkMode ? classes.cardBodyDark : classes.cardBodyLight,
            classes.cardBody
          )}
          dangerouslySetInnerHTML={{
            __html: flashcard.front,
          }}
        />
      </div>
      <div className={classes.sideCardCont}>
        <div
          className={clsx(
            classes.cardFaceLight,
            classes.cardFace,
            classes.mlAuto,
            !showFrontView &&
              (isDarkMode
                ? classes.cardFaceActiveDark
                : classes.cardFaceActiveLight)
          )}
          onClick={toggleShowFrontView}
        >
          Back
        </div>
        <div
          className={clsx(
            isDarkMode ? classes.cardBodyDark : classes.cardBodyLight,
            classes.cardBody,
            showFrontView ? classes.backCardInactive : classes.backViewCardBody
          )}
          dangerouslySetInnerHTML={{
            __html: showFrontView ? "" : flashcard.back,
          }}
          onClick={showFrontView ? toggleShowFrontView : undefined}
        />
        {!showFrontView && (
          <BackViewCardFooter isDarkMode={isDarkMode} tags={flashcard.tags} />
        )}
      </div>
    </div>
  );
};

export default DoubleSideView;
