const styles = theme => ({
  paperRoot: {
    padding: "1rem",
  },
  heading: {
    marginTop: 0,
    marginBottom: "2rem",
  },
  accordionHeaderCont: {
    display: "flex",
  },
  accordionHeaderTxt: {
    color: theme.palette.secondary.main,
    marginLeft: 6,
  },
});

export default styles;
