import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { activateUser } from "../../actions/userAction";

const ActivateUserPage = () => {
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const { id, code } = params;
    const data = {
      _id: id,
      code,
    };

    dispatch(activateUser(data));
  }, [dispatch]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      fontSize="1.5rem"
      height="100vh"
    >
      Activating User...
    </Box>
  );
};

export default ActivateUserPage;
