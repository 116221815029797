const styles = theme => ({
  barContainer: {
    minWidth: 150,
    maxWidth: 300,
    height: 6,
    display: "flex",
    overflow: "hidden",
    fontSize: ".75rem",
    backgroundColor: "#e9ecef",
    borderRadius: ".25rem",
  },
  progressBar: {
    height: 6,
    transition: "width .6s ease",
  },
  greenBG: {
    backgroundColor: "#69c869 !important",
  },
  redBG: {
    backgroundColor: "#f05a5b !important",
  },
  blueBG: {
    backgroundColor: "#689bf7 !important",
  },
});

export default styles;
