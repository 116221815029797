import {
  CONTENT_PADDING,
  FLASHCARD_CONTENT_STYLING,
  FLASHCARD_CONT_STYLING,
} from "../../styles/constants";

const styles = theme => ({
  // Flashcards Page style
  root: {
    ...CONTENT_PADDING(theme),
    minHeight: "90vh",
  },
  topContainer: {
    width: "100%",
  },
  filterMenu: {
    width: 275,
  },
  filterIcon: {
    marginLeft: 16,
    fontSize: 20,
    color: props =>
      props.applyCategoriesFilter ? theme.palette.secondary.main : "#757575",
    marginBottom: 2,
    cursor: "pointer",
  },
  checkboxLabel: {
    fontSize: 14,
  },
  createCardBtn: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    height: 45,
    width: 130,
    maxWidth: "100%",
    marginTop: 10,
    border: "1px solid #ddd",
    borderRadius: 25,
    backgroundColor: "#fff",
    color: theme.palette.secondary.main,
    cursor: "pointer",
    fontSize: "1.1em",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  bottomContainer: {
    marginTop: 20,
  },
  card: {
    ...FLASHCARD_CONT_STYLING,
  },
  cardContent: {
    ...FLASHCARD_CONTENT_STYLING,
  },
  notFoundTxt: {
    fontSize: "1.5em",
    fontWeight: 300,
    marginTop: "1.5rem",
  },
});

export default styles;
