import * as UIActions from "./uiAction";

export const handleAxiosError = (
  dispatch,
  err,
  customErrMsg = '"We are having problem. Please try again later."'
) => {
  dispatch(UIActions.appReady());
  let errMsg;
  if (err.message) errMsg = err.message;
  else if (err.graphQLErrors)
    errMsg = err.graphQLErrors.map(item => item.message).join(". ");
  else errMsg = customErrMsg;

  dispatch(UIActions.addError(errMsg));
  return errMsg;
};
