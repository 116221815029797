import React, { useMemo, useCallback } from "react";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useForm, Controller } from "react-hook-form/dist/index.ie11";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import useFormPersist from "react-hook-form-persist";

import PrimaryTextField from "../../PrimaryTextField";
import PrimarySelect from "../../PrimarySelect";
import { CAREER_STATUS_LIST, EDUCATIONAL_GROUP_LIST } from "../constants";
import * as UserActions from "actions/userAction";
import { EMAIL_REGEX } from "../../../constants";
import { PrimaryButton } from "../../CustomButton";
import { SIGNUP_FORM } from "../../../localStorage/constants";
import { getAllCountries, getCountryByCode, getStatesOfCountry } from "utils";

const SignupForm = ({ register }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { handleSubmit, control, errors, watch, setValue, getValues } = useForm(
    {
      mode: "onChange",
      defaultValues: {
        email: "",
        confirmEmail: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        address1: "",
        address2: "",
        city: "",
        country: "",
        state: "",
        zipCode: "",
        phoneNo: "",
        educationalGroup: "",
        careerStatus: "",
        graduatingSchool: "",
      },
    }
  );

  useFormPersist(
    SIGNUP_FORM,
    { watch, setValue },
    {
      exclude: ["password", "confirmPassword"],
      storage: window.localStorage,
    }
  );
  const watchCountry = watch("country");
  const COUNTRIES = useMemo(() => getAllCountries(), []);
  const STATES = useMemo(() => {
    const states = getStatesOfCountry(watchCountry);
    if (watchCountry) {
      if (Array.isArray(states) && states.length > 0) {
        return states;
      } else {
        const foundCountry = getCountryByCode(watchCountry);
        return foundCountry ? [foundCountry] : [];
      }
    } else return [];
  }, [watchCountry]);

  const onSubmit = async data => {
    const token = await verifyRecaptcha();
    await register({
      recaptcha: token,
      ...data,
      confirmEmail: undefined,
      confirmPassword: undefined,
    });
  };

  const verifyRecaptcha = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }
    return await executeRecaptcha("signup_page");
  }, [executeRecaptcha]);

  return (
    <>
      <Grid direction="column" container>
        <Grid item xs={12}>
          <Box fontSize="1.8rem" mb={2}>
            Profile
          </Box>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="email"
              rules={{
                required: "Email cannot be empty",
                pattern: {
                  value: EMAIL_REGEX,
                  message: "Invalid Email Format",
                },
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="Email"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.email)}
                  helperText={errors.email && errors.email.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="confirmEmail"
              rules={{
                required: "Email cannot be empty",
                pattern: {
                  value: EMAIL_REGEX,
                  message: "Invalid Email Format",
                },
                validate: value =>
                  value === getValues("email") ||
                  "Confirm email does not match with email",
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="Confirm Email"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.confirmEmail)}
                  helperText={
                    errors.confirmEmail && errors.confirmEmail.message
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="password"
              rules={{
                required: "Required",
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="Password"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.password)}
                  helperText={errors.password && errors.password.message}
                  type={"password"}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="confirmPassword"
              rules={{
                required: "Required",
                validate: value =>
                  value === getValues("password") ||
                  "Confirm password does not match with password",
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="Confirm Password"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.confirmPassword)}
                  helperText={
                    errors.confirmPassword && errors.confirmPassword.message
                  }
                  type={"password"}
                  inputProps={{ autoComplete: "new-password" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="firstName"
              rules={{
                required: "First Name cannot be empty",
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="First Name"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.firstName)}
                  helperText={errors.firstName && errors.firstName.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="lastName"
              rules={{
                required: "Last Name cannot be empty",
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="Last Name"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.lastName)}
                  helperText={errors.lastName && errors.lastName.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="address1"
              rules={{ required: "Address cannot be empty" }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="Address (PO Box is not allowed)"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.address1)}
                  helperText={errors.address1 && errors.address1.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="address2"
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="Address 2"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.address2)}
                  helperText={errors.address2 && errors.address2.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="city"
              rules={{ required: "City cannot be empty" }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="City"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.city)}
                  helperText={errors.city && errors.city.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="country"
              rules={{ required: "Country cannot be empty" }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimarySelect
                  label="Select Country"
                  onChange={e => {
                    onChange(e.target.value);
                    setValue("state", "", { shouldValidate: true });
                  }}
                  value={value}
                  error={errors.country && errors.country.message}
                >
                  <option aria-label="None" value="" />
                  {COUNTRIES.map(country => (
                    <option key={country.isoCode} value={country.isoCode}>
                      {country.name}
                    </option>
                  ))}
                </PrimarySelect>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="state"
              rules={{ required: "State cannot be empty" }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimarySelect
                  label="Select State"
                  disabled={!Boolean(watchCountry)}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  error={errors.state && errors.state.message}
                >
                  <option aria-label="None" value="" />
                  {STATES.length > 0 &&
                    STATES.map(state => (
                      <option key={state.isoCode} value={state.isoCode}>
                        {state.name}
                      </option>
                    ))}
                </PrimarySelect>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="zipCode"
              rules={{ required: "Zip Code cannot be empty" }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="Zip Code"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.zipCode)}
                  helperText={errors.zipCode && errors.zipCode.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="phoneNo"
              rules={{ required: "Telephone cannot be empty" }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="Telephone"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.phoneNo)}
                  helperText={errors.phoneNo && errors.phoneNo.message}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box fontSize="1.8rem" mt={4} mb={2}>
            Educational Status
          </Box>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="educationalGroup"
              rules={{ required: "Group cannot be empty" }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimarySelect
                  label="Select Group"
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  error={
                    errors.educationalGroup && errors.educationalGroup.message
                  }
                >
                  <option aria-label="None" value="" />
                  {EDUCATIONAL_GROUP_LIST.map(state => (
                    <option key={state.label} value={state.value}>
                      {state.label}
                    </option>
                  ))}
                </PrimarySelect>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="careerStatus"
              rules={{ required: "Career status cannot be empty" }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimarySelect
                  label="Select Career Status"
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  error={errors.careerStatus && errors.careerStatus.message}
                >
                  <option aria-label="None" value="" />
                  {CAREER_STATUS_LIST.map(state => (
                    <option key={state.label} value={state.value}>
                      {state.label}
                    </option>
                  ))}
                </PrimarySelect>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="graduatingSchool"
              rules={{ required: "Graduating School cannot be empty" }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="Graduating School"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.graduatingSchool)}
                  helperText={
                    errors.graduatingSchool && errors.graduatingSchool.message
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" mt={3}>
              <PrimaryButton
                color="primary"
                variant="contained"
                fullWidth
                onClick={handleSubmit(onSubmit)}
              >
                CREATE ACCOUNT
              </PrimaryButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  register: body => dispatch(UserActions.register(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupForm);
