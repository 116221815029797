import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";

export const CustomMenuItem = withStyles(theme => ({
  root: {
    height: "2.8em",
  },
  selected: {
    backgroundColor: "rgba(0,0,0,.04)!important",
  },
}))(MenuItem);

export const CustomMenu = withStyles(theme => ({
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  paper: {
    maxWidth: "80vw",
    minWidth: 210,
    boxShadow:
      "0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%)",
  },
}))(Menu);
