import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import PhotoSizeSelectActualOutlinedIcon from "@material-ui/icons/PhotoSizeSelectActualOutlined";
import SdStorageOutlinedIcon from "@material-ui/icons/SdStorageOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import TitleOutlinedIcon from "@material-ui/icons/TitleOutlined";
import LinkIcon from "@material-ui/icons/Link";
import Box from "@material-ui/core/Box";

import * as MediaAction from "../../../actions/mediaAction";
import {
  CustomConfirmDialogActions,
  CustomActionBtn,
  CustomCancelBtn,
} from "../../../Components/CustomDialogElements";
import { parseDateHtml, parseQuestionHtml } from "../../../utils";
import {
  CustomDeleteIcon,
  CustomEditIcon,
} from "../../../Components/CustomActionIcons";
import { CustomTableSortLabel } from "../../../Components/CustomTableElements";
import { parseMediaTableData, sortMedias } from "./helper";
import useStyles from "./styles";
import { COURSE_APP_SUB_ROUTE } from "../../../routes/constants";
import MUICustomHeader from "../../../Components/MUICustomHeader";
import FileImage from "./components/FileImage";
import { RESOURCE_STATUS } from "../../../constants";
import ImagePreviewModal from "../../TestDetailPage/modal/themedModal/ImagePreviewModal";

const ManageMedia = ({ getMediaList, removeMedia }) => {
  const classes = useStyles();
  const history = useHistory();

  const [medias, setMedias] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(undefined);
  const [isAsc, setIsAsc] = useState(false);
  const [orderBy, setOrderBy] = useState("date");
  const [showImagePreview, setShowImagePreview] = useState({
    show: false,
    imgAttr: null,
  });

  const onSort = value => {
    if (orderBy === value) {
      setIsAsc(!isAsc);
    } else {
      setOrderBy(value);
    }
  };

  // Handle Confirmtion Modal Start
  const handleOpenDeleteModal = event => {
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };
  // Handle Confirmtion Modal End

  const fetchMediaList = async () => {
    const filter = { status: RESOURCE_STATUS.ACTIVE };
    const res = await getMediaList(filter);
    if (res && res.medias) {
      setMedias(res.medias);
    }
  };

  const onRemoveMedia = async () => {
    const data = await removeMedia(selectedMedia._id);
    if (data) {
      setMedias(medias =>
        medias.filter(media => media._id !== selectedMedia._id)
      );
    }
    handleCloseDeleteModal();
  };

  useEffect(() => {
    fetchMediaList();
  }, []);

  if (!medias) return null;

  const currentMedias = sortMedias(medias, isAsc, orderBy);

  return (
    <Fragment>
      {/* Image Preview Modal */}
      <ImagePreviewModal
        visible={showImagePreview.show}
        imgAttr={showImagePreview.imgAttr}
        isDarkMode={false}
        customPos={true}
        onClose={() => setShowImagePreview({ show: false, imgAttr: null })}
        showFlashcardFunc={false}
      />

      {/* Delete Media Confirmation Modal */}
      <Dialog onClose={handleCloseDeleteModal} open={showDeleteModal}>
        <DialogContent>
          Are you sure you wish to delete this media?
        </DialogContent>
        <CustomConfirmDialogActions>
          <CustomActionBtn onClick={onRemoveMedia}>Delete</CustomActionBtn>
          <CustomCancelBtn onClick={handleCloseDeleteModal}>
            Cancel
          </CustomCancelBtn>
        </CustomConfirmDialogActions>
      </Dialog>
      <div className="pageCont manageMediaPage">
        <div className="bodyCont">
          <div className="pageContent">
            <MUIDataTable
              data={parseMediaTableData(currentMedias)}
              columns={[
                {
                  name: "File",
                  options: {
                    customHeadRender: columnMeta => {
                      return (
                        <MUICustomHeader label={columnMeta.label}>
                          <>
                            {columnMeta.label}

                            <PhotoSizeSelectActualOutlinedIcon
                              className={classes.thIcon}
                              color="secondary"
                            />
                          </>
                        </MUICustomHeader>
                      );
                    },
                    customBodyRenderLite: dataIndex => {
                      let url = currentMedias[dataIndex].url;
                      let filename = currentMedias[dataIndex].filename;
                      return (
                        <FileImage
                          url={url}
                          filename={filename}
                          setShowImagePreview={setShowImagePreview}
                        />
                      );
                    },
                  },
                },
                {
                  name: "File Size",
                  options: {
                    customHeadRender: columnMeta => {
                      return (
                        <MUICustomHeader label={columnMeta.label}>
                          <>
                            {columnMeta.label}
                            <SdStorageOutlinedIcon
                              className={classes.thIcon}
                              color="secondary"
                            />
                          </>
                        </MUICustomHeader>
                      );
                    },
                  },
                },
                {
                  name: "Author",
                  options: {
                    customHeadRender: columnMeta => {
                      return (
                        <MUICustomHeader label={columnMeta.label}>
                          <>
                            {columnMeta.label}
                            <PersonOutlineOutlinedIcon
                              className={classes.thIcon}
                              color="secondary"
                            />
                          </>
                        </MUICustomHeader>
                      );
                    },
                  },
                },
                {
                  name: "Attached to",
                  options: {
                    customHeadRender: columnMeta => {
                      return (
                        <th
                          className="MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-92 MUIDataTableHeadCell-fixedHeader-93"
                          scope="col"
                          data-colindex="1"
                          key={columnMeta.label}
                        >
                          <span className="MUIDataTableHeadCell-contentWrapper-102">
                            <span className="MuiButton-label">
                              <CustomTableSortLabel
                                active={orderBy === "attach"}
                                direction={isAsc ? "asc" : "desc"}
                                onClick={() => onSort("attach")}
                              >
                                <div
                                  className="MUIDataTableHeadCell-sortAction-97"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#313131",
                                  }}
                                >
                                  <div className="MUIDataTableHeadCell-data-96">
                                    {columnMeta.label}
                                  </div>
                                  <PublishOutlinedIcon
                                    className={classes.thIcon}
                                    color="secondary"
                                  />
                                </div>
                              </CustomTableSortLabel>
                            </span>
                          </span>
                        </th>
                      );
                    },
                    customBodyRenderLite: dataIndex => {
                      const media = currentMedias[dataIndex];
                      let jsx, url;
                      // Case Topic
                      if (media.lesson) {
                        const {
                          lesson: { title, _id },
                        } = media;
                        jsx = <div>{title}</div>;
                        url = `${COURSE_APP_SUB_ROUTE}/manage-topic/${_id}/edit`;
                      } // Case Question
                      else if (media.question) {
                        const {
                          question: { question, _id },
                        } = media;
                        jsx = parseQuestionHtml(question);
                        url = `${COURSE_APP_SUB_ROUTE}/manage-question/${_id}/edit`;
                      } else {
                        jsx = <div>{"N/A"}</div>;
                      }

                      return (
                        <Fragment>
                          <Box display="flex" alignItems="center">
                            <Box
                              className="clickable"
                              mr={1}
                              display="flex"
                              alignItems="center"
                              onClick={() => history.push(url)}
                            >
                              <LinkIcon color="secondary" fontSize="small" />
                            </Box>

                            <div style={{ width: 240, wordWrap: "break-word" }}>
                              {jsx}
                            </div>
                          </Box>
                        </Fragment>
                      );
                    },
                  },
                },
                {
                  name: "Date",
                  options: {
                    customHeadRender: columnMeta => {
                      return (
                        <th
                          className="MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-92 MUIDataTableHeadCell-fixedHeader-93"
                          scope="col"
                          data-colindex="1"
                          key={columnMeta.label}
                        >
                          <span className="MUIDataTableHeadCell-contentWrapper-102">
                            <span className="MuiButton-label">
                              <CustomTableSortLabel
                                active={orderBy === "date"}
                                direction={isAsc ? "asc" : "desc"}
                                onClick={() => onSort("date")}
                              >
                                <div
                                  className="MUIDataTableHeadCell-sortAction-97"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#313131",
                                  }}
                                >
                                  <div className="MUIDataTableHeadCell-data-96">
                                    {columnMeta.label}
                                  </div>
                                  <CalendarTodayOutlinedIcon
                                    className={classes.thIcon}
                                    color="secondary"
                                  />
                                </div>
                              </CustomTableSortLabel>
                            </span>
                          </span>
                        </th>
                      );
                    },
                    customBodyRenderLite: dataIndex => {
                      let val = currentMedias[dataIndex]._createdOn;
                      return parseDateHtml(val);
                    },
                  },
                },
                {
                  name: "Title",
                  options: {
                    customHeadRender: columnMeta => {
                      return (
                        <MUICustomHeader label={columnMeta.label}>
                          <>
                            {columnMeta.label}
                            <TitleOutlinedIcon
                              className={classes.thIcon}
                              color="secondary"
                            />
                          </>
                        </MUICustomHeader>
                      );
                    },
                  },
                },
                {
                  name: "Action",
                  options: {
                    customHeadRender: columnMeta => {
                      return (
                        <MUICustomHeader label={columnMeta.label}>
                          <>{columnMeta.label}</>
                        </MUICustomHeader>
                      );
                    },
                    customBodyRenderLite: dataIndex => {
                      return (
                        <div className={"d-flex"}>
                          <CustomEditIcon
                            onClickHandler={() =>
                              history.push(
                                `${COURSE_APP_SUB_ROUTE}/manage-media/${currentMedias[dataIndex]._id}/edit`
                              )
                            }
                          />
                          <CustomDeleteIcon
                            onClickHandler={() => {
                              setSelectedMedia(currentMedias[dataIndex]);
                              handleOpenDeleteModal();
                            }}
                            style={{ marginLeft: 8 }}
                          />
                        </div>
                      );
                    },
                  },
                },
              ]}
              options={{
                download: false,
                print: false,
                selectableRows: "none",
                customSearch: (searchQuery, currentRow, columns) => {
                  // Only search Title which is last second column
                  return currentRow[currentRow.length - 2]
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase());
                },
                setRowProps: (row, dataIndex, rowIndex) => {
                  return {
                    style: {
                      backgroundColor:
                        rowIndex % 2 !== 0 ? "#fff" : "rgba(0, 0, 0, 0.04)",
                    },
                  };
                },
                // onRowsDelete: (rowsDeleted, data, newTableData) => {
                //   (async () => {
                //     const deletedIds = [];
                //     await Promise.all(
                //       rowsDeleted.data.map(async ({ dataIndex }) => {
                //         const data = await removeMedia(
                //           currentMedias[dataIndex]._id
                //         );
                //         if (data) {
                //           deletedIds.push(currentMedias[dataIndex]._id);
                //         }
                //       })
                //     );

                //     setMedias(medias =>
                //       medias.filter(media => !deletedIds.includes(media._id))
                //     );
                //   })();

                //   return false;
                // },
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  getMediaList: filter => dispatch(MediaAction.getMediaList(filter)),
  updateMedia: () => dispatch(MediaAction.updateMedia()),
  removeMedia: id => dispatch(MediaAction.removeMedia(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageMedia);
