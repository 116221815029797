export const sortUserExams = (exams, isAsc, orderBy) => {
  return exams
    ? [...exams].sort((a, b) => {
        const scoreA = (a._totalScore / a.exam.questions.length) * 100;
        const scoreB = (b._totalScore / b.exam.questions.length) * 100;

        const aSubject =
          a.exam.categories.length === 0
            ? "N/A"
            : a.exam.categories.length > 1
            ? "Multiple"
            : a.exam.categories[0].category.name;
        const bSubject =
          b.exam.categories.length === 0
            ? "N/A"
            : b.exam.categories.length > 1
            ? "Multiple"
            : b.exam.categories[0].category.name;

        const aSystem =
          a.exam.subCategories.length === 0
            ? "N/A"
            : a.exam.subCategories.length > 1
            ? "Multiple"
            : a.exam.subCategories[0].category.name;

        const bSystem =
          b.exam.subCategories.length === 0
            ? "N/A"
            : b.exam.subCategories.length > 1
            ? "Multiple"
            : b.exam.subCategories[0].category.name;
        if (isAsc) {
          switch (orderBy) {
            case "score":
              return scoreA > scoreB ? 1 : -1;
            case "name":
              return a._userExamNo > b._userExamNo ? 1 : -1;
            case "date":
              return a._createdOn > b._createdOn ? 1 : -1;
            case "mode":
              const aMode = a.exam.tutor
                ? a.exam.timed
                  ? "Tutor Timed"
                  : "Tutor"
                : a.exam.timed
                ? "Timed"
                : "Untimed";
              const bMode = b.exam.tutor
                ? b.exam.timed
                  ? "Tutor Timed"
                  : "Tutor"
                : b.exam.timed
                ? "Timed"
                : "Untimed";
              return aMode > bMode ? 1 : -1;
            case "subjects":
              return aSubject > bSubject ? 1 : -1;
            case "systems":
              return aSystem > bSystem ? 1 : -1;
            case "qs":
              return a.exam.questions.length > b.exam.questions.length ? 1 : -1;
            case "questionMode":
              return a.exam.question_mode > b.exam.question_mode ? 1 : -1;
          }
        } else {
          switch (orderBy) {
            case "score":
              return scoreA < scoreB ? 1 : -1;
            case "name":
              return a._userExamNo < b._userExamNo ? 1 : -1;
            case "date":
              return a._createdOn < b._createdOn ? 1 : -1;
            case "mode":
              const aMode = a.exam.tutor
                ? a.exam.timed
                  ? "Tutor Timed"
                  : "Tutor"
                : a.exam.timed
                ? "Timed"
                : "Untimed";
              const bMode = b.exam.tutor
                ? b.exam.timed
                  ? "Tutor Timed"
                  : "Tutor"
                : b.exam.timed
                ? "Timed"
                : "Untimed";
              return aMode < bMode ? 1 : -1;
            case "subjects":
              return aSubject < bSubject ? 1 : -1;
            case "systems":
              return aSystem < bSystem ? 1 : -1;
            case "qs":
              return a.exam.questions.length < b.exam.questions.length ? 1 : -1;
            case "questionMode":
              return a.exam.question_mode < b.exam.question_mode ? 1 : -1;
          }
        }
      })
    : [];
};
