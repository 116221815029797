import React, { useState, Fragment } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {
  CustomConfirmDialogActions,
  CustomActionBtn,
  CustomCancelBtn,
} from "../../Components/CustomDialogElements";

import styles from "./FlashcardModal/styles";

const FlashcardModalMenu = withStyles({
  paper: {
    boxShadow: "0 2px 8px 0 rgb(0 0 0 / 25%)",
    color: "#000",
    borderRadius: 2.34,
    background: "#fff",
    width: 150,
    "& .MuiListItem-root": {
      backgroundColor: "#fff",
    },
    "& .Mui-focusVisible": {
      backgroundColor: "#fff",
    },
  },
})(props => <Menu {...props} getContentAnchorEl={null} />);

const FlashcardModalMenuItem = withStyles(theme => ({
  root: {
    "&:hover": {
      backgroundColor: "#fff",
      "& .MuiSvgIcon-root, & span": {
        color: theme.palette.secondary.main,
      },
    },
  },
}))(MenuItem);
const useStyles = makeStyles(theme => styles(theme));

const FlashcardMenu = props => {
  const {
    anchorEl,
    anchorOrigin = {
      vertical: "bottom",
      horizontal: "right",
    },
    transformOrigin = {
      vertical: "top",
      horizontal: "right",
    },
    handleCloseMenu,
    currentFlashcard,
    handleGoBack,
    onClickEditBtnHandler,
    onRemoveFlashcard,
  } = props;
  const classes = useStyles();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  // Handle Confirmtion ConfirmationModal Start
  const handleOpenConfirmationModal = event => {
    setShowConfirmationModal(true);
  };

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
  };
  // Handle Confirmtion Modal End

  const onClickDeleteBtnHandler = () => {
    handleCloseMenu();
    handleOpenConfirmationModal();
  };

  const handleRemoveFlashcard = async () => {
    const res = await onRemoveFlashcard(currentFlashcard._id);
    if (res && res.removeFlashcard) {
      handleCloseConfirmationModal();
      handleGoBack();
    }
    return;
  };

  return (
    <Fragment>
      {/* Menu containg Edit button and Delete button Start here */}
      <FlashcardModalMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <FlashcardModalMenuItem onClick={onClickEditBtnHandler}>
          <div className={classes.menuItemCont}>
            <EditOutlinedIcon className={classes.menuItemIcon} />
            <span className={classes.menuItemText}>Edit</span>
          </div>
        </FlashcardModalMenuItem>
        <FlashcardModalMenuItem onClick={onClickDeleteBtnHandler}>
          <div className={classes.menuItemCont}>
            <DeleteOutlinedIcon className={classes.menuItemIcon} />
            <span className={classes.menuItemText}>Delete</span>
          </div>
        </FlashcardModalMenuItem>
      </FlashcardModalMenu>
      {/* Menu containg Edit button and Delete button End here */}

      {/* Delete Confirmation Dialog Start here */}
      <Dialog
        onClose={handleCloseConfirmationModal}
        open={showConfirmationModal}
      >
        <DialogContent>
          Are you sure you wish to delete this flashcard?
        </DialogContent>
        <CustomConfirmDialogActions>
          <CustomActionBtn
            className={classes.deleteBtn}
            onClick={handleRemoveFlashcard}
          >
            Delete
          </CustomActionBtn>
          <CustomCancelBtn
            onClick={handleCloseConfirmationModal}
            className={classes.cancelBtn}
          >
            Cancel
          </CustomCancelBtn>
        </CustomConfirmDialogActions>
      </Dialog>
      {/* Delete Confirmation Dialog End here */}
    </Fragment>
  );
};

export default FlashcardMenu;
