import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import LabelOutlinedIcon from "@material-ui/icons/LabelOutlined";
import RedeemOutlinedIcon from "@material-ui/icons/RedeemOutlined";
import DataUsageOutlinedIcon from "@material-ui/icons/DataUsageOutlined";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { urlAdapter } from "../../../../routes/helper";

import { parseDateHtml } from "../../../../utils";
import useStyles from "../styles";
import MUICustomHeader from "../../../../Components/MUICustomHeader";
import { CustomTableSortLabel } from "../../../../Components/CustomTableElements";
import { selectVoucherList } from "../../../../selectors/voucher";
import {
  getVoucherList,
  removeVoucher,
} from "../../../../actions/voucherAction";
import { sortList } from "../../../../utils/parser-helper";
import {
  CREATE_DISCOUNT_CODE_URL,
  EDIT_DISCOUNT_CODE_URL,
  idParams,
} from "../../../../routes/constants";
import history from "../../../../routes/history";
import { CustomDeleteIcon } from "../../../../Components/CustomActionIcons";
import useModal from "../../../../hooks/useModal";
import ConfirmationModal from "../../../../Components/Modal/ConfirmationModal";

const ManageVoucher = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const vouchers = useSelector(selectVoucherList);
  const [isAsc, setIsAsc] = useState(false);
  const [orderBy, setOrderBy] = useState("date");
  const [selectedVoucher, setSelectedVoucher] = useState(null);
  const { open, handleOpen, handleClose } = useModal();
  const handleRemoveVoucher = () => {
    dispatch(removeVoucher(selectedVoucher._id));
    handleClose();
  };

  const onSort = value => {
    if (orderBy === value) {
      setIsAsc(!isAsc);
    } else {
      setOrderBy(value);
    }
  };

  useEffect(() => {
    dispatch(getVoucherList());
  }, [dispatch]);

  if (!vouchers) return null;

  const sortedVouchers = sortList(vouchers, isAsc, orderBy);

  return (
    <>
      <ConfirmationModal
        open={open}
        onClose={handleClose}
        actionFn={handleRemoveVoucher}
        ctaTitle={"Are you wish to delete this discount code?"}
        ctaTxt={"Delete"}
      />
      <Fragment>
        <div className="pageCont">
          <div className="bodyCont">
            <div className="pageAction">
              <div className="pageActionRight">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    history.push(CREATE_DISCOUNT_CODE_URL);
                  }}
                >
                  Create Discount code
                </Button>
              </div>
            </div>
            <div className="pageContent">
              <MUIDataTable
                data={sortedVouchers.map((voucher, i) => [
                  i + 1,
                  voucher.code,
                  voucher._createdOn,
                  voucher._expiredOn,
                  voucher.limit,
                  voucher.redeemedCount,
                ])}
                columns={[
                  {
                    name: "No",
                    options: {
                      customHeadRender: columnMeta => {
                        return (
                          <MUICustomHeader label={columnMeta.label}>
                            <>
                              {columnMeta.label}
                              <FormatListNumberedIcon
                                className={classes.thIcon}
                                color="secondary"
                              />
                            </>
                          </MUICustomHeader>
                        );
                      },
                    },
                  },
                  {
                    name: "Code",
                    options: {
                      customHeadRender: columnMeta => {
                        return (
                          <MUICustomHeader label={columnMeta.label}>
                            <>
                              {columnMeta.label}
                              <LabelOutlinedIcon
                                className={classes.thIcon}
                                color="secondary"
                              />
                            </>
                          </MUICustomHeader>
                        );
                      },
                      customBodyRenderLite: dataIndex => {
                        let id = sortedVouchers[dataIndex]._id;
                        if (id) {
                          const url = urlAdapter(
                            EDIT_DISCOUNT_CODE_URL,
                            idParams,
                            id
                          );

                          return (
                            <Link to={url}>
                              {sortedVouchers[dataIndex].code}
                            </Link>
                          );
                        }
                      },
                    },
                  },
                  {
                    name: "Created On",
                    options: {
                      customHeadRender: columnMeta => {
                        return (
                          <MUICustomHeader label={columnMeta.label}>
                            <>
                              <CustomTableSortLabel
                                active={orderBy === "date"}
                                direction={isAsc ? "asc" : "desc"}
                                onClick={() => onSort("date")}
                              >
                                <div
                                  className="MUIDataTableHeadCell-sortAction-97"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#313131",
                                  }}
                                >
                                  <div className="MUIDataTableHeadCell-data-96">
                                    {columnMeta.label}
                                  </div>
                                  <CalendarTodayOutlinedIcon
                                    className={classes.thIcon}
                                    color="secondary"
                                  />
                                </div>
                              </CustomTableSortLabel>
                            </>
                          </MUICustomHeader>
                        );
                      },
                      customBodyRenderLite: dataIndex => {
                        let val = sortedVouchers[dataIndex]._createdOn;
                        return parseDateHtml(val, 130);
                      },
                    },
                  },
                  {
                    name: "Expires On",
                    options: {
                      customHeadRender: columnMeta => {
                        return (
                          <MUICustomHeader label={columnMeta.label}>
                            <>
                              {columnMeta.label}
                              <EventOutlinedIcon
                                className={classes.thIcon}
                                color="secondary"
                              />
                            </>
                          </MUICustomHeader>
                        );
                      },
                      customBodyRenderLite: dataIndex => {
                        let val = sortedVouchers[dataIndex]._expiredOn;
                        return parseDateHtml(val);
                      },
                    },
                  },
                  {
                    name: "Limit Usage",
                    options: {
                      customHeadRender: columnMeta => {
                        return (
                          <MUICustomHeader label={columnMeta.label}>
                            <>
                              {columnMeta.label}
                              <DataUsageOutlinedIcon
                                className={classes.thIcon}
                                color="secondary"
                              />
                            </>
                          </MUICustomHeader>
                        );
                      },
                    },
                  },
                  {
                    name: "Code Used Count",
                    options: {
                      customHeadRender: columnMeta => {
                        return (
                          <MUICustomHeader label={columnMeta.label}>
                            <>
                              {columnMeta.label}
                              <RedeemOutlinedIcon
                                className={classes.thIcon}
                                color="secondary"
                              />
                            </>
                          </MUICustomHeader>
                        );
                      },
                    },
                  },
                  {
                    name: "Action",
                    options: {
                      customBodyRenderLite: dataIndex => {
                        const currentVoucher = sortedVouchers[dataIndex];

                        return (
                          <div style={{ display: "flex" }}>
                            <CustomDeleteIcon
                              onClickHandler={() => {
                                setSelectedVoucher(currentVoucher);
                                handleOpen();
                              }}
                            />
                          </div>
                        );
                      },
                    },
                  },
                ]}
                options={{
                  download: false,
                  print: false,
                  selectableRows: "none",
                  setRowProps: (row, dataIndex, rowIndex) => {
                    return {
                      style: {
                        backgroundColor:
                          rowIndex % 2 !== 0 ? "#fff" : "rgba(0, 0, 0, 0.04)",
                      },
                    };
                  },
                }}
              />
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default ManageVoucher;
