import { useEffect } from "react";

const useProtectContent = () => {
  const handleKeyDown = e => {
    const isCtrlKeyPressed = e.ctrlKey || e.metaKey;

    // disable F12 key
    if (e.keyCode == 123) {
      e.preventDefault();
      return false;
    }

    // disable A key
    if (isCtrlKeyPressed && e.keyCode == 65) {
      e.preventDefault();
      return false;
    }

    // disable C key
    if (isCtrlKeyPressed && e.keyCode == 67) {
      e.preventDefault();
      return false;
    }

    // disable V key
    if (isCtrlKeyPressed && e.keyCode == 86) {
      e.preventDefault();
      return false;
    }

    if (e.keyCode == 44) {
      e.preventDefault();
      return false;
    }
    // Disable print key
    if (isCtrlKeyPressed && e.keyCode == 80) {
      e.preventDefault();
      return false;
    }
  };

  const handleKeyUp = e => {
    // Disable print screen key
    if (e.keyCode == 44) {
      e.preventDefault();
      return false;
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.addEventListener("keyup", handleKeyUp);
      return false;
    };
  }, []);
};
export default useProtectContent;
