import { gql } from "@apollo/client";

export const notes = gql`
  query Notes {
    notes {
      _id
      question {
        _id
        _ref
        question
        answers {
          content
          validAnswer
        }
        explanation
      }
      content
      status
      category {
        name
      }
      subCategory {
        name
      }
    }
  }
`;

export const createNote = gql`
  mutation CreateNote($input: CreateNoteInput!) {
    createNote(input: $input) {
      _id
      question {
        _id
        question
      }
      content
      status
    }
  }
`;

export const updateNote = gql`
  mutation UpdateNote($_id: ObjectId!, $input: UpdateNoteInput!) {
    updateNote(_id: $_id, input: $input) {
      _id
      question {
        _id
        question
      }
      content
      status
      category {
        name
      }
      subCategory {
        name
      }
    }
  }
`;

export const removeNote = gql`
  mutation RemoveNote($_id: ObjectId!) {
    removeNote(_id: $_id)
  }
`;
