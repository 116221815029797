import React, { Fragment, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import LibraryBooksOutlinedIcon from "@material-ui/icons/LibraryBooksOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CollectionsBookmarkOutlinedIcon from "@material-ui/icons/CollectionsBookmarkOutlined";

import * as TopicAction from "../../../actions/topicAction";
import { setTopicListState } from "../../../actions/topicAction";
import { COURSE_APP_SUB_ROUTE } from "../../../routes/constants";
import useStyles from "./styles";
import { CATEGORY_TYPE } from "../../../constants";
import { categoriesArr, sortTopics } from "./helper";
import clsx from "clsx";
import { selectTopicListState } from "../../../selectors/topicList";

const TopicList = ({ getTopicListState }) => {
  const classes = useStyles();
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const dispatch = useDispatch();

  const {
    topics,
    categories,
    selectedTopicsCategory,
    showTopicsCategory,
    selectedCategory,
    showCategory,
    selectedCategories,
    selectedTopics,
  } = useSelector(selectTopicListState);

  const handleTopicCategoriesItemClick = key => {
    dispatch(
      setTopicListState({
        selectedTopicsCategory: key,
        showTopicsCategory: false,
      })
    );

    if (selectedCategory) {
      dispatch(
        setTopicListState({
          selectedCategory: null,
          selectedTopics: [],
        })
      );
    }
  };

  const handleBackToTopicsCategory = () => {
    dispatch(
      setTopicListState({
        selectedTopicsCategory: null,
        selectedCategories: [],
        showTopicsCategory: true,
      })
    );

    if (selectedCategory) {
      dispatch(
        setTopicListState({
          selectedCategory: null,
          selectedTopics: [],
        })
      );
    }
  };

  const handleCategoriesItemClick = category => {
    dispatch(
      setTopicListState({
        selectedCategory: category,
        showCategory: false,
      })
    );
  };

  const handleBackToCategories = () => {
    dispatch(
      setTopicListState({
        selectedCategory: null,
        selectedTopics: [],
        showCategory: true,
      })
    );
  };

  const loadInitialData = async () => {
    await getTopicListState();
  };

  useEffect(() => {
    if (!topics | !categories) {
      loadInitialData();
    }
  }, [topics, categories]);

  useEffect(() => {
    if (categories && selectedTopicsCategory) {
      dispatch(
        setTopicListState({
          selectedCategories: categories[selectedTopicsCategory],
        })
      );
    }
  }, [selectedTopicsCategory, categories]);

  useEffect(() => {
    if (selectedCategory && topics) {
      let parsedTopics = [];
      if (selectedTopicsCategory === CATEGORY_TYPE.SUBJECT) {
        parsedTopics = sortTopics(
          topics.filter(
            topic =>
              topic.category &&
              topic.category._id &&
              topic.category._id === selectedCategory._id
          )
        );
      } else if (selectedTopicsCategory === CATEGORY_TYPE.SYSTEM) {
        parsedTopics = sortTopics(
          topics.filter(
            topic =>
              topic.subCategory &&
              topic.subCategory._id &&
              topic.subCategory._id === selectedCategory._id
          )
        );
      }

      dispatch(
        setTopicListState({
          selectedTopics: parsedTopics,
        })
      );
    }
  }, [selectedCategory]);

  const selectedCategoryTitle = selectedTopicsCategory
    ? categoriesArr.find(item => item["key"] === selectedTopicsCategory).title
    : "";

  if (!categories || !topics) return null;

  return (
    <div className={classes.container}>
      <div className={classes.itemColumnsContainer}>
        {/* Boolean condition below is for mobile view */}
        {(showTopicsCategory || lgUp) && (
          <div className={clsx(classes.itemColumn, "fadeInLeft animated")}>
            <Grid container direction="column">
              <Grid item>
                <div style={{ height: "24px" }}></div>
              </Grid>
              <Grid item>
                <Typography variant="h4" className={classes.title}>
                  Topics
                </Typography>
              </Grid>
              <Grid item>
                <List className={classes.listRoot} disablePadding>
                  {categoriesArr.map(category => (
                    <ListItem
                      selected={selectedTopicsCategory === category.key}
                      onClick={() =>
                        handleTopicCategoriesItemClick(category.key)
                      }
                      classes={{
                        root: classes.linkContainer,
                        selected: classes.activeLinkContainer,
                      }}
                      key={category.key}
                    >
                      <ListItemIcon className={classes.icon}>
                        <LibraryBooksOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary={category.title} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </div>
        )}

        {(showCategory || lgUp) &&
          selectedCategories.length > 0 &&
          selectedTopicsCategory !== null && (
            <div className={clsx(classes.itemColumn, "fadeInRight animated")}>
              <Fragment>
                <Grid container direction="column">
                  <Grid item>
                    <div
                      className={classes.backActionContainer}
                      onClick={handleBackToTopicsCategory}
                    >
                      <div>
                        <ChevronLeftIcon className={classes.chevron} />
                      </div>
                      <div>
                        <Typography
                          variant="subtitle1"
                          className={classes.subTitle}
                        >
                          TOPICS
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                  <Grid item>
                    <Typography variant="h4" className={classes.title}>
                      {selectedCategoryTitle}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <List className={classes.listRoot} disablePadding>
                      {selectedCategories.length > 0 &&
                        selectedCategories.map(category => (
                          <ListItem
                            selected={
                              selectedCategory &&
                              selectedCategory._id &&
                              selectedCategory._id === category._id
                            }
                            onClick={() => handleCategoriesItemClick(category)}
                            classes={{
                              root: classes.linkContainer,
                              selected: classes.activeLinkContainer,
                            }}
                            key={category._id}
                          >
                            <ListItemIcon className={classes.icon}>
                              <CollectionsBookmarkOutlinedIcon
                                className={classes.topicIcon}
                              />
                            </ListItemIcon>
                            <ListItemText primary={category.name} />
                          </ListItem>
                        ))}
                    </List>
                  </Grid>
                </Grid>
              </Fragment>
            </div>
          )}

        {selectedTopics.length > 0 && selectedCategory !== null && (
          <div className={clsx(classes.itemColumn, "fadeInRight animated")}>
            <Fragment>
              <Grid container direction="column">
                <Grid item>
                  <div
                    className={classes.backActionContainer}
                    onClick={handleBackToCategories}
                  >
                    <div>
                      <ChevronLeftIcon className={classes.chevron} />
                    </div>
                    <div>
                      <Typography
                        variant="subtitle1"
                        className={classes.subTitle}
                      >
                        {selectedCategoryTitle}
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item>
                  <Typography variant="h4" className={classes.title}>
                    {selectedCategory.name ? selectedCategory.name : "N/A"}
                  </Typography>
                </Grid>
                <Grid item>
                  <List className={classes.listRoot} disablePadding>
                    {selectedTopics.length > 0 &&
                      selectedTopics.map(topic => (
                        <Link
                          to={`${COURSE_APP_SUB_ROUTE}/topics/${topic._id}`}
                          key={topic.title}
                        >
                          <ListItem
                            classes={{
                              root: classes.linkContainer,
                            }}
                          >
                            <ListItemIcon className={classes.icon}>
                              <DescriptionOutlinedIcon
                                className={classes.topicIcon}
                              />
                            </ListItemIcon>
                            <ListItemText primary={topic.title} />
                          </ListItem>
                        </Link>
                      ))}
                  </List>
                </Grid>
              </Grid>
            </Fragment>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  getTopicListState: () => dispatch(TopicAction.getTopicListState()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TopicList)
);
