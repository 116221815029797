import * as UIActions from "./uiAction";
import * as UserApi from "../services/user";
import { AppConfig } from "../configs";
import { handleAxiosError } from "./errorAction";
import history from "../routes/history";
import { CHECKOUT_SIGNUP_URL } from "../routes/constants";

const { client } = AppConfig;

const MODULE_NAME = "SIGNUP";

export const CHECK_USER_EXISTS = {
  AWAIT: `${MODULE_NAME}/CHECK_USER_EXISTS/AWAIT`,
  SUCCESS: `${MODULE_NAME}/CHECK_USER_EXISTS/SUCCESS`,
  FAIL: `${MODULE_NAME}/CHECK_USER_EXISTS/FAIL`,
};
export const CLEAR_CHECK_USER_EXISTS = `${MODULE_NAME}/CLEAR_CHECK_USER_EXISTS`;

export const clearCheckUserExists = () => ({
  type: CLEAR_CHECK_USER_EXISTS,
});

export const checkUserExists = email => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({ type: CHECK_USER_EXISTS.AWAIT });
  try {
    const res = await client.query({
      query: UserApi.CHECK_EMAIL,
      variables: { email },
    });

    dispatch(UIActions.appReady());
    dispatch({
      type: CHECK_USER_EXISTS.SUCCESS,
      payload: res.data.checkEmail,
    });

    if (res.data.checkEmail && res.data.checkEmail.available === true) {
      history.push(CHECKOUT_SIGNUP_URL);
    }

    return res.data.checkEmail;
  } catch (err) {
    handleAxiosError(
      dispatch,
      err,
      "Some errors occur. Please try again later."
    );
    dispatch({
      type: CHECK_USER_EXISTS.FAIL,
    });
  }
};
