import { TRANSACTION_SOURCE } from "../../../constants";
import { creditCardsIcon, paypalIcon } from "../../../resources/assets";

export const steps = ["Pricing", "Cart", "Checkout"];
export const PAYMENT_METHOD_LIST = [
  {
    label: "PayPal",
    value: TRANSACTION_SOURCE.PAYPAL,
    img: paypalIcon,
  },
  {
    label: "Credit Card",
    value: TRANSACTION_SOURCE.STRIPE,
    img: creditCardsIcon,
  },
];
