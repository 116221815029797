import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const selectInputProps = classes => ({
  style: { fontSize: 15 },
  classes: {
    root: classes.textFieldRoot,
    notchedOutline: classes.notchedOutline,
    focused: classes.focused,
    adornedStart: classes.adornedStart,
  },
});

const selectProps = classes => ({
  select: true,
  fullWidth: true,
  variant: "outlined",

  FormHelperTextProps: {
    style: { fontSize: 13 },
  },
  SelectProps: {
    IconComponent: ExpandMoreIcon,
    classes: {
      select: classes.select,
    },
    MenuProps: {
      PopoverClasses: {
        paper: classes.popoverMenuPaper,
      },
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },
      getContentAnchorEl: null,
      elevation: 0,
    },
  },
});

export { selectInputProps, selectProps };
